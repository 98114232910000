import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "निजी रूपरेखा जानकारी", "description": "लिंग, जाती, धार्मिक विश्वास, यौन अभिविन्यास, राजनीतिक राय, संगीत, टीवी/फ़िल्म या स्ट्रीमिंग पसंद, मोबाइल चलन आदतें, कार्यक्रम." },
      { "index": 2, "id":"2_health", "name": "स्वास्थ्य जानकारी", "description": "जिस्मानी और मानसिक स्थि‍ति, जेनेटिकया बायोमेट्रिक जानकारी, वजन, बीएमआई, दबाव, स्वास्थ्य भवन तक पहुँच." },
      { "index": 3, "id":"3_dietary", "name": "आहार की आदतों की जानकारी", "description": "भोजन या पेय विकल्प, एलर्जी और न सहने योग्यता, धूम्रपान की आदतें, दवाओं का उपयोग, पोषण की  जानकारी, बार, रेस्तरां, सुविधाओं के संदर्भ में पसंद." },
      { "index": 4, "id":"4_transactions", "name": "इलैक्ट्रोनिक लेन-देन की जानकारी", "description": "क्रेडिट कार्ड का उपयोग, इंटरनेट लेन-देन, ऑफ़लाइन और ऑनलाइन खरीद, उत्पाद की खपत की जानकारी." },
      { "index": 5, "id":"5_socialnetworks", "name": "सामाजिक नेटवर्किंग की जानकारी", "description": "सामाजिक नेटवर्किंग का निजी इस्तेमाल, कार्य संचार, ईमेल, प्रयोग,भूमिका में बैठकें, डेटिंग ऐप्स, वीडियो कॉल के मंच." },
      { "index": 6, "id":"6_human", "name": "मानव गतिशीलता की जानकारी", "description": "व्यक्तिगत गतिशीलता, निजी या सार्वजनिक वाहनों का उपयोग, के बारे में जानकारी के साथ भू-स्थानीकरण, समय की मोहर, लोगों की गिनती." },
      { "index": 7, "id":"7_animal", "name": "पशु गतिशीलता की जानकारी", "description": "पालतू या पशुओं की गतिशीलता, के बारे में जानकारी के साथ भू-स्थानीकरण, समय की मोहर, जानवरों की गिनती." },
      { "index": 8, "id":"8_vehicle", "name": "वाहन गतिशीलता की जानकारी", "description": "वाहन गतिशीलता (कार, बाइक, स्कूटर, बस, हवाई जहाज), के बारे में जानकारी के साथ भू-स्थानीकरण, समय की मोहर, वाहनकी गिनती  और लक्षण." },
      { "index": 9, "id":"9_utility", "name": "उपयोग की जानकारी", "description": "पानी, बिजली और गैस की खपत, हीटिंग और शीतलन की आदतें, कचरा मचने और रीसाइक्लिंग की आदतें, लैंडलाइन फोन वाईफाई." },
      { "index": 10, "id":"10_environmental", "name": "पर्यावरण की जानकारी", "description": "अन्दर और बहार की हवा के गुण, तापमान, रसायन, आग, प्रकाश, ध्वनि प्रदूषण." },
      { "index": 11, "id":"11_infrastructure", "name": "सार्वजनिक अवसंरचना की जानकारी", "description": "पुल, सड़क की स्थिति, सार्वजनिक फर्नीचर, ट्रैफिक लाइट, सार्वजनिक स्थानों का संरक्षण." },
      { "index": 12, "id":"12_greenery","name": "हरियाली  की जानकारी", "description": "पेड़, पार्क, उद्यान, कृषि क्षेत्र, हरी छतरी और  पौधों की विविधता." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "घर का वातावरण", "description": "लोगों के घरेलू जीवन के पहलू जो उनके रहने की स्थिति में योगदान करते हैं. बाहरी और भीतरी स्थान, निजी घर शामिल हैं, लेकिन निवास समुदाय भी, जैसे नर्सिंग होम, अनाथालय, वृद्धाश्रम, जेल, उपचार की सुविधा, और पालक घरों जैसे समूह घर." },
      { "index": 2, "color":"#295966", "name": "काम का माहौल", "description": "तत्व जिसमें वह सेटिंग शामिल है जिसमें कर्मचारी काम करते हैं और प्रभावित होते हैं,सामाजिक सुविधाओं और भौतिक स्थितियों के रूप में. ये पहलू भलाई की भावनाओं को प्रभावित कर सकते हैं,कार्यस्थल संबंध, सहयोग, क्षमता और कर्मचारी स्वास्थ्. यह एक घर के कार्यालय से लेकर एक कार्यालय भवन या कारखाने (बाहरी या इनडोर) तक है." },
      { "index": 3, "color":"#8862A6", "name": "सार्वजनिक स्थान", "description": "एक जगह जो आम तौर पर लोगों के लिए सड़क, सार्वजनिक चौक, पार्क, समुद्र तट, बाइक पथ, गैरेज, सरकारी भवनों के रूप में खुली और सुलभ है, जैसे सार्वजनिक पुस्तकालय, लिफ्ट, दुकानें, रेस्तरां, सामुदायिक केंद्र, खेल सुविधाएं, पार्टी रूम, थिएटर या मीडिया रूम जैसी सुविधाएं." }
  ],
  discover:{
    blocks:[
      {
        title:'भूमिका',
        text:"हर जगह जानकारी इकट्ठा किया जा रहा है. जानकारी-संचालित समाधान बदल रहे हैं हम सार्वजनिक स्थानों, रहने और कार्यस्थलों का उपयोग कैसे करते हैं "
      },
      {
        title:"विशेष कार्य",
        text:"डेटा स्लॉट एक अनोखा मिशन वाला गेम है: गुप्तता की चिंताओं और लाभों को संतुलित करते हुए डेटा-सम्बंधित समाधान प्रस्तावित करें."
      },
      {
        title:"खेल",
        subtitle:"कथानक पत्ते",
        id:"scenario",
        text:"अपने विचारों को प्रस्तावित करने के लिए बिना सोचे समझे एक कथानक कार्ड चुनें."
      },
      {
        subtitle:"जानकारी के पत्ते",
        id:"data-cards",
        text:"आपको तीन बेतरतीब जानकारी के पत्ते मिलते हैं.  एक कार्ड को लॉक करें, जिसे आप पूरे खेल के लिए रखते हैं. अगला, अपनी संपत्ति बनाने के लिए अन्य जानकारी के पत्ते का आदान-प्रदान करें."
      },
      {
        title:"विचार",
        id:"ideation",
        text:"आपके कथानक के लिए, कम से कम 3 जानकारी के पत्तों का प्रयोग करें."
      },
      {
        title:"जांचना",
        id:"assessments",
        text:"एक बार जब आप अपना समाधान सबमिट कर देते हैं, तो डेटा स्लॉट अन्य खिलाड़ियों द्वारा बनाए गए अन्य तीन विचारों को प्रदर्शित करेगा. हर विचार की गुप्तता  संबंधी चिंताओं और लाभों को आंके."
      },
      {
        title:"निवेश",
        id:"investment",
        text:"अंत में, अपने चिप्स को इन विचारों में निवेश करें."
      },
      {
        title:"",
        id:"scoreboard",
        text:"स्कोरबोर्ड न भूलें. एक उपनाम बनाएं और स्कोरबोर्ड के माध्यम से अपनी रैंकिंग पर नज़र रखें."
      }
    ],
    contact:"<strong>संपर्क:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>अस्वीकरण:</strong> डेटा स्लॉट किसी भी व्यक्तिगत डेटा का अनुरोध नहीं करते हैं. डेटा स्लॉट में जनरेट किया गया डेटा गुमनाम होता है और इसका उपयोग केवल अकादमिक शोध के लिए किया जाएगा.",
    credits:"<strong>समूह:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>वेबसाइट:</strong> समूह + Paul Bouisset, Pietro Leoni<br><strong>स्वीकृति:</strong> Yuki Machida, Oluwatobi Oyinlola, और SCL सदस्य, साथ ही दुनिया भर के शहरों में प्रतिभागी जिन्होंने डेटा स्लॉट का स्वयं संस्करण खेला और गेम को बेहतर बनाने में हमारी मदद की. हम SCL संघ को धन्यवाद देते हैं."
  },
  gameHints:{
    yourCards:"आपके कार्ड",
    cardsDeck:"कार्ड डेक",
    introText:'डेटा-संचालित समाधानों की नैतिकता और मूल्य के बारे में 10-मिनट का गेम',
    introPlay:'खेल खेलें',
    introDiscover:'खोज',
    introScoreboard:'स्कोर बोर्ड',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'कृपया कथानक का चुनाव करें', hint: 'आपका मिशन इन कथानक में से किसी एक के लिए अनुभव बनाने के लिए डेटा-संचालित विचार तैयार करना है: सार्वजनिक स्थान, रहने और काम का माहौल.', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: 'आपको तीन पत्ते मिले हैं. एक को लॉक करें , और उससे पूरे खेल में रखे', hint: 'बस कार्ड को टैप करके इसे चुनें', icon: '' },
      level1: { message: 'अब वह कार्ड चुनें जिसे आप अपने डेक से हटाना चाहते हैं', hint: 'कार्ड पर टैप करें', icon: '' },
      level2: { message: 'अब वह कार्ड चुनें जिसे आप अपने डेक में चाहते हैं', hint: 'कार्ड पर टैप करें', icon: '' },
      level3: { message: 'अब दूसरा कार्ड चुनें जिसे आप अपने डेक से हटाना चाहते हैं', hint: 'कार्ड पर टैप करें', icon: '' },
      level4: { message: 'अब वह कार्ड चुनें जिसे आप अपने डेक में चाहते हैं', hint: 'कार्ड पर टैप करें' , icon: '' },
      level5: { message: 'अब अपना अंतिम कार्ड चुनें', hint: 'कार्ड पर टैप करें', icon: '' },
      level6: { message: 'ये आपके अंतिम कार्ड हैं ', hint: 'अपने विचार का प्रस्ताव करने के लिए आगे बढ़ें पर टैप करें' }
    },
    slot: {
        level0: { message: 'आपको तीन पत्ते मिले हैं. एक को लॉक करें , और उससे पूरे खेल में रखे', hint: 'बस क्लिक करके चुनें', icon: 'highlight_alt' },
        level1: { message: 'अब अपना एक पत्ते बदलें', hint: 'एक पत्ते  को ऊपर डेक पर खींचें', icon: 'swipe_up' },
        level2: { message: 'अब एक और पत्ते बदलें', hint: 'एक पत्ते को ऊपर डेक पर खींचें', icon: 'swipe_up' },
        level3: { message: 'अब अपना आखरी कार्ड चुनें', hint: 'ऊपरी डेक से एक कार्ड खींचें', icon: 'swipe_down' },
        level4: { message: 'ये आपके अंतिम पत्ते  हैं', hint: 'अपने विचार का प्रस्ताव करने के लिए क्लिक करें', }
    },
    sideButtons: {
        scanario: { label: 'कथानक ' },
        cards: { label: 'मेरे पत्ते' }
    },
    finalMessage: {
      playAgain:'फिर से चालू करें',
      scoreboard:'स्कोर बोर्ड',
      name: {
        hint: 'आपका नाम',
        error: 'कृपया कुछ टाइप करें',
        confirm:'अपडेट करें'
      },
      message: 'डेटा स्लॉट खेलने के लिए धन्यवाद',
      hint:"स्कोरबोर्ड में शामिल होने के लिए यह आपका उपनाम है <br> जल्द वापस आएं और अपने विचार की रैंकिंग जांचने के लिए अपने निकनेम का इस्तेमाल करें"
    }
  },
  profilation:{
      title: 'हमें अपने बारे में बताइये',
      hint: '',

      name: {
          hint: 'आपका नाम',
          error: 'कृपया कुछ टाइप करें'
      },
      role: {
          hint: 'आपका योगदान',
          options: [
              'शिक्षा',
              'नागरिक',
              'नगर अधिकारी'
          ],
          error: 'कृपया कुछ चुनें'

      },
      region: { hint: 'आप कहा खेल रहे है', options: regions,error: 'कृपया कुछ चुनें' },
      age: {
          hint: 'आपका उम्र',
          options: [
              '16 के नीचे',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              '65 के ऊपर'
          ],
          error: 'कृपया कुछ चुनें'

      },
      gender: {
          hint: 'आपके सर्वनाम',
          options: [
              'वह / वह',
              'वह / वह',
              'वे / उन्हें',
              'मुझे टाइप करने दो',
              'मैं नहीं कहना पसंद करता हूं'
          ],
          error: 'कृपया कुछ चुनें'
      },
      customGender: {
          hint: 'आपके सर्वनाम',
          error: 'कृपया कुछ टाइप करें'

      },
      confirm: 'आगे बढे'
  },
  ideation:{
      title: 'विचार',
      hint: 'आप अपने कथानक  के लिए अपने कम से कम 3 पत्ते संयोजन करके कौनसा विचार/अनुभव सुझाएंगे? <br>आपके पास 5 मिनट है.',
      projectTitle: {
          hint: 'परियोजना का शीर्षक',
          error: 'कृपया कुछ टाइप करें'
      },
      name: {
          hint: 'आपका नाम',
          error: 'कृपया कुछ टाइप करें'
      },
      projectCaption: {
          hint: 'परियोजना का दावा',
          error: 'कृपया कुछ टाइप करें'
      },
      projectDescription: {
          hint: 'परियोजना छोटा रंग-रूप',
          error: 'कृपया कुछ टाइप करें'
      },
      projectRatings:{
        translation:"* कुछ परियोजनाओं का स्वचालित रूप से उनकी मूल भाषा से अनुवाद किया जाता है",
        ratings:{
          hint:'विचारों को रेट करें',
          subtitle:'आक्रामकता &#8594; डेटा के उपयोग से हर विचार में गोपनीयता की चिंता करे <br> Bफ़ायदे &#8594; प्रत्येक विचार द्वारा हुए फ़ायदे'
        },
        investments:{
          hint:'अब आप निवेशक हैं',
          subtitle:'आपके पास दस सिक्के हैं. इन विचारों में निवेश करने के लिए सिक्कों को खींचें',
          icon:'swipe_up'
        }
      },
      read_more:'आगे पढ़े',
      confirm: 'प्रस्तुत',
      invasiveness:'आक्रामकता',
      benefit:'फायदा',
      high:'उच्च',
      low:'कम'
  },
  scoreboard:{
    search:'खोज',
    name:'उपनाम',
    position:'पद',
    title:'शीर्षक',
    country: 'देश',
    score: 'अंक',
    ratings:'रेटिंग्स'
  }
}