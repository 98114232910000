import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "個人プロフィールデータ", "description": "ジェンダー、民族的帰属、宗教、性的指向、政治に関する意見、音楽・テレビ・映画・ストリーミングサービスなどの好み、スマホ利用状況、日々のスケジュールなど." },
      { "index": 2, "id":"2_health", "name": "健康データ", "description": "心身の状態、遺伝・生体情報、体重、BMI値、ストレス、健康関連サービスの利用状況など." },
      { "index": 3, "id":"3_dietary", "name": "食習慣データ", "description": "食べ物・飲み物の選択、アレルギー・過敏症、喫煙習慣、薬品の使用状況、栄養状況、バー・レストラン等の嗜好など." },
      { "index": 4, "id":"4_transactions", "name": "電子取引データ", "description": "クレジットカード利用状況、インターネットバンキング、オフライン・オンラインでの買い物履歴、購買した商品の情報など." },
      { "index": 5, "id":"5_socialnetworks", "name": "ソーシャルネットワークデータ", "description": "SNS、仕事上のコミュニケーション、メール、スマホアプリ、対面会議、マッチングアプリ、ビデオ通話プラットフォームなどの個人の利用状況." },
      { "index": 6, "id":"6_human", "name": "人の移動データ", "description": "個人の移動・自家用車や公共交通機関の利用に関する、位置・日時・移動人数などの情報." },
      { "index": 7, "id":"7_animal", "name": "動物の移動データ", "description": "ペットや家畜の移動に関する、位置・日時・頭数などの情報." },
      { "index": 8, "id":"8_vehicle", "name": "車両移動データ", "description": "車両（車、自転車、スクーター、バス、飛行機）の移動に関する、位置・日時・車両数・車両の特徴などの情報." },
      { "index": 9, "id":"9_utility", "name": "公共サービス利用データ", "description": "水・電気・ガスの消費量、冷暖房の利用状況、ゴミ回収やリサイクルの利用状況、固定電話・Wifi利用状況." },
      { "index": 10, "id":"10_environmental", "name": "環境データ", "description": "屋内外の大気の質、温度、化学物質、火災、日照、騒音公害に関する情報." },
      { "index": 11, "id":"11_infrastructure", "name": "公共インフラのデータ", "description": "橋や道路の状況、パブリックファニチャー、信号機、公共スペースの維持管理に関する情報." },
      { "index": 12, "id":"12_greenery","name": "緑に関するデータ", "description": "樹木、公園、庭園、農地、グリーンキャノピー、植物の多様性に関する情報." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "家庭環境", "description": "人々の家庭生活が営まれる生活環境。屋外・屋内空間、介護施設、グループホーム（児童養護施設、老人ホーム、刑務所、治療施設など）、児童養護施設などの居住型コミュニティ施設も含まれる。" },
      { "index": 2, "color":"#295966", "name": "職場環境", "description": "人々が働く環境を構成する要素。社会的・物理的に働く人に影響を与える。これらの要素は、ウェルビーイング、職場における人間関係、コラボレーション、能率、従業員の健康などに影響を与える可能性がある。在宅勤務、オフィスビル、工場（屋外/屋内）まで多様な形態がある。" },
      { "index": 3, "color":"#8862A6", "name": "公共空間", "description": "一般に開放され、人々が利用できる場所。道路、広場、公園、ビーチ、自転車道、車庫、公立図書館などの行政施設、エレベーターなどの設備、店舗、レストラン、コミュニティセンター、スポーツ施設、パーティールーム、劇場やメディアルームなど。" }
  ],
  discover:{
    blocks:[
      {
        title:'Background',
        text:"Data is being collected everywhere. Data-driven solutions are changing how we use public spaces, living and work places."
      },
      {
        title:"Mission",
        text:"Data Slots is a game with a unique mission: propose data-driven solutions balancing privacy concerns and benefits."
      },
      {
        title:"The Game",
        subtitle:"Scenario cards",
        id:"scenario",
        text:"Randomly pick a scenario card to propose your ideas."
      },
      {
        subtitle:"Data cards",
        id:"data-cards",
        text:"You receive three random data cards. Lock one card, which you keep for the entire game. Next, exchange other data cards to build your assets."
      },
      {
        title:"Ideation",
        id:"ideation",
        text:"Use your 4 data cards to propose solutions for your scenario."
      },
      {
        title:"Assessment",
        id:"assessments",
        text:"Once you submit your solution, Data Slots will display other three ideas created by other players. <br>Assess privacy concerns and benefits of each idea."
      },
      {
        title:"Investment",
        id:"investment",
        text:"Finally, invest your chips in these ideas."
      },
      {
        title:"",
        id:"scoreboard",
        text:"Don’t miss the scoreboard! Create a nickname and keep track of your ranking through the Scoreboard."
      }
    ],
    contact:"<strong>Contact:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Disclaimer:</strong> Data Slots do not request any personal data. The data generated in Data Slots is anonymous and will be used for academic research only.",
    credits:"<strong>Team:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Website:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Acknowledgment:</strong> Yuki Machida, Oluwatobi Oyinlola, and SCL members, as well as participants in cities around the world who played the in-person version of Data Slots and helped us to improve the game. We thank the SCL consortium."
  },
  gameHints:{
    yourCards:"Your cards",
    cardsDeck:"Cards deck",
    introText:'A 10-min game about the ethics and value of data-driven solutions',
    introPlay:'Play the game',
    introDiscover:'Discover',
    introScoreboard:'Scoreboard',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'Please select the scenario', hint: 'Your mission is to design a data-driven idea to create experiences for one of these scenarios: public space, living and work&nbsp;environment.', icon: 'highlight_alt' },
    slotMobile:{
        level0: { message: 'You got three cards. Lock one to keep it along the entire game', hint: 'Just pick it by tapping the card', icon: '' },
        level1: { message: 'Now choose the card you want to remove from your deck', hint: 'Tap the card', icon: '' },
        level2: { message: 'Now choose the card you want in your deck', hint: 'Tap the card', icon: '' },
        level3: { message: 'Now choose another card you want to remove from your deck', hint: 'Tap the card', icon: '' },
        level4: { message: 'Now choose the card you want in your deck', hint: 'Tap the card' , icon: '' },
        level5: { message: 'Now pick your last card', hint: 'Tap the card', icon: '' },
        level6: { message: 'These are your final cards', hint: 'Tap proceed to propose your idea' }
      },
    slot: {
        level0: { message: 'You got three cards. Lock one to keep it along the entire game', hint: 'Just pick it by clicking', icon: 'highlight_alt' },
        level1: { message: 'Now swap one of your cards', hint: 'Drag one card to the top deck', icon: 'swipe_up' },
        level2: { message: 'Now swap one more card', hint: 'Drag one card to the top deck', icon: 'swipe_up' },
        level3: { message: 'Now pick your last card', hint: 'Drag one card from the top deck', icon: 'swipe_down' },
        level4: { message: 'These are your final cards', hint: 'Click proceed to propose your idea', }
    },
    sideButtons: {
        scanario: { label: 'scanario' },
        cards: { label: 'my cards' }
    },
    finalMessage: {
        playAgain:'Play Again',
        scoreboard:'Scoreboard',
      name: {
        hint: 'Your name',
        error: 'Please type something',
        confirm:'Update'
      },
      message: 'Thank you for playing Data Slots',
      hint:"This is your nickname to join the scoreboard <br> Come back soon and use your nickname to check your idea's ranking"
    }
  },
  profilation:{
      title: 'Tell us about you',
      hint: '',

      name: {
          hint: 'Your name',
          error: 'Please type something'
      },
      role: {
          hint: 'Your role',
          options: [
              'Academic',
              'Citizen',
              'City official'
          ],
          error: 'Please select something'

      },
      region: { hint: 'where are you playing', options: regions,error: 'Please select something' },
      age: {
          hint: 'Your age',
          options: [
              'under 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'over 65'
          ],
          error: 'Please select something'

      },
      gender: {
          hint: 'Your pronouns',
          options: [
              'She/her',
              'He/him',
              'They/them',
              'Let me type',
              'I prefer not to say'
          ],
          error: 'Please select something'
      },
      customGender: {
          hint: 'Your pronouns',
          error: 'Please type something'

      },
      confirm: 'Proceed'
  },
  ideation:{
      title: 'The ideation',
      hint: 'Which idea/experience would you suggest for your scenario combining at least 3 of your cards? <br>You have 5 minutes.',
      projectTitle: {
          hint: 'Project title',
          error: 'Please type something'
      },
      name: {
          hint: 'Your name',
          error: 'Please type something'
      },
      projectCaption: {
          hint: 'Project caption',
          error: 'Please type something'
      },
      projectDescription: {
          hint: 'Project short description',
          error: 'Please type something'
      },
      projectRatings:{
        translation:"* Some of the projects are automatically translated from their original language",
        ratings:{
          hint:'Rate the different ideas in terms of invasiveness and benefits',
          subtitle:'Invasiveness &#8594; privacy concerns about the use of data in each idea <br> Benefits &#8594; benefits created by each idea'
        },
        investments:{
          hint:'Now you are the investor',
          subtitle:'You have ten coins. Drag the coins to invest in these ideas',
          icon:'swipe_up'
        }
      },
      read_more:'Read more',
      confirm: 'Submit',
      invasiveness:'Invasiveness',
      benefit:'Benefit',
      high:'High',
      low:'Low'
  },
  scoreboard:{
    search:'Search',
    name:'Nickname',
    position:'Position',
    title:'Title',
    country: 'Country',
    score: 'Score',
    ratings:'Ratings'
  }
}