<template>
  <div class="home-content">
    <div class="dataslot-name">
      <img src="/logo.png" alt="">
      <h1 class="text-h1 text-bold text-primary q-mb-0 q-mt-xs">
        Data Slots
      </h1>
    </div>
    <a href="https://www.dropbox.com/sh/b17jg6y0fov3gzl/AAA1EJgc7tMEo1tDPAjqcePea?dl=0" target="_blank" class="text-body2 press-link">Press Material</a>
    <div class="home-info">
      <LanguageSelector ref="languageSelector"/>

      <p class="text-h6 text-primary q-mb-lg">
        {{ $t('gameHints.introText') || ''}}
      </p>
      <router-link to="/play" replace>
        <q-btn rounded unelevated color="primary" size="large"
          >{{ $t('gameHints.introPlay') || ''}}</q-btn
        >
      </router-link>
      <router-link to="/discover" class="q-ml-md" replace>
          <q-btn rounded unelevated color="primary" size="large"
            >{{$t('gameHints.introDiscover') || ''}}</q-btn
          >
        </router-link>
      <div class="q-mt-md">
        <router-link to="/scoreboard" replace>
          <q-btn rounded unelevated outline style="color: #2e434c" size="medium"
            >{{$t('gameHints.introScoreboard') || ''}}</q-btn
          >
        </router-link>
    </div>
    </div>
    <embed src="MIT_SCL_logo_text.svg" type="" class="mit-logo q-mb-lg" />
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import LanguageSelector from "../components/LanguageSelector.vue"

  const store = useStore()


</script>


<style  lang="scss">
@import '../style/shared.scss';

a{
    text-decoration:none;
}
.press-link{
  position: fixed;
  bottom: 65px;
  right:20px;
  text-align: right;
  color: $primary;
  @include max-responsive(sm){
    left:50%;
    bottom: 105px;
    right: initial;
    transform: translate(-50%,0);
  }
}
.home-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include max-responsive(sm){
    overflow: hidden;
    padding-bottom: 60px;
  }

  .text-h6{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

  .home-info{
    position: relative;
    @include max-responsive(sm){
      padding: 0 20px;
    };
  }
}
.dataslot-name {
  // border-bottom: 2px solid $primary;
  img{
    width: 200px;
  }
  .text-h1{
    margin-bottom: 10px;
  }
}
.mit-logo {
  width: 10rem;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%,0);
}
</style>
