<template>
  <div  :class="{ 'locked-card': cardData.properties.locked, 'non-interactive': cardData.properties.disabled }" >
    <q-responsive :ratio="2.5 / 3.5" class="card-wrapper">
      <q-card
        @click="emit('clicked', cardData)"
        class="custom-shadow card-structure"
        :class="{ 'card-flipped': cardData.properties.flipped }"
        bordered
      >
        <div class="card-front">
          <div
            class="locked-icon"
            :class="{ 'hidden-icon': !cardData.locked }"
          >
            <q-btn flat round color="primary" disable icon="lock" />
          </div>
          <div
            class="locked-icon lock-icon-mobile"
            :class="{ 'hidden-icon': !props.lockeable }"
            @click="lockCardMobile"
          >
            <q-btn flat round color="primary" disable :icon="isMobileLocked ?'lock':'lock_open'" />
          </div>

          <div
            class="card-index row justify-end text-bold"
            :style="`color:${colorFromScriptSetup}`"
          >
            <div class="">#{{ cardData.index.toString().padStart(2, 0) }}</div>
          </div>
          <div
            class="row card-name text-h5 q-pl-md q-mt-md text-bold"
            :style="`color:${colorFromScriptSetup}`"
            v-html="cardData.name"
          />
          <div class="outlinedrow card-description q-pa-md self-end">
            <div :style="`color:${colorFromScriptSetup}`">
              {{ cardData.description }}
            </div>
          </div>
        </div>

        <div class="card-back">
          <div class="">
            <div class="text-white text-h4 text-bold q-mt-lg">
              Data slots
            </div>
          </div>
        </div>
      </q-card>
    </q-responsive>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { colors } from "quasar";

const props = defineProps(["cardData", "flipped", "color", "locked", "disabled","index","lockeable","removable","addInDeck"]);
// const locked= reactive(props.cardData.properties.locked)

if (props.flipped) props.cardData.properties.flipped = true;
if (props.locked) props.cardData.properties.locked = true;

const colorFromScriptSetup = props.color || colors.getPaletteColor("primary");

const emit = defineEmits(["clicked","lock"]);
const isMobileLocked = ref(false);

function addCardMobile(){
  emit('add',props.cardData)
}
function removeCardMobile(){
  emit('remove',props.cardData);
}
function lockCardMobile(){
  emit('lock', props.cardData)
  isMobileLocked.value = true;
}
function printProperties() {
  // console.log(props.cardData.properties);
}
</script>

<style lang="scss" >
@import '../style/shared.scss';

.card-wrapper {
  width: 250px;
  transition: all 0.2s ease-out;
    border-radius: 5% !important;
}
.scenario-card{
  .card-wrapper{
    @include max-responsive(sm){
      width: 25vw;
      .text-h4{
        font-size: 15px;
        margin-top: 0;
      }
    };
  }
}

.your-deck{
  .card-structure{
    background: rgba(#2e434d, 0.15);
  }
}

.card-structure {
  text-align: center;
  border-radius: 5% !important;
  position: relative;
  transition: all 0.5s ease-out;
  transform-style: preserve-3d;
  will-change: transform;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;

  /* content backface is visible so that static content still appears */
  backface-visibility: visible;
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -o-backface-visibility: visible;
  -ms-backface-visibility: visible;
}

.card-flipped {
  transform: perspective(600px) rotateY(179.9deg);
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  -o-perspective: 600px;
  -ms-perspective: 600px;
  //   border:2px solid red !important;
  .card-front{
    opacity: 0;
  }

  @include max-responsive(sm){
    transform: none;
    -webkit-perspective: none;
    -moz-perspective: none;
    -o-perspective: none;
    -ms-perspective: none;
    
    .card-back{
      display: flex !important;
    }
  }
}

.locked-card .custom-shadow{
  // outline:2px solid gainsboro;
  box-shadow:none !important;
}
@media (hover: hover) {
  .locked-card .card-wrapper:hover {
    transform: scale(100%) !important;

  }
  .non-interactive .card-wrapper:hover {
    transform: scale(100%) !important;

  }
  @media screen and (min-width:1400px){
    .card-wrapper:hover {
      transform: scale(105%);
    }
  }
}

.card-front {
  // border:2px solid green !important;
  // background: yellow;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.card-index {
  margin-top: 15%;
  text-align: end;
  display: inline-block;
  width: 25%;
  border-top: 2px solid v-bind("colorFromScriptSetup");
}
.card-name {
  text-align: start;
  line-height: 1.2 !important;
}
.card-description {
  text-align: left ;
  width: 100%;
  font-size: 80%;
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
  justify-content: end;
}
.card-back {
  // border:2px solid green !important;
  // background: yellow;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: v-bind("colorFromScriptSetup");
  transform: perspective(600px) rotateY(180deg);
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  -o-perspective: 600px;
  -ms-perspective: 600px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex !important;
  border-radius: 5% !important;
  justify-content: center;
  align-items: center;
  @include max-responsive(sm){
    display: none !important;
    transform: none;
    -webkit-perspective: none;
    -moz-perspective:none;
    -o-perspective: none;
    -ms-perspective: none;
  }

  .card-description{
    text-align: left !important;
  }
}
.locked-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.25s ease-out;
}
.icon-remove-mobile,.icon-add-mobile{
  width: 51px;
  opacity: .6;
  margin-right: 10px;
}
.lock-icon-mobile{
  @include responsive(sm){
    display: none;
  };
}
.hidden-icon {
  opacity: 0;
}
</style>
