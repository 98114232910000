import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
    state() {
        return {
            language: 'en',
            gameLevel: 1,
            userProfile: null,
            scenario: null,
            cardsReference: null,
            cardsDeck: null,
            pulledCards: null,
            currentCards: null,
            finalCards: null,
            projectDescription: null,
            gameLog: [],
            gameOutput: null,
            sessionId:null,
            projectsRatings: null,
            projectsInvestments:null,
            dev: import.meta.env.DEV
        }
    },
    mutations: {
        setLanguage(state, payload) {
            state.language = payload;
        },
        setGameLevel(state, payload) {
            state.gameLevel = payload;
        },
        setUserProfile(state, payload) {
            state.userProfile = payload;
            this.$firebase.addSession(payload)
        },
        setUserNickname(state,payload){
            state.userProfile.nickname = payload;
            if(!state.dev)this.$firebase.updateUserNickName(payload)
        },
        setScenario(state, payload) {
            state.scenario = payload;
            if(!state.dev)this.$firebase.addScenario(payload)
        },
        setCardsReference(state, payload) {
            state.cardsReference = payload;
        },
        setCardsDeck(state, payload) {
            state.cardsDeck = payload;
        },
        setPulledCards(state, payload) {
            state.pulledCards = payload;
        },
        setCurrentCards(state, payload) {
            state.currentCards = payload;
        },
        setFinalCards(state, payload) {
            state.finalCards = payload;
            if(!state.dev)this.$firebase.setFinalCards(payload)
        },
        addToGameLog(state, payload) {
            state.gameLog.push(payload);
            if(!state.dev)this.$firebase.addToGameLog(payload)
        },
        setProjectDescription(state, payload) {
            state.projectDescription = payload;
            if(!state.dev)this.$firebase.setProjectDescription(payload)
        },
        setProjectRatings(state,payload){
          state.projectsRatings = payload
          if(!state.dev)this.$firebase.setProjecstRatings(payload)
        },
        setProjectInvestments(state,payload){
          state.projectsInvestments = payload
          if(!state.dev)this.$firebase.setProjectInvestments(payload)
        },
        setGameOutput(state, payload) {
            state.gameOutput = payload;
        }
    }
})

export default store;
