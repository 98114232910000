import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "个人信息数", "description": "性别，种族，宗教信仰，性取向，政治主张，音乐、电视节目/电影直播偏好，移动设备使用习惯，行程。" },
      { "index": 2, "id":"2_health", "name": "健康情况数据", "description": "生理与心理状况，基因与生物信息，体重，BMI指数，压力，健康相关机构访问情况。" },
      { "index": 3, "id":"3_dietary", "name": "饮食习惯数据", "description": "食物或饮品选择，过敏原及饮食禁忌，吸烟习惯，药品使用情况，营养信息，对于酒吧、餐厅、娱乐场所的偏好。" },
      { "index": 4, "id":"4_transactions", "name": "电子交易数据", "description": "信用卡额度，网络银行，线下线上消费记录，商品消费信息。" },
      { "index": 5, "id":"5_socialnetworks", "name": "社交网络数据", "description": "个人社交网络使用情况，工作交流与往来，邮件，应用程序，线下会议，交友应用程序，视频电话平台。" },
      { "index": 6, "id":"6_human", "name": "个人移动数据", "description": "个人交通移动，公共及私人交通工具使用情况，个人时空定位信息，人流数量统计。" },
      { "index": 7, "id":"7_animal", "name": "动物移动数据", "description": "宠物或牲畜移动，动物的时空定位信息及数量统计。" },
      { "index": 8, "id":"8_vehicle", "name": "交通工具移动数据", "description": "交通工具移动情况（机动车、自行车、踏板车、公交车、飞机）及其时空定位与计数数据，交通工具特征。" },
      { "index": 9, "id":"9_utility", "name": "公共服务数据", "description": "水、电、气能源消费记录，制冷与制热习惯，垃圾与回收习惯，固定电话，WIFI网络。" },
      { "index": 10, "id":"10_environmental", "name": "环境数据", "description": "室内外空气质量，气温，化学物质，火情警报，光污染，噪音污染。" },
      { "index": 11, "id":"11_infrastructure", "name": "公共基础设施数据", "description": "桥梁，公路情况，街道家具，交通信号灯，公共空间维护。" },
      { "index": 12, "id":"12_greenery","name": "绿化数据", "description": "树木，公园，花园，农业区域，绿色天蓬以及植物多样性。" }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "家庭环境", "description": "影响人们家庭生活的各方面条件,包括室内外空间，私人住宅以及居住社区机构，如疗养院；团体之家，例如孤儿、孤儿院、养老院、监狱、治疗设施和寄养家庭。" },
      { "index": 2, "color":"#295966", "name": "工作环境", "description": "构成工作环境以及影响其中员工的各因素，如社会特征和身体状况。这些方面会对幸福感、工作场所关系、协作、效率和员工健康等方面产生影响。 它涉及从家庭办公室到办公楼或工厂（室外或室内）的所有环境范围。" },
      { "index": 3, "color":"#8862A6", "name": "公共空间", "description": "向公众开放且可供人们自由使用的地方，例如道路、公共广场、公园、海滩、自行车道、车库；政府机构，例如公共图书馆；设施，例如电梯、商店、餐厅、社区中心、体育设施、宴会厅 、剧院或媒体室。" }
  ],
  discover:{
    blocks:[
      {
        title:'背景',
        text:"可用于收集数据的渠道无处不在。数据驱动的解决方案正在改变我们使用公共空间、生活和工作场所的方式。"
      },
      {
        title:"使命",
        text:"Data Slots是一款独具使命的游戏：提出平衡私密和利益的数据驱动解决方案。"
      },
      {
        title:"游戏",
        subtitle:"情景卡",
        id:"scenario",
        text:"随机挑选一张情景卡以提出你的想法。"
      },
      {
        subtitle:"数据卡",
        id:"data-cards",
        text:"您将收到三张随机数据卡。 请锁定其中的一张卡牌，您将在整个游戏中保留它。接下来，交换其余两张数据卡来构建你的数据资产。"
      },
      {
        title:"构思",
        id:"ideation",
        text:"使用至少3张数据卡为您的场景提出解决方案。"
      },
      {
        title:"评估",
        id:"assessments",
        text:"提交解决方案后，Data Slots将显示其他玩家所创建的三个想法。请评估与权衡每个想法的私密性和利益关系。"
      },
      {
        title:"投资",
        id:"investment",
        text:"最后，用你的筹码对这些想法进行投资。"
      },
      {
        title:"",
        id:"scoreboard",
        text:"不要错过排行榜！创建昵称并通过记分牌跟踪您的排名。"
      }
    ],
    contact:"<strong>联系方式:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>免责声明:</strong> Data Slots 不要求提供任何个人数据。 Data Slots 中生成的匿名数据将仅用于学术研究。",
    credits:"<strong>团队:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>网站:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>致谢:</strong> Yuki Machida, Oluwatobi Oyinlola及其他SCL成员，以及世界各地在现场进行Data Slots的参与者们对改进游戏做出的贡献。 同时，我们也对支持SCL的财团表示感谢。"
  },
  gameHints:{
    yourCards:"Your cards",
    cardsDeck:"Cards deck",
    introText:'关于数据驱动解决方案道德和价值的 10 分钟游戏',
    introPlay:'进行游戏',
    introDiscover:'发现',
    introScoreboard:'排行榜',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: '请选择场景', hint: '您的任务是通过数据驱动的方式为以下场景之一创造更好的体验感：公共空间、生活和工作环境。', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: '您拿到了三张卡牌，请锁定一张并在整个游戏中保留它', hint: '点击您想选择的卡片', icon: '' },
      level1: { message: '现在，请从牌组中选择一张您想移除的卡牌', hint: '请点击那张卡', icon: '' },
      level2: { message: '现在，请选择一张您想加入牌组的卡牌', hint: '请点击那张卡', icon: '' },
      level3: { message: '现在，请选择另一张您想移除的卡牌', hint: '请点击那张卡', icon: '' },
      level4: { message: '现在，请选择一张您想加入牌组的卡牌', hint: '请点击那张卡' , icon: '' },
      level5: { message: '现在，请选择您的最后一张卡牌', hint: '请点击那张卡', icon: '' },
      level6: { message: '这些是您的最终卡牌组合', hint: '点击以开始提出您的构想' }
    },
    slot: {
        level0: { message: '您拿到了三张卡牌，请锁定一张并在整个游戏中保留它。', hint: '请点击您选择的卡牌。', icon: 'highlight_alt' },
        level1: { message: '现在，请交换您的一张卡牌。', hint: '将这张卡牌拖到顶层卡牌组', icon: 'swipe_up' },
        level2: { message: '现在，请交换您的一张卡牌。', hint: '将这张卡牌拖到顶层卡牌组', icon: 'swipe_up' },
        level3: { message: '现在，请选择您的最后一张卡牌。', hint: '从顶层拖出一张卡牌', icon: 'swipe_down' },
        level4: { message: '这些是您的最终卡牌组合。', hint: '点击“继续“并提出您的想法', }
    },
    sideButtons: {
        scanario: { label: '场景' },
        cards: { label: '我的卡牌' }
    },
    finalMessage: {
      playAgain:'Play Again',
      scoreboard:'Scoreboard',
      name: {
        hint: '您的名字',
        error: '请输入内容',
        confirm:'更新'
      },
      message: '感谢您参与Data Slots',
      hint:"这是您加入排行榜的昵称 <br> 随时回访，并使用您的昵称来查看想法的排名"
    }
  },
  profilation:{
      title: '让我们更了解您',
      hint: '',

      name: {
          hint: '您的名字',
          error: '请输入内容'
      },
      role: {
          hint: '您的角色',
          options: [
              '学术',
              '公民',
              '城市机构'
          ],
          error: '请输入内容'

      },
      region: { hint: '您的参与地点', options: regions,error: '请选择' },
      age: {
          hint: '您的年龄',
          options: [
              '16岁以下',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              '65岁以上'
          ],
          error: '请选择'

      },
      gender: {
          hint: '您的性别',
          options: [
              '她/她的',
              '他/他的',
              '他们/他们的',
              '其他',
              '选择保持沉默'
          ],
          error: '请选择'
      },
      customGender: {
          hint: '您的称呼',
          error: '请输入内容'

      },
      confirm: '继续'
  },
  ideation:{
      title: '构思',
      hint: '针对您的场景，请结合至少3张卡牌提出您的想法/经验。<br>您有5分钟的时间。',
      projectTitle: {
          hint: '项目名称',
          error: '请输入内容'
      },
      name: {
          hint: '您的姓名',
          error: '请输入内容'
      },
      projectCaption: {
          hint: '项目标语',
          error: '请输入内容'
      },
      projectDescription: {
          hint: '项目概述',
          error: '请输入内容'
      },
      projectRatings:{
        translation:"* 一些项目由其原始语言自动翻译而来",
        ratings:{
          hint:'基于侵入性和益处平衡，请评价不同的想法',
          subtitle:'侵入性&#8594; 关于每个想法中数据使用可能涉及的泄密问题<br> 益处 &#8594; 每个想法所创造的公共价值'
        },
        investments:{
          hint:'现在您将作为投资者',
          subtitle:'您有十个硬币，请拖动硬币来投资这些想法',
          icon:'swipe_up'
        }
      },
      read_more:'更多',
      confirm: '提交',
      invasiveness:'侵入性',
      benefit:'益处',
      high:'高',
      low:'低'
  },
  scoreboard:{
    search:'搜索',
    name:'昵称',
    position:'排名',
    title:'称谓',
    country: '国家',
    score: '分数',
    ratings:'点击率'
  }
}