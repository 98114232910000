<template>
  <div class="play-view">

    <!-- <LanguageSelector /> -->

    <a href="/discover" target="_blank" class="logo_wrapper">
      <span>{{$t('gameHints.introDiscover')}}</span>
      <img src="/logo.png" class="logo" alt="">
    </a>
    <!-- <q-btn @click="keyIndex++">+</q-btn> -->

    <TransitionGroup name="scale" mode="out-in" tag="div">
      <Profilation
        v-if="gameLevel === 1"
        key="profilation-level"
        @completed="gameLevel = 2"
        class="full-width-element"
      />
      <ScenarioSelect
        v-if="gameLevel === 2"
        key="scenario-level"
        @completed="gameLevel = 3"
        class="full-width-element"
      />
      <Slot
        v-if="gameLevel === 3"
        key="slot-level"
        @completed="gameLevel = 4"
        class="full-width-element"
      />
      <ProjectIdeation
        v-if="gameLevel === 4"
        key="ideation-level"
        @completed="gameLevel = 5"
        class="full-width-element"
      />
      <ProjectEvaluation
        v-if="gameLevel === 5"
        key="ratings-level"
        @completed="gameLevel = 6"
        class="full-width-element"
      />
      <div
        v-if="gameLevel === 6"
        key="final-message"
        class="full-width-element final-message"
      >
        <div class="text-h2 text-primary text-bold q-mb-md" v-html="$t('gameHints.finalMessage.message')">
        </div>
        <q-form
          @submit="onSubmit"
          class="profilation-form row align-center q-col-gutter-y-sm q-col-gutter-x-md"
        >
          <q-input
            square
            :filled="true"
            v-model="nickname"
            :label="$t('gameHints.finalMessage.name.hint')"
            lazy-rules
            :rules="[(val) => (val && val.length > 0) || $t('gameHints.finalMessage.name.error')]"
          />
          <div class="profilation-form__button">
            <q-btn :label="$t('gameHints.finalMessage.name.confirm')" type="submit" color="primary" unelevated rounded />
          </div>
          <div class="profilation-form__button">
          <a class="q-btn q-btn-item non-selectable no-outline q-btn--unelevated q-btn--rectangle q-btn--rounded bg-primary text-white q-btn--actionable q-focusable q-hoverable q-btn--active" :href="`data:application/octet-stream;charset=utf-8,${projectOutput.replace(/ /g,'%20')}`" download="data-slots.txt">
              <span class="q-btn__content text-center col items-center q-anchor--skip justify-center row">Download</span>
          </a>
          </div>
        </q-form>
        <!-- <q-btn color="primary" label="download" unelevated rounded :href="`data:application/octet-stream;charset=utf-8,${projectOutput}`" download="data-slots.txt"/>  -->

        <div class="text-h5 text-primary" v-html="$t('gameHints.finalMessage.hint')">
        </div>

        <q-btn
          v-if="store.state.gameOutput"
          rounded
          unelevated
          color="primary"
          class="q-mt-lg"
          @click="$router.go($router.currentRoute)"
          >{{$t('gameHints.finalMessage.playAgain')}}</q-btn
        >
        <div class="q-mt-md">
          <router-link to="/scoreboard" replace>
            <q-btn rounded unelevated outline style="color: #2e434c" size="small"
              >{{$t('gameHints.finalMessage.scoreboard')}}</q-btn
            >
          </router-link>
        </div>

        <!-- <q-btn
          v-if="store.state.gameOutput"
          rounded
          unelevated
          color="primary"
          class="q-mt-lg"
          @click="toggleGameOutput"
          >display game output</q-btn
        >

        <q-dialog v-model="showGameOutput">
          <q-card class="q-pa-md game-output">
            <pre
              class="output-pre"
              ref="gameOutputCard"
              @click="copyToClipboard"
              >{{ JSON.stringify(store.state.gameOutput, null, 2) }}
              </pre
            >
          </q-card>
        </q-dialog> -->
      </div>
    </TransitionGroup>


    <GameProgress :gameLevel="gameLevel" v-if="gameLevel < 6" />

    <SelectedCardsArchive
      class="scenario-cards-indicator"
      v-if="gameLevel < 5"
    />
    <!-- <div v-if="cardsDisplay">
      {{ cardsDisplay.map((c) => `${c.index} ${c.properties?.locked}`) }}<br />
      {{ store.state.cardsDeck }}<br />
      {{ store.state.pulledCards }}<br />
      {{ store.state.currentCards }}<br />
    </div> -->
  </div>
</template>


<script setup>
import { dataCards } from "../contents/cards.js";
import LanguageSelector from "../components/LanguageSelector.vue"

// import _ from "lodash";
import { computed, reactive, watch, ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { gameHints as contents } from "../contents/gameHints.js";
import Profilation from "../levels/Profilation.vue";
import ScenarioSelect from "../levels/ScenarioSelect.vue";
import ProjectIdeation from "../levels/ProjectIdeation.vue";
import ProjectEvaluation from "../levels/ProjectEvaluation.vue";
import Investments from '../levels/Investments.vue'
import GameHint from "../components/GameHint.vue";
import Slot from "../levels/Slot.vue";
import SelectedCardsArchive from "../components/SelectedCardsArchive.vue";
import GameProgress from "../components/GameProgress.vue";
import { useI18n } from 'vue-i18n'

const store = useStore();
const nickname = ref(null)

const { tm } = useI18n();

const projectOutput = computed(()=>{
  const cards = store.state.finalCards && store.state.finalCards.length > 0 ? Object.entries(store.state.finalCards).map((item)=>item[1]) : [];
  const dataC = tm('dataCards')
  let cardsTitles = cards ? `Cards:\n` : '';
  if(cards && cards.length > 0){
    cards.forEach((card)=>{
      cardsTitles += `${dataC.find(c => c.index ===card).name}\n`
    })
  }
  const scenarioUser = store.state && store.state.scenario && store.state.scenario.name ? store.state.scenario.name : '';
  const projectTitle = store.state && store.state.projectDescription && store.state.projectDescription.title ? store.state.projectDescription.title : ''
  const projectCaption = store.state && store.state.projectDescription && store.state.projectDescription.caption ? store.state.projectDescription.caption : ''
  const projectDescription = store.state && store.state.projectDescription && store.state.projectDescription.description ? store.state.projectDescription.description : ''
  return `Thank you for playing!\n\nName:\n${nickname.value || ''}\n\nScenario:\n${scenarioUser}\n\nProject:\n${projectTitle}\n${projectCaption}\n${projectDescription}\n\n${cardsTitles}`
})

const cardsDisplay = computed(() => store.state.cardsReference);
const gameLevel = computed({
  get() {
    return store.state.gameLevel;
  },
  set(value) {
    store.commit("setGameLevel", value);
  },
});

  onMounted(()=>{
  window.addEventListener("beforeunload",beforeUnload);
  })
  onBeforeUnmount(()=>{
    window.removeEventListener("beforeunload",beforeUnload);
  })


const beforeUnload = (e)=>{
  if(import.meta.env.DEV){
    return
  }
  var confirmationMessage = "\o/";

  (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  return confirmationMessage;                            //Webkit, Safari, Chrome
}

watch(store.state,()=>{
  if(store.state.userProfile.nickname){
    nickname.value = store.state.userProfile.nickname
  }
})

watch(
  () => gameLevel.value,
  (newValue) => {

    window.scrollTo(0,0)
    if (newValue === 4) {
      store.commit("setFinalCards", store.state.currentCards);
    }

    if (newValue === 5) {
      const gameOutput = {
        user: store.state.userProfile,
        scenario: store.state.scenario.index,
        rounds: store.state.gameLog,
        projectDescription: store.state.projectDescription,
      };
      store.commit("setGameOutput", gameOutput);
    }
  }
);

const onSubmit = ()=>{
  store.commit("setUserNickname", nickname.value);
}

const showGameOutput = ref(false);
const gameOutputCard = ref(null);
function toggleGameOutput() {
  showGameOutput.value = !showGameOutput.value;
}
function copyToClipboard(e) {
  const copyText = e.path[0].textContent;
  navigator.clipboard.writeText(copyText);
}
</script>

<style lang="scss">

@import '../style/shared.scss';

.play-view {
  width: 100vw;
  min-height: 100vh;
  /* border: 2px solid red; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include max-responsive(sm){
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;

    .text-h2{
      font-size: 2rem;
      line-height: 1.2;
    }
    .text-h5{
      font-size: 1.2rem;
      line-height: 1.2;
    }
    .xs-container{
      margin-top: 50px;
    }
    .logo_wrapper{
      display:none;
    }
  };
  .logo_wrapper{
    position: fixed;
    bottom: 30px;
    left: 30px;
    @media (hover: hover) {
      &:hover{
        span{
          opacity: 1;
          transform: translate(-50%,0);
        }
      }
    }
    span{
      white-space: nowrap;
      color: var(--q-primary) !important;
      position: absolute;
      opacity: 1;
      top: -30px;
      left: 50%;
      transform: translate(-50%,0);
      transition: transform .4s ease, opacity .4s ease;
    }
    .logo{
      width: 100px;
    }
    @media screen and (min-width: 701px) and (max-height:800px){
      span{
        opacity: 0;
      }
    } 
    @include max-responsive(sm){
      bottom: 50px;
      position: absolute;
      .logo{
        width: 100px;
      }
    };
  }
}

.full-width-element {
  width: 100vw;
  @include max-responsive(sm){
    padding: 0 var(--page-gutter);
  };
}
.final-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  .profilation-form{
    justify-content: center;
    margin-bottom: 20px;
    .q-field--with-bottom{
      padding-bottom: 0;
    }
    .profilation-form__button{
      display: flex;
      align-items: center;
    }
  }
}
.game-output {
  user-select: all;
}
.scenario-cards-indicator {
  position: fixed;
  top: 20px;
  left: 20px;
}
.output-pre {
  position: relative;
  display: flex;
}
</style>
