import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { collection,getCountFromServer, updateDoc,arrayUnion,orderBy,getDoc,setDoc,startAt,addDoc, onSnapshot, getFirestore,doc,limit,query,where, getDocs, serverTimestamp } from "firebase/firestore";
import _ from "lodash";
import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyAzEDFhMUoNwxTr2qettBrYvh7wi5p-OTs",
  authDomain: "data-slots.firebaseapp.com",
  projectId: "data-slots",
  storageBucket: "data-slots.appspot.com",
  messagingSenderId: "419791917196",
  appId: "1:419791917196:web:8b9b3a4be29ada6acb2ba9",
  measurementId: "G-V9ELVKEZ2N"
};

class FirebaseClient {
  constructor ({ store }) {
    this.app = initializeApp(firebaseConfig);
    this.firestore = getFirestore();
    this.store = store;
    this.sessionId = null
    this.docRef = null
    this.currentSessionData = null
    this.listener = null
    this.analytics = getAnalytics(this.app);
    const auth = getAuth();
    signInAnonymously(auth).then(() => {
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode,errorMessage);
    });
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
      } else {
      }
    });
  }
  addSessionListener(){
    const docRef = doc(this.firestore, 'sessions', this.sessionId)
    this.listener = onSnapshot(docRef, (doc) => {
      this.currentSessionData = doc.data()
    })
  }
  addError({message = ''}){
    console.log('addError',message)
    return addDoc(collection(this.firestore, "errors"), {
      docRef: this.docRef || 'unknown',
      timestamp: serverTimestamp(),
      message
    })
  }
  async addSession(payload){
    // if(import.meta.env.DEV)return
    try{
      await addDoc(collection(this.firestore, "sessions"), Object.assign(payload,{createdOnline:true, timestamp: serverTimestamp()})).then((docRef)=>{
        this.currentSessionData = payload;
        this.store.state.sessionId = docRef.id
        this.sessionId = docRef.id
        this.docRef = docRef
        this.addSessionListener()
      })
    }catch(e){
      console.log(e)
      this.addError({message:'Error saving the user session'})
    }
  }
  async addScenario(payload){
    // if(import.meta.env.DEV)return
    if(!this.docRef)return
    try{
      updateDoc(this.docRef, {
        scenario:payload.index
      })
    }
    catch(e){
      console.log(e)
      this.addError({message:'Error saving the user scenario'})
    }
  }
  async setFinalCards(payload){
    if(!this.docRef)return
    const string = payload.reduce((acc,cur) => acc ? `${acc},${cur}` : cur,'');
    try{
      updateDoc(this.docRef, {
        finalCards: string
      })
    }catch(e){
      console.log(e)
      this.addError({message:'Error saving the final cards'})
    }
  }
  async updateUserNickName(payload){
    if(!this.docRef || !payload)return
    try{
      updateDoc(this.docRef, {
        nickname: payload,
        nickname_lower: payload.toLowerCase()
      })
    }
    catch(e){
      console.log(e)
      this.addError({message:'Error saving user Nick name'})
    }
  }
  async setProjectDescription(payload){
    if(!this.docRef)return
    let languageDetect = ''
    try{
      const {data} = await axios.post('https://libretranslate.com/detect',{
        q:payload.description,
        api_key: 'a532fab0-b8fd-478d-aaa5-67ed4c9d125b'
      })
      languageDetect = data && data[0] ? data[0].language : false
    }
    catch(e){
      console.log(e)
      this.addError({message:'Error with libre translate to get correct project language'})
    }
    try{
      updateDoc(this.docRef, {
        title_lower:payload.title ? payload.title.toLowerCase() : '',
        title:payload.title,
        caption:payload.caption,
        description:payload.description,
        languageDetect
      })
    }catch(e){
      console.log(e)
      this.addError({message:'Error saving user project'})
    }
  }
  async getUsersProjects(scenario){
    let totalSessions = 0
    try{
      const q = query(collection(this.firestore, 'sessions'), where("title","!=",false),where("scenario","==",scenario))
      const snapshot = await getCountFromServer(q);
      totalSessions = snapshot.data().count
    } catch(e){
      console.log(e)
      this.addError({message:'Error getting the total number of sessions (used to query randomly documents)'})
    }
    try{
      const randomOffset = totalSessions  ? Math.floor(Math.random() * (totalSessions - 20)) : 0
      const q = await query(collection(this.firestore, 'sessions'), where("title","!=",false), where("scenario","==",scenario), orderBy('title'),limit(20),startAt(randomOffset))
      const docsRequest = await getDocs(q)
      return docsRequest && docsRequest.docs ? _.shuffle(docsRequest.docs) : []
    }
    catch(e){
      console.log(e)
      this.addError({message:'Error getting user projects'})
      return []
    }
  }
  async getScoreboardProjects(){
    try{
      const q = await query(collection(this.firestore, 'sessions'), where("investmentsAvg",">=",0) ,orderBy("investmentsAvg","desc"), limit(5))
      const docsRequest = await getDocs(q)
      return docsRequest && docsRequest.docs ?  docsRequest.docs : []
    }
    catch(e){
      console.log(e)
      this.addError({message:'Error getting scoreboard project'})
      return []
    }
  }
  async getRank(score,projectId){
    try{
      const q = await query(collection(this.firestore, 'sessions'), where("investmentsAvg",">=",score),orderBy("investmentsAvg","desc"))
      const docsRequest = await getDocs(q)
      return docsRequest && docsRequest.docs ?  docsRequest.docs.filter((doc)=> doc.id !== projectId).length : []
    }
    catch(e){
      console.log(e)
      this.addError({message:`Error getting rank for project ${projectId}`})
      return []
    }
  }
  async searchProject(key,queryString){
    try{
      const q = await query(collection(this.firestore, 'sessions'), where(key,">=",queryString), limit(1))
      const docsRequest = await getDocs(q)
      return docsRequest && docsRequest.docs ?  docsRequest.docs : []
    }
    catch(e){
      this.addError({message:`Error searching for project: ${queryString}`})
    }
  }
  async setProjectInvestments(payload){
    for (var i = 0; i < payload.length; i++) {
      const session = payload[i];
      if(session){
        const sessionDocRef = doc(this.firestore,"sessions",session.project.id);
        const docSnap = await getDoc(sessionDocRef)
        if(docSnap.data()){
          const docData = docSnap.data()
          const allInvestments = docData.investments || [];
          allInvestments.push({session:this.sessionId,investment: session.investment})
          const investmentsTotal = allInvestments.reduce((acc,cur)=> acc ? acc + cur.investment : cur.investment,0);
          await updateDoc(sessionDocRef, {
            investmentTotal: investmentsTotal,
            investmentsAvg: parseFloat(investmentsTotal / allInvestments.length),
            investments: allInvestments,
            investmentCount: allInvestments.length
          }).catch((e)=>{
            this.addError({message:`Error setting project investments ${sessionDocRef}`})
          })
        }
      }
    }
  }
  async setProjecstRatings(payload){
    if(!this.docRef)return

    for (var i = 0; i < payload.length; i++) {
      const session = payload[i];
      if(session.id === this.sessionId){
        try{
          console.log("will rate", {session:this.sessionId,invasiveness: session.ratings.invasiveness,benefit: session.ratings.benefit})
          await updateDoc(this.docRef, {
            invasiveness: session.ratings.invasiveness,
            benefit: session.ratings.benefit,
            assessments:[
              {session:this.sessionId,invasiveness: session.ratings.invasiveness,benefit: session.ratings.benefit}
            ],
            ratingsCount:1
          })
        }
        catch(e){
          console.log(e)
          this.addError({message:`Error setting project ratings`})
        }
      } else{
        const sessionDocRef = doc(this.firestore,"sessions",session.id);
        const docSnap = await getDoc(sessionDocRef)
        if(docSnap.data()){
          const docData = docSnap.data()
          const allAssessments = docData.assessments || [];
          allAssessments.push({session:this.sessionId,invasiveness: session.ratings.invasiveness,benefit: session.ratings.benefit})
          console.log(session.id,allAssessments.length)
          try{
            await updateDoc(sessionDocRef, {
              invasiveness: parseFloat((allAssessments.reduce((r,c)=> r + c.invasiveness,0) / allAssessments.length)),
              benefit: parseFloat((allAssessments.reduce((r,c)=> r + c.benefit,0) / allAssessments.length)),
              assessments: allAssessments,
              ratingsCount: allAssessments.length
            })
          }
          catch(e){
            console.log(e)
            this.addError({message:`Error setting project ratings ${sessionDocRef}`})
          }
        }
      }
    }

    //
    // payload
    // updateDoc(this.docRef, {
    //   invasiveness:payload.invasiveness,
    //   benefit:payload.benefit,
    //   assessments:[{sessions:this.sessionId,invasiveness:payload.invasiveness,benefit:payload.benefit}]
    // })
  }
  async addToGameLog(payload){
    // if(import.meta.env.DEV)return
    if(!this.docRef)return
    if(payload.level === 0){
      try{
        await updateDoc(this.docRef, {
          gameLogs:[payload]
        })
      }catch(e){
        console.log(e)
        this.addError({message:`Error saving game log`})
      }
    }
    else{
      const filteredObj = Object.fromEntries(
        Object.entries(payload).filter(([_, value]) => value !== undefined)
      );
      try{
        await updateDoc(this.docRef, {
          gameLogs:arrayUnion(filteredObj)
        })
      } catch(e){
        this.addError({message:`Error saving game log`})
      }
    }
    // setDoc(doc(this.firestore, `sessions/${this.sessionId}/gameLog`,String(payload.level)),payload)
    // const cardsReference = this.store.state.cardsReference;
    // if (payload.level === 1){
    //   const lockedCard = cardsReference.find((c)=>c.index === payload.lockedCard)
    //   let updateValues = {}
    //   payload.currentCards.forEach((card, i) => {
    //     const matchingRef = cardsReference.find((c)=>c.index === card)
    //     updateValues[matchingRef.id] = `1${lockedCard.id === matchingRef.id ? `,0`:''}`;
    //   });
    //   updateDoc(this.docRef, updateValues)
    // }
    // else if (payload.level === 2 || payload.level === 3 || payload.level === 4){
    //   let updateValues = {}
    //   payload.currentCards.forEach((card, i) => {
    //     const matchingRef = cardsReference.find((c)=>c.index === card)
    //     const currentValue = this.currentSessionData[matchingRef.id]
    //     updateValues[matchingRef.id] = `${currentValue ? `${currentValue},`:''}${payload.level}`;
    //   });
    //   updateDoc(this.docRef, updateValues)
    // }
  }
}

export default {
  install: (app, options) => {
    const client = new FirebaseClient({ store: app.config.globalProperties.$store })
    app.provide('firebase', client)
    app.config.globalProperties.$store.$firebase = client;
  }
}
