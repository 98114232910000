<template>
  <div v-if="cards">
    <!-- <q-card class="row q-pa-lg custom-shadow"> -->
    <!-- <div class="column q-pa-xl">
      <div class="text-h4 text-primary">{{contents[store.state.language].cardSelect.message}}</div>
    </div> -->
      <GameHint
        class="q-mb-md"
        :message="$t('gameHints.cardSelect.message')"
        :hint="$t('gameHints.cardSelect.hint')"
        :icon="$t('gameHints.cardSelect.icon')"
      />

    <!-- <div class="row flex-center md-container">
      <Card
        v-for="(card, index) in cards"
        :key="`card-data-${index}`"
        :index="index"
        :cardData="card"
        class="q-mx-sm scenario-card"
        :flipped="true"
        @clicked="() => flipCard(card)"
        color="#e6293d"
      />
    </div> -->

    <div class="xs-container xs-container--scenario" @click="flipMobile">
      <div class="scenario-card-container" :class="{'flipped':mobileFlipped}">
        <div class="scenario-card">
          <div class="card-structure">
            <div class="scenario-card__front">
              <div
                class="card-index row text-bold q-pl-md"
              >
                <div class="">#{{ randomMobileCard.index.toString().padStart(2, 0) }}</div>
              </div>
              <div
                class="row card-name text-h5 q-pl-md q-mt-md text-bold"
                v-html="randomMobileCard.name.split(' ').join('<br/>')"
              />
              <div class="outlinedrow card-description q-pa-md self-end">
                <div>
                  {{ randomMobileCard.description }}
                </div>
              </div>
            </div>
            <div class="scenario-card__back">
              <div class="text-white text-h4 text-bold q-pt-lg">
                Data slots
              </div>
            </div>
          </div>
        </div>
      </div>

      <q-btn
        unelevated
        rounded
        class="next-step"
        color="primary"
        :class="{'visible':mobileFlipped}"
        @click="goNextMobile"
        >{{$t('profilation.confirm')}}</q-btn
      >
    </div>

    <!-- </q-card> -->
  </div>
</template>


<script setup>
import Card from "../components/Card.vue";
import _ from "lodash";
import { computed, ref, watch, reactive, inject } from "vue";
import { colors } from "quasar";
// import { scenarioCards } from "../contents/cards.js";
import { gameHints as contents } from "../contents/gameHints.js";

import GameHint from "../components/GameHint.vue";

import { useStore } from "vuex";

import { useI18n } from 'vue-i18n'
const { tm } = useI18n();

const store = useStore();

const scenarioCards = tm('scenarioCards');
scenarioCards.forEach((c) => (c.properties = { flipped: true, locked: false }));

const cards = ref(_.cloneDeep(scenarioCards));
const cardSelected = ref(false);
const mobileFlipped = ref(false);

const emit = defineEmits(["completed"]);

const selectedScenario = scenarioCards[Math.floor(Math.random(0)*(scenarioCards.length))];
const randomMobileCard = selectedScenario || scenarioCards[2];

function flipMobile(){
  mobileFlipped.value = true;
}
function goNextMobile(){
  store.commit("setScenario", randomMobileCard);
  emit("completed",randomMobileCard)
}
function flipCard(card) {
  if (!cardSelected.value) {
    cardSelected.value = true;
    card.properties.flipped = !card.properties.flipped;
    setTimeout(() => {
      store.commit("setScenario", card);
      emit("completed", card);
    }, 1500);
  }
}
</script>

<style lang="scss">
@import '../style/shared.scss';
  .md-container{
    @include max-responsive(sm){
      display: none !important;
    };
  }
  .xs-container{
    padding: 0 40px;
    display: none ;
    max-width: 360px;
    margin: 0 auto;

    &.xs-container--scenario{
      display: block !important;
      max-width: 360px;
      margin: 0 auto;
    }
    @include max-responsive(sm){
      display: block;
    };

    .next-step{
      margin-top: 10px;
      pointer-events: none;
      opacity: 0;
      transition: opacity .5s 1.5s;

      &.visible{
        pointer-events: auto;
        opacity: 1;
      }
    }


    .scenario-card-container{
      width: 100%;
      padding-top: 140%;
      position: relative;

      &:before,&:after{
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #e6293d;
        z-index: 1;
        transform-origin: center bottom;
        border: solid 1px rgb(191, 191, 191);
        transition: transform 1s $ease-out-expo, opacity .1s 1s;
        border-radius: 5% !important;
      }
      &:before{
        transform: rotate(-5deg);
      }
      &:after{
        transform: rotate(5deg);
      }

      &.flipped{
        &:before,&:after{
          transform: none;
          opacity: 0;
        }
        .card-structure{
          transform: perspective(600px) rotateY(0);
          -webkit-perspective: 600px;
          -moz-perspective: 600px;
          -o-perspective: 600px;
          -ms-perspective: 600px;
        }
      }
      .scenario-card{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: white;
        border-radius: 5% !important;
        // overflow: hidden;

        /* content backface is visible so that static content still appears */
        // backface-visibility: visible;
        // -webkit-backface-visibility: visible;
        // -moz-backface-visibility: visible;
        // -o-backface-visibility: visible;
        // -ms-backface-visibility: visible;
      }
      .card-structure{
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 1s 1.3s $ease-out-expo;
        background: rgba(#2e434d, 0.15);
        // overflow: hidden;
        border: solid 1px rgb(191, 191, 191);
        transform: perspective(600px) rotateY(179.9deg);
        -webkit-perspective: 600px;
        -moz-perspective: 600px;
        -o-perspective: 600px;
        -ms-perspective: 600px;
        transform-style: preserve-3d;
        will-change: transform;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;

        .scenario-card__back, .scenario-card__front{
          -webkit-backface-visibility: hidden; /* Safari */
          backface-visibility: hidden;
        }

        .scenario-card__front{
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .scenario-card__back{
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: perspective(600px) rotateY(180deg);
          -webkit-perspective: 600px;
          -moz-perspective: 600px;
          -o-perspective: 600px;
          -ms-perspective: 600px;
          -webkit-backface-visibility: hidden; /* Safari */
          backface-visibility: hidden;
          display: flex !important;
          border-radius: 5% !important;
          justify-content: center;
          align-items: center;
          background: #e6293d;
        }
        .card-index{

        }

      }
    }
  }
</style>
