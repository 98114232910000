import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "Dados de perfil pessoal", "name_short": "Perfil pessoal", "description": "Gênero, etnia, crenças religiosas, orientação sexual, opiniões políticas, preferências musicais, preferências de TV/filmes ou serviços streaming, hábitos de uso de celular, agenda." },
      { "index": 2, "id":"2_health", "name": "Dados de saúde", "name_short": "Saúde", "description": "Condição física e mental, informação genética ou biométrica, peso, IMC, estresse, acesso a infraestruturas de saúde." },
      { "index": 3, "id":"3_dietary", "name": "Dados de hábitos alimentares", "name_short": "Háb alimentares", "description": "Escolhas de alimentos ou bebidas, alergias e intolerâncias, hábitos de fumar, uso de drogas, informações nutricionais, preferências em termos de bares, restaurantes." },
      { "index": 4, "id":"4_transactions", "name": "Dados de transações eletrônicas", "name_short": "Trans eletrônicas", "description": "Uso de cartão de crédito, internet banking, compras offline e online, informações de consumo de produtos." },
      { "index": 5, "id":"5_socialnetworks", "name": "Dados de redes sociais", "name_short": "Redes sociais", "description": "Uso pessoal de redes sociais, comunicação de trabalho, e-mail, aplicativos, reuniões presenciais, aplicativos de namoro, plataformas de videochamadas." },
      { "index": 6, "id":"6_human", "name": "Dados de mobilidade humana", "name_short": "Mobilidade humana", "description": "Mobilidade individual, uso de transporte privado ou público com informações sobre geolocalização, horário, contagem de pessoas." },
      { "index": 7, "id":"7_animal", "name": "Dados de mobilidade animal", "name_short": "Mobilidade animal", "description": "Mobilidade de animais de estimação ou pecuária, com informações sobre geolocalização, horário, contagem de animais." },
      { "index": 8, "id":"8_vehicle", "name": "Dados de mobilidade do veículo", "name_short": " Mobilidade do veículo", "description": "Mobilidade dos veículos (carros, bicicletas, patinetes, ônibus, aviões), com informações sobre geolocalização, horário, contagem e características dos veículos." },
      { "index": 9, "id":"9_utility", "name": "Dados de serviços", "name_short": "Serviços", "description": "Consumo de água, eletricidade e gás, hábitos de aquecimento, ventilação ou ar-condicionado, hábitos de coleta lixo e reciclagem, telefone fixo, wifi." },
      { "index": 10, "id":"10_environmental", "name": "Dados do meio-ambiente", "name_short": "Meio-ambiente", "description": "Qualidade do ar interior e exterior, temperatura, produtos químicos, incêndio, iluminação, poluição sonora." },
      { "index": 11, "id":"11_infrastructure", "name": "Dados de infraestrutura pública", "name_short": "Infraestrutura", "description": "Pontes, estado das estradas, mobiliário público, semáforos, manutenção dos espaços públicos." },
      { "index": 12, "id":"12_greenery", "name": "Dados de vegetação", "name_short": "Vegetação", "description": "Árvores, parques, jardins, áreas agrícolas, arborização urbana e diversidade de plantas." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Ambiente doméstico", "description": "Aspectos da vida doméstica das pessoas que contribuem para suas condições de vida. Estão incluídos espaços ao ar livre e interiores, casas particulares mas também instituições comunitárias residenciais, como lares de idosos, e habitações coletivas, como orfanatos, prisões, e instalações de tratamento de dependentes químicos." },
      { "index": 2, "color":"#295966", "name": "Ambiente de trabalho", "description": "Elementos que compõem o ambiente em que os funcionários trabalham e impactam os trabalhadores, como características sociais e condições físicas. Esses aspectos podem impactar sentimentos de bem-estar, relações de trabalho, colaboração, eficiência e saúde dos funcionários. Pode variar de um escritório em casa a um prédio de escritórios ou fábrica, e pode ser externo ou interno." },
      { "index": 3, "color":"#8862A6", "name": "Espaço público", "description": "Local geralmente aberto e acessível às pessoas, como estradas, praças públicas, parques, praias, ciclovias, garagens; prédios governamentais, como bibliotecas públicas; instalações, como elevadores; lojas, restaurantes, centros comunitários, instalações esportivas, salões de festas, teatro ou cinemas." }
  ],
  discover:{
    blocks:[
      {
        title:'Contexto',
        text:"Dados estão sendo coletados em todos os lugares. As soluções orientadas por dados estão mudando a forma como usamos espaços públicos, ambientes domésticos e ambientes de trabalho."
      },
      {
        title:"Missão",
        text:"Data Slots é um jogo com uma missão única: propor soluções baseadas em dados equilibrando benefícios e preocupações com privacidade."
      },
      {
        title:"O Jogo",
        subtitle:"Cartas de cenários",
        id:"scenario",
        text:"Selecione aleatoriamente uma carta de cenário para o qual irá propor suas ideias."
      },
      {
        subtitle:"Cartas de dados",
        id:"data-cards",
        text:"Você receberá três cartas de dados aleatórios. Bloqueie uma carta, que você manterá por todo o jogo. Em seguida, troque outras cartas de dados para construir seus ativos."
      },
      {
        title:"Ideação",
        id:"ideation",
        text:"Use ao menos 3 cartas de dados para propor soluções para o seu cenário."
      },
      {
        title:"Avaliação",
        id:"assessments",
        text:"Depois de enviar sua solução, Data Slots exibirá outras três ideias criadas por outros jogadores. <br> Depois de enviar sua solução, Data Slots exibirá outras três ideias criadas por outros jogadores."
      },
      {
        title:"Investimento",
        id:"investment",
        text:"Finalmente, invista suas moedas nessas ideias."
      },
      {
        title:"",
        id:"scoreboard",
        text:"Não perca o placar! Crie um apelido e acompanhe sua classificação através do Scoreboard."
      }
    ],
    contact:"<strong>Contato:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Isenção de responsabilidade:</strong> Data Slots não solicita nenhum dado pessoal. Os dados gerados em Data Slots são anônimos e serão usados apenas para pesquisas acadêmicas.",
    credits:"<strong>Equipe:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Website:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Agradecimentos:</strong> Yuki Machida, Oluwatobi Oyinlola, e membros do SCL, bem como participantes em cidades ao redor do mundo que jogaram a versão presencial de Data Slots e nos ajudaram a melhorar o jogo. Agradecemos ao membros do consórcio SCL."
  },
  gameHints:{
    yourCards:"Suas cartas",
    cardsDeck:"Baralho de cartas",
    introText:'Um jogo de 10 minutos sobre a ética e o valor das soluções orientadas por dados.',
    introPlay:'Jogar',
    introDiscover:'Saiba mais',
    introScoreboard:'Placar',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'Por favor, selecione um cenário', hint: 'Sua missão é projetar uma ideia baseada em dados para criar experiências para um desses cenários: espaço público, ambiente doméstico, ou ambiente de trabalho.', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: 'Você tem três cartas. Bloqueie um para mantê-la ao longo de todo o jogo', hint: 'Selecioná-lo tocando no cartão', icon: '' },
      level1: { message: 'Agora escolha a carta que deseja remover do seu baralho', hint: 'Toque no cartão', icon: '' },
      level2: { message: 'Agora escolha a carta que você quer no seu baralho', hint: 'Toque no cartão', icon: '' },
      level3: { message: 'Agora escolha outra carta que deseja remover do seu baralho', hint: 'Toque no cartão', icon: '' },
      level4: { message: 'Agora escolha a carta que você quer no seu baralho', hint: 'Toque no cartão' , icon: '' },
      level5: { message: 'Agora escolha sua última carta', hint: 'Toque no cartão', icon: '' },
      level6: { message: 'Estas são suas cartas finais', hint: 'Toque em "continuar" para propor sua ideia' }
    },
    slot: {
        level0: { message: 'Você tem três cartas. Bloqueie um para mantê-la ao longo de todo o jogo', hint: 'Basta escolhê-lo clicando', icon: 'highlight_alt' },
        level1: { message: 'Agora troque uma de suas cartas', hint: 'Arrastar uma carta para o baralho superior', icon: 'swipe_up' },
        level2: { message: 'Agora troque uma outra carta', hint: 'Arrastar uma carta para o baralho superior', icon: 'swipe_up' },
        level3: { message: 'Agora pegue sua última carta', hint: 'Arrastar uma carta do baralho superior', icon: 'swipe_down' },
        level4: { message: 'Essas são suas cartas finais', hint: 'Clique em continuar para propor a sua ideia', }
    },
    sideButtons: {
        scanario: { label: 'cenários' },
        cards: { label: 'minhas cartas' }
    },
    finalMessage: {
      playAgain:'Jogar de novo',
      scoreboard:'Placar',
      name: {
        hint: 'seu nome',
        error: 'Por favor, digite algo',
        confirm:'Atualizar'
      },
      message: 'Obrigado por jogar Data Slots',
      hint:"Este é o seu apelido para conferir o placar <br> Volte em breve e use seu apelido para verificar o ranking da sua ideia"
    }
  },
  profilation:{
      title: 'Conte-nos mais sobre você',
      hint: '',

      name: {
          hint: 'seu nome',
          error: 'Por favor, digite algo'
      },
      role: {
          hint: 'Seu papel',
          options: [
              'Acadêmico',
              'Cidadão',
              'Funcionário público'
          ],
          error: 'Por favor, digite algo'

      },
      region: { hint: 'Onde está jogando?', options: regions,error: 'Por favor, digite algo' },
      age: {
          hint: 'Sua idade',
          options: [
              'menor de 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'maior de 65'
          ],
          error: 'Por favor, digite algo'

      },
      gender: {
          hint: 'Pronomes',
          options: [
              'Ela',
              'Ele',
              'Indefinido',
              'Deixe-me digitar',
              'Prefiro não dizer'
          ],
          error: 'Por favor, digite algo'
      },
      customGender: {
          hint: 'Pronomes',
          error: 'Por favor, digite algo'
      },
      confirm: 'Continuar'
  },
  ideation:{
      title: 'Sua ideia',
      hint: 'Qual ideia/experiência você sugeriria para o seu cenário combinando pelo menos 3 de suas cartas? <br> Você tem 5 minutos.',
      projectTitle: {
          hint: 'Título do projeto',
          error: 'Por favor, digite algo'
      },
      name: {
          hint: 'seu nome',
          error: 'Por favor, digite algo'
      },
      projectCaption: {
          hint: 'Frase que resume o projeto',
          error: 'Por favor, digite algo'
      },
      projectDescription: {
          hint: 'Descrição breve do projeto',
          error: 'Por favor, digite algo'
      },
      projectRatings:{
        translation:"* Alguns dos projetos são traduzidos automaticamente de seu idioma original",
        ratings:{
          hint:'Classifique as ideias',
          subtitle:'Invasividade &#8594; preocupações de privacidade sobre o uso de dados em cada ideia <br> Benefícios &#8594; benefícios criados por cada ideia'
        },
        investments:{
          hint:'Agora você é o investidor',
          subtitle:'Você tem dez moedas. Mova as moedas para investir nessas ideias',
          icon:'swipe_up'
        }
      },
      read_more:'Saiba mais',
      confirm: 'Submeter',
      invasiveness:'Invasividade',
      benefit:'Benefício',
      high:'Grande',
      low:'Pequeno'
  },
  scoreboard:{
    search:'Procurar',
    name:'Apelido',
    position:'Ranking',
    title:'Título',
    country: 'País',
    score: 'Pontos',
    ratings:'Classificações'
  }
}