<template>
  <q-form
    @submit="onSubmit"
    class="profilation-form row align-center q-col-gutter-y-sm q-col-gutter-x-md"
  >
    <!-- <q-input
      square
      :filled="filled"
      v-model="name"
      :label="contents[store.state.language].name.hint"
      :class="elementClasses"
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || contents[store.state.language].name.error]"
    /> -->
    <!-- <q-select
      square
      :filled="filled"
      v-model="role"
      :label="contents[store.state.language].role.hint"
      :options="contents[store.state.language].role.options"
      :class="elementClasses"
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || contents[store.state.language].role.error]"
    /> -->
    <q-select
      square
      :filled="filled"
      v-model="region"
      :label="$t('profilation.region.hint')"
      :options="$tm('profilation.region.options')"
      :class="elementClasses"
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || $t('profilation.region.error')]"
    />

    <q-select
      square
      :filled="filled"
      v-model="age"
      :label="$t('profilation.age.hint')"
      :options="$tm('profilation.age.options')"
      :class="elementClasses"
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || $t('profilation.age.error')]"
    />
    <q-select
      square
      :filled="filled"
      v-model="gender"
      :label="$t('profilation.gender.hint')"
      :options="$tm('profilation.gender.options')"
      :class="elementClasses"
      lazy-rules
      v-if="gender !== $tm('profilation.gender.options')[3]"
      :rules="[(val) => (val && val.length > 0) || $t('profilation.gender.error')]"
    />

    <q-input
      square
      :filled="filled"
      :label="$t('profilation.customGender.hint')"
      v-if="gender === $tm('profilation.gender.options')[3]"
      v-model="customGender"
      :class="elementClasses"
      lazy-rules
      ref="customGenderElement"
      :rules="[(val) => (val && val.length > 0) || $t('profilation.customGender.error')]"
    />

    <!-- <q-select standout v-model="model" :options="options" label="Standout" /> -->
    <div class="col-12 row">
      <q-btn :label="$t('profilation.confirm')" type="submit" color="primary" unelevated rounded />
    </div>
  </q-form>
</template>

<script setup>
import {profilation as contents} from "../contents/profilation"
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";
// import { useI18n } from 'vue-i18n'
// const { tm, rt } = useI18n();

import {  ref, watch } from "vue";
import {useStore} from "vuex";

const store = useStore();


const emit = defineEmits(["completed"]);

const name = ref(
  uniqueNamesGenerator({
    dictionaries: [adjectives, animals],
    style: "capital",
    separator: " ",
  })
);

const elementClasses = "col-xs-12 col-md-12";
const filled = ref(true);

const role = ref();



const region = ref();

const gender = ref();
const customGender = ref();
const customGenderElement = ref(null);

watch(customGenderElement, (newVal) => {
  customGenderElement.value.focus();
});
const age = ref();

function onSubmit() {
  // console.log("submitted")
  // name: name.value,
  // role: role.value,
  const output = {
    nickname: name.value,
    nickname_lower:name.value ? name.value.toLowerCase() : '',
    region: region.value,
    age: age.value,
    gender: customGender.value || gender.value,
  };

  emit("completed", output);
}
</script>

<style lang="scss">
@import "../style/shared.scss";

  .profilation-form{
    @include responsive(sm){
      min-width: 450px;
    };
  }
</style>
