import { createRouter, createWebHistory } from "vue-router";
// 1. Define route components.
// These can be imported from other files
import Home from "../views/home.vue"
import Play from "../views/play.vue"
import Discover from "../views/discover.vue"
import Scoreboard from "../views/scoreboard.vue"
// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [{
        path: '/',
        component: Home,
        meta: { transition: 'scale' },
    },
    {
        path: '/play',
        component: Play,
        meta: { transition: 'scale' },
    },
    {
        path: '/discover',
        component: Discover,
        meta: { transition: 'scale' },
    },
    {
        path: '/scoreboard',
        component: Scoreboard,
        meta: { transition: 'scale' },
    }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    // mode: 'history',
    history: createWebHistory(
        import.meta.env.BASE_URL),
    routes, // short for `routes: routes`
})

export default router;
