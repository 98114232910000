<template>
  <div class="card-project-wrapper" :class="{'translating':((props.cardData.languageDetect && props.cardData.languageDetect !== $i18n.locale) || ($i18n.locale !=='en' && !props.cardData.languageDetect)) && !props.cardData.originalTitle}">
    <q-responsive :ratio="2.5 / 3.5" class="card-wrapper card-project" :class="{'isRating':isRating}">
      <q-card
        class="custom-shadow card-structure"
        :class="{ 'card-flipped': flipped }"
        bordered
        @mouseleave="flipCard(false)"
      >
        <TransitionGroup name="fade" mode="out-in" tag="div">
          <div v-if="isRating" class="card-progress" :class="{'--step-1':(benefit > 0 && invasiveness === 0) || (invasiveness >0 && benefit === 0), '--step-2':benefit>0&&invasiveness>0}">
            <svg>
            <circle
                    r="7"
                    cy="8.5"
                    cx="8.5"
                    class="progress-circle__path">
            </circle>
            <circle
                    r="7"
                    cy="8.5"
                    cx="8.5"
                    stroke-dasharray="200 1000"
                    class="progress-circle__progress">
            </circle>
          </svg>
          </div>
        </TransitionGroup>
        <div class="card-front text-left q-pa-md">
          <div class="" :style="`--chip-color:${scenario.color}`">
            <h6 class="q-my-none text-weight-bolder project-title"><span class="w"> <span class="t">{{props.cardData.title}}</span> <span class="translating"></span> </span></h6>
            <p class="q-mb-none text-subtitle1 project-caption"><span class="w"><span class="t">{{ props.cardData.caption.length > 50 ? `${props.cardData.caption.substr(0,50)}...` : props.cardData.caption  }}</span> <span class="translating"></span></span></p>
            <span @click="flipCard(true)" class="link">{{$t('ideation.read_more')}}</span>
          </div>
          <div class="card-description">
            <div class="">
              <span class="chip chip-scenario">{{ scenario.name }}</span>
            </div>
            <div class="chip-container">
              <template v-if="projectCards && projectCards.length > 0">
                <span v-for="card in projectCards" class="chip ">{{card.name_short || card.name}}</span>
              </template>
            </div>

          </div>

          <div v-if="!isRating" :data-investments-id="index" class="investments-container">

          </div>

          <q-field
              v-if="isRating"
              borderless :label="$t('ideation.invasiveness')" stack-label
            >
            <q-rating
              v-model="invasiveness"
              size="1em"
              icon="img:images/rating_empty.svg"
              icon-selected="img:images/rating.svg"
            />
            <div class="labels">
              <span>{{$t('ideation.low')}}</span>
              <span>{{$t('ideation.high')}}</span>
            </div>
          </q-field>
          <q-field
              v-if="isRating"
              borderless :label="$t('ideation.benefit')" stack-label
            >
            <q-rating
              v-model="benefit"
              size="1em"
              icon="img:images/rating_empty.svg"
              icon-selected="img:images/rating.svg"
            />
            <div class="labels">
              <span>{{ $t('ideation.low') }}</span>
              <span>{{ $t('ideation.high') }}</span>
            </div>
          </q-field>
        </div>
        <div class="card-back q-pa-md" @touchstart="flipCard(false)">
          <div class="text-left">
            <h6 class="q-my-none text-weight-bolder">{{props.cardData.title}}</h6>
            <p class="card-description">{{props.cardData.description}}</p>
          </div>
        </div>
      </q-card>
    </q-responsive>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";
import { colors } from "quasar";
import _ from "lodash";
import {ideation} from '../contents/profilation.js'
import {useStore} from "vuex";

import { useI18n } from 'vue-i18n'
const { tm } = useI18n();
const scenarioCards = tm('scenarioCards');
const dataCards = tm('dataCards')

const emit = defineEmits(["completed"]);
const store = useStore();
const props = defineProps(["cardData","isRating","color","index"]);

const invasiveness = ref(0);
const benefit = ref(0);
const flipped = ref(false);

const scenario = computed(() => scenarioCards.find((scenario)=> scenario.index === props.cardData.scenario));
const descriptionShort = computed(() => _.truncate(props.cardData.description,{length:150}));
const projectCards = computed(()=>{
  const cardData = props.cardData
  const finalCarsProject = typeof cardData.finalCards == 'string' ? cardData.finalCards.split(",") : cardData.finalCards
  return cardData && finalCarsProject ? finalCarsProject.map((card)=>{
    return dataCards.find((c)=> c.index === parseInt(card))
  }) : []
})

const colorFromScriptSetup = props.color || colors.getPaletteColor("primary");

watch(invasiveness, (val, prevVal) => {
  if(val>0 && benefit.value > 0){
    emit('completed',{index:props.index,id:props.cardData.id,ratings:{invasiveness:invasiveness.value,benefit:benefit.value}})
  }
})
watch(benefit, (val, prevVal) => {
  if(val>0 && invasiveness.value > 0){
    emit('completed',{index:props.index,id:props.cardData.id,ratings:{invasiveness:invasiveness.value,benefit:benefit.value}})
  }
})

const flipCard = (bool) => {
  flipped.value = bool;
}

</script>

<style lang="scss" >
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
.card-project-wrapper:not(.user-project){
  .project-title,.project-caption{
    .w{
      position: relative;
      .t,.translating{
        transition: opacity .5s;
      }
      .translating{
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $primary;
      }
      .t{
        opacity: 1;
      }
    }
  }
  @keyframes fading {
  0% {
    opacity: .1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .1;
  }
}
  &.translating{
    .project-title,.project-caption{
      .w{
        .t{
          opacity:0;
        }
        .translating{
          opacity: 1;
          animation: fading 1.5s infinite;
        }
      }
    }
  }
}
.card-project{
  position: relative;
  width: 290px;

  .investments-container{
    width: 100%;
    border: solid 1px rgb(176, 176, 176);
    height: 110px;
    margin-top: 10px;
    border-radius: 8px;
    padding: 8px;
  }

  .q-field{
    &:first-of-type{
      margin-top: 10px;
    }
  }
  .q-field__control-container{
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 20px !important;
    .q-field__label{
      text-align: center;
      top: 6px;
      left: 50%;
      transform: translate(-50%,-50%) scale(0.75);
      transform-origin: center;
    }
    .labels{
      display: flex;
      gap:75px;
      text-transform: uppercase;
      font-size:.65em;
      opacity: .7;
      span{
        &:last-of-type{
          text-align: right;
        }
      }
    }
  }
  .card-progress{
    position: absolute;
    right: 7px;
    top: 7px;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    transform: rotate(-90deg) scale(.7);
    backface-visibility: hidden;
    &:after{
      content: "";
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%) scale(0);
      border-radius: 50%;
      transition: transform 1.4s $ease-out-expo .4s;
      width: 100%;
      height: 100%;
      background: $primary;
    }
    circle{
      fill:none;
      stroke-width: 2;
    }
    .progress-circle__path{
      stroke:rgb(226, 222, 222);
    }
    .progress-circle__progress{
      stroke-dasharray:0 100;
      stroke: $primary;
      transition: stroke-dasharray 1s $ease-out-expo;
    }
    &.--step-1{
      .progress-circle__progress{
        stroke-dasharray:21 100;
      }
    }
    &.--step-2{
      &:after{
        transform: translate(-50%,-50%) scale(1);
      }
      .progress-circle__progress{
        stroke-dasharray:44 100;
      }
    }
  }
  h6{
    line-height: 1.3;
  }
  .text-subtitle1{
    line-height: 1.5;
  }
  .link{
    margin-bottom: 5px;
    font-size: .9em;
    cursor: pointer;
    text-decoration: underline;
  }
  .card-description{
    font-size: 0.9em;
    justify-content: flex-end;
    text-align: center;
    padding-bottom: 0;
    .chip-container{
      padding-top: 4px;
      display: grid;
      gap:5px;
      grid-template-columns: repeat(2,1fr);
      font-size: .9em;
    }
    .chip{
      display: flex;
    align-items: center;
    justify-content: center;
      padding: 2px;
      border-radius: 4px;
      padding: 3px 4px;
      text-decoration: none !important;
      // white-space: nowrap;
      flex-shrink: 0;
      display: inline-block;
      font-weight: 500;
      background: white;
      border: solid 1px #d9d9d9;
      &.chip-scenario{
        margin-bottom: 5px;
        background: #e6293d !important;
        color:white !important;
      }
    }
  }
  .card-back{
    .text-left{
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  &.isRating{
    .card-front{
      .card-description{
        padding-bottom: 10px;
        margin-bottom:0;
        border-bottom: solid 1px rgba(0,0,0,0.1);
      }
    }
  }
  .card-front{
    .card-description{
      p{
        margin-bottom:0;
      }
      span{
        font-size:.9em;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
