import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "Persönliche Profildaten", "name_short": "Profildaten", "description": "Geschlecht, Ethnizität, Glaube, sexuelle Orientierung, politische Überzeugung, Musik, Film-, Fernseh- u. Streamingpräferenzen, Mobiltelefonnutzung, typischer Tagesablauf." },
      { "index": 2, "id":"2_health", "name": "Gesundheitsdaten", "description": "Physische und mentale Kondition, genetisch- u. biometrische Daten, Gewicht, BMI, Stress, Zugang zu Gesundheitsinfrastruktur." },
      { "index": 3, "id":"3_dietary", "name": "Ernährung und Diät", "description": "Essen und Trinken, Allergien und Intoleranzen, Rauchen, Drogen, Nährwertdaten, Präferenzen bzgl." },
      { "index": 4, "id":"4_transactions", "name": "Geld Transaktionsdaten", "name_short": "Transaktionsdaten", "description": "Kreditkarten-Nutzungsdaten, Internet Banking, Offline- und Online-Käufe, Konsumenten." },
      { "index": 5, "id":"5_socialnetworks", "name": "Soziale Netzwerk Daten", "name_short": "Soziale Netzwerk", "description": "Persönliche Nutzung von sozialen Netzwerken, Professionelle Kommunikation, E-Mail, Bewerbungen, Physische Meetings, Dating Apps, Video/Telekonferenz-Apps." },
      { "index": 6, "id":"6_human", "name": "Mobilitätsdaten: Menschen", "name_short": "Menschen", "description": "Individueller Transport, private u. öffentliche Verkehrsmittel, Lokalisierungsdaten/GPS, Timestamps, Anzahl Leute." },
      { "index": 7, "id":"7_animal", "name": "Mobilitätsdaten: Tiere", "name_short": "Tiere", "description": "Haustiere- und Nutztiere-Transport, Lokalisierung/GPS, Timestamps, Anzahl Tiere." },
      { "index": 8, "id":"8_vehicle", "name": "Mobilitätsdaten: Transportmittel", "name_short": "Transportmittel", "description": "Typ (Auto, Fahrrad, Scooter, Bus, Flugzeug), Lokalisierung/GPS, Timestamp, Anzahl und besondere Merkmale/Features." },
      { "index": 9, "id":"9_utility", "name": "Versorgungsdaten", "description": "Wasser, Elektrizität, Erdgas, Heiz- u. Kühlgewohnheiten, Abfall- und Recycling- Gewohnheiten, Telefon, Wifi." },
      { "index": 10, "id":"10_environmental", "name": "Umgebungsdaten", "description": "Innen- und Außenluftqualität, Temperatur, Chemie, Feuer, Licht, Lärm." },
      { "index": 11, "id":"11_infrastructure", "name": "Öffentliche Infrastruktur Daten", "name_short": "Infrastruktur", "description": "Brücken, Straßenbedingungen, Möbel im öffentlichen Raum, Verkehrsampeln, Wartung." },
      { "index": 12, "id":"12_greenery","name": "Das Grün", "description": "Trees, parks, gardens, agricultural areas, green canopy and plant diversity." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Zuhause", "description": "Aspekte des Alltags zuhause, die zu den generellen Lebensbedingungen beitragen: Außen- und Innenräume, Privathäuser, aber auch Wohngemeinschaften, wie Pflegeheime, Gruppenheime wie Waisenhäuser, Altersheime, Gefängnisse, Behandlungseinrichtungen und Pflegeheime." },
      { "index": 2, "color":"#295966", "name": "Arbeitsumfeld", "description": "Soziale und physische Aspekte, die das Wohlbefinden, die Beziehungen am Arbeitsplatz, die Zusammenarbeit, die Effizienz und die Gesundheit der Mitarbeiter beeinflussen. Ist es ein Heimbüro, ein Bürogebäude, eine Fabrik, innen oder außen." },
      { "index": 3, "color":"#8862A6", "name": "Öffentlicher Raum", "description": "Ein Ort der generell für alle offen und zugänglich ist: Strassen, Marktplätze, Parks, Strand, Fahrradwege, Garagen, Regierungsgebäude, öffentliche Bibliotheken, Lifte, Restaurant, Gemeindezentren, Sportanlagen, Party/medienräume, Theater." }
  ],
  discover:{
    blocks:[
      {
        title:'Hintergrund',
        text:"Überall werden Daten gesammelt. Datengetriebene Projekte und Lösungen verändern unsere Nutzung öffentlicher Räume, Lebens- und Arbeitsplätze."
      },
      {
        title:"Mission",
        text:"Data Slots ist ein Spiel mit einer besonderen Mission: Datengesteuerte Lösungen vorzuschlagen, die Datenschutzbedenken und Nutzen in Einklang bringen."
      },
      {
        title:"Das Spiel",
        subtitle:"Szenariokarten",
        id:"scenario",
        text:"Wähle eine beliebige Szenario-Karte und schlage eine Idee vor."
      },
      {
        subtitle:"Datenkarten",
        id:"data-cards",
        text:"Du erhältst drei zufällige Karten aus dem Datendeck. Eine Karte musst du für das gesamte Spiel behalten. Die anderen Karten kannst du nun mit anderen tauschen, um deine Anlagen so aufzubauen, wie es dir gefällt."
      },
      {
        title:"Ideenfindung",
        id:"ideation",
        text:"Nutze mindestens drei Daten-Karten, um deine Lösung zu beschreiben."
      },
      {
        title:"Bewertung",
        id:"assessments",
        text:"Nachdem du deine Idee eingereicht hast, wird dir Data Slots die Ideen drei anderer Spieler zeigen. Bewerte die Ideen anhand ihrer Nutzen und Datenschutzbedenken."
      },
      {
        title:"Bewertung",
        id:"investment",
        text:"Verteile jetzt deine Chips, um in die Ideen zu investieren."
      },
      {
        title:"",
        id:"scoreboard",
        text:"Vergesse nicht die Bewertungstafel! Erfinde einen Nicknamen und behalte dein Ranking im Auge."
      }
    ],
    contact:"<strong>Kontakt:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Wichtig:</strong> Data Slots wird dich keine persönlichen Daten fragen. Die Daten, die aus Data Slots generiert werden, sind anonym und werden ausschließlich für akademische Forschungszwecke verwendet.",
    credits:"<strong>Team:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Webseite:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Annerkennung:</strong> Yuki Machida, Oluwatobi Oyinlola, und SCL Mitgliedern, wie auch Beteiligten aus der ganzen Welt, die Data Slots live gespielt haben und uns geholfen haben, das Spiel zu verbessern. Wir danken dem SCL Konsortium."
  },
  gameHints:{
    yourCards:"Deine Karten",
    cardsDeck:"Kartendeck",
    introText:'Ein 10-Minütiges Spiel über die Ethik und Nutzen Datengetriebener Lösungen',
    introPlay:'Spielen',
    introDiscover:'Entdecken',
    introScoreboard:'Scoretafel',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'Wähle ein Szenario', messageMobile:'Klicken Sie, um ein zufälliges Szenario auszuwählen', hint: 'Deine Mission ist es, eine datengetriebene Idee zu einem der folgenden Szenarien zu umschreiben: öffentlicher Raum, Wohn und Arbeitsumgebung.', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: 'Du hast drei Karten. Wähle eine aus und behalte sie für das gesamte Spiel', hint: 'Tipp einfach auf eine Karte', icon: '' },
      level1: { message: 'Wähle nun die Karte, die du aus dem Deck entfernen möchtest', hint: 'Tipp auf die Karte', icon: '' },
      level2: { message: 'Wähle nun die Karte, die du für dein Deck möchtest', hint: 'Tipp auf die Karte', icon: '' },
      level3: { message: 'Wähle nun die Karte, die du aus dem Deck entfernen möchtest', hint: 'Tipp auf die Karte', icon: '' },
      level4: { message: 'Wähle nun die Karte, die du für dein Deck möchtest', hint: 'Tipp auf die Karte' , icon: '' },
      level5: { message: 'Wähle nun die letzte Karte', hint: 'Tipp auf die Karte', icon: '' },
      level6: { message: 'Das sind deine endgültigen Karten', hint: 'Tipp auf Weiter um deine Idee vorzuschlagen' }
    },
    slot: {
        level0: { message: 'Du hast drei Karten. Wähle eine aus und behalte sie für das gesamte Spiel', hint: 'Einfach drauf klicken', icon: 'highlight_alt' },
        level1: { message: 'Tausche nun eine der Karten aus', hint: 'Ziehe eine Karte zu dem oberen Deck', icon: 'swipe_up' },
        level2: { message: 'Tausche nun eine weitere Karte', hint: 'Ziehe eine Karte zu dem oberen Deck', icon: 'swipe_up' },
        level3: { message: 'Wählen Sie jetzt Ihre letzte Karte', hint: 'Ziehen Sie eine Karte aus dem obersten Deck', icon: 'swipe_down' },
        level4: { message: 'Das sind deine endgültigen Karten', hint: 'Klicke nun auf Weiter, um deine Idee vorzuschlagen', }
    },
    sideButtons: {
        scanario: { label: 'scanario' },
        cards: { label: 'Meine Karten' }
    },
    finalMessage: {
      playAgain:'Nochmal spielen',
      scoreboard:'Scoretafel',
      name: {
        hint: 'Dein Name',
        error: 'Bitte etwas eingeben',
        confirm:'Aktualisieren'
      },
      message: 'Danke, dass du Data Slots gespielt hast',
      hint:"Das ist dein Nickname, um dem Leaderboard hinzugefügt zu werden. Komm bald zurück, um mit deinem Nicknamen das Ranking deiner Idee zu checken"
    }
  },
  profilation:{
      title: 'Erzähl was von dir',
      hint: '',

      name: {
          hint: 'Dein Name',
          error: 'Bitte etwas eingeben'
      },
      role: {
          hint: 'Deine Rolle',
          options: [
              'Akademisch',
              'Bürger',
              'Stadtbeamter'
          ],
          error: 'Bitte wähle etwas aus'

      },
      region: { hint: 'Wo spielst du?', options: regions,error: 'Bitte wähle etwas aus' },
      age: {
          hint: 'Dein Alter',
          options: [
              'unter 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'über 65'
          ],
          error: 'Bitte wähle etwas aus'
      },
      gender: {
          hint: 'Deine Pronomen',
          options: [
              'Sie/Ihres',
              'Er/Seine',
              'Es/Sein',
              'Ich schreibe es selber',
              'Ich behalte es für mich'
          ],
          error: 'Bitte wähle etwas aus'
      },
      customGender: {
          hint: 'Deine Pronomen',
          error: 'Bitte etwas eingeben'

      },
      confirm: 'Weiter'
  },
  ideation:{
      title: 'Ideenfindung',
      hint: 'Welche Idee/welches Erlebnis würdest du für dein Szenario vorschlagen (kombiniere mindestens drei deiner Karte)? <br>Du hast 5 Minuten.',
      projectTitle: {
          hint: 'Projekttitel',
          error: 'Bitte etwas eingeben'
      },
      name: {
          hint: 'Dein Name',
          error: 'Bitte etwas eingeben'
      },
      projectCaption: {
          hint: 'Projekt Anspruch',
          error: 'Bitte etwas eingeben'
      },
      projectDescription: {
          hint: 'Kurze Beschreibung des Projekts',
          error: 'Bitte etwas eingeben'
      },
      projectRatings:{
        translation:"* Einige der Projekte werden automatisch aus ihrer Originalsprache übersetzt",
        ratings:{
          hint:'Bewerte die Ideen ',
          subtitle:'Invasivität &#8594; Verwendung der Daten: Datenschutzbenden jeder Idee <br> Vorteile &#8594; Vorteile/Nutzen jeder Idee'
        },
        investments:{
          hint:'Jetzt bist du der Investor',
          subtitle:'Du hast zehn Münzen. Platziere die Münzen, um in Ideen zu investieren',
          icon:'swipe_up'
        }
      },
      read_more:'Lies mehr',
      confirm: 'Fertig',
      invasiveness:'Invasivität',
      benefit:'Nutzen',
      high:'Hoch',
      low:'Niedrig'
  },
  scoreboard:{
    search:'Suche',
    name:'Nickname',
    position:'Rang',
    title:'Titel',
    country: 'Land',
    score: 'Punktzahl',
    ratings:'Bewertung'
  }
}