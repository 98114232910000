<template>
  <div class="slot-level">
      <div class="text-h5 text-primary" key="gameMessage">
        <!-- <TransitionGroup name="fade" mode="out-in" tag="div" class="md-container">
          <GameHint
            class="game-hint"
            :key="internalLevel"
            :message="
              $t(`gameHints.slot.level${internalLevel}.message`)
            "
            :hint="
              $t(`gameHints.slot.level${internalLevel}.hint`)
            "
            :icon="
              $t(`gameHints.slot.level${internalLevel}.icon`) && $t(`gameHints.slot.level${internalLevel}.icon`) !== 'gameHints.slot.level4.icon' ? $t(`gameHints.slot.level${internalLevel}.icon`) : ''
            "
          />
        </TransitionGroup> -->
        <div class="games-hint-wrap">
          <Transition name="fade-position" mode="" class="xs-container">
          <GameHint
            class="game-hint"
            :key="mobileInternalLevel"
            :message="
              $t(`gameHints.slotMobile.level${mobileInternalLevel}.message`)
            "
            :hint="
              $t(`gameHints.slotMobile.level${mobileInternalLevel}.hint`)
            "
            :icon="
              $t(`gameHints.slotMobile.level${mobileInternalLevel}.icon`) && $t(`gameHints.slotMobile.level${mobileInternalLevel}.icon`) !== 'gameHints.slotMobile.level6.icon' ? $t(`gameHints.slotMobile.level${mobileInternalLevel}.icon`) : ''
            "
          />
        </Transition>
        </div>
      </div>
      <!-- <div class="md-container">
        <CardsSpace
          v-if="pulledCards && internalLevel < 4 && cardsReference"
          :key="`pulledCardsLevel-${internalLevel}`"
          v-model="pulledCards"
          :swap="swapMode"
          name="a1"
          :disabled="disableCards"
          :lockedOverride="lockPulledCards"
          :topLabel="$t('gameHints.cardsDeck')"
        />

        <CardsSpace
          v-if="cardsReference"
          key="currentCards"
          v-model="currentCards"
          :swap="swapMode"
          name="a2"
          @cardClicked="clickBehavior"
          @cardRemoved="handleCardEvent"
          @cardAdded="handleCardEvent"
          :disabled="disableCards"
          :lockedOverride="lockCurrentCards"
          :topLabel="$t('gameHints.yourCards')"
        />
        <q-btn
          v-if="internalLevel === 4"
          unelevated
          rounded
          color="primary"
          @click="emit('completed')"
          >{{$t('profilation.confirm')}}</q-btn
        >
      </div> -->
    <div class="xs-container container q-mt-lg">
      <Transition name="fade-position-cards" mode="">
        <template v-if="(currentCards && !pulledCards && internalLevel === 0)">
          <div class="card-gallery your-deck" key="0" @touchstart="dragStart">
              <Card
                v-for="card in currentCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="true"
                :cardData="cardsReference.find((c) => c.index === card)"
                class="card-element q-mx-sm"
                @touchend="cardTouchEnd($event,cardsReference.find((c) => c.index === card))"
                @click="clickCardTouchEnd($event,cardsReference.find((c) => c.index === card))"
              />
            </div>
        </template>
        <template v-else-if="(currentCards && pulledCards && internalLevel === 1 && mobileSwitchStep === 0)">
          <div class="card-gallery your-deck" key="1" @touchstart="dragStart">
              <Card
                v-for="card in currentCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="false"
                :removable="true"
                :cardData="cardsReference.find((c) => c.index === card)"
                :data-locked="cardsReference.find((c) => c.index === card).locked"
                class="card-element q-mx-sm"
                @touchend="cardTouchEndRemove($event,card)"
                @click="clickCardTouchEndRemove($event,card)"
              />
            </div>
        </template>
        <template v-else-if="(currentCards && pulledCards && internalLevel === 1 && mobileSwitchStep === 1)">
          <div class="card-gallery" key="2" @touchstart="dragStart">
              <Card
                v-for="card in pulledCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="false"
                :addInDeck="true"
                :cardData="cardsReference.find((c) => c.index === card)"
                class="card-element q-mx-sm"
                @touchend="cardTouchEndAdd($event,card)"
                @click="clickCardTouchEndAdd($event,card)"
              />
            </div>
        </template>
        <template v-else-if="(currentCards && pulledCards && mobileInternalLevel === 3)">
          <div class="card-gallery your-deck" key="3" @touchstart="dragStart">
              <Card
                v-for="card in currentCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="false"
                :removable="true"
                :cardData="cardsReference.find((c) => c.index === card)"
                :data-locked="cardsReference.find((c) => c.index === card).locked"
                class="card-element q-mx-sm"
                @touchend="cardTouchEndRemove($event,card)"
                @click="clickCardTouchEndRemove($event,card)"
              />
            </div>
        </template>
        <template v-else-if="(currentCards && pulledCards && mobileInternalLevel === 4)">
          <div class="card-gallery" key="4" @touchstart="dragStart">
              <Card
                v-for="card in pulledCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="false"
                :addInDeck="true"
                :cardData="cardsReference.find((c) => c.index === card)"
                class="card-element q-mx-sm"
                @touchend="cardTouchEndAdd($event,card)"
                @click="clickCardTouchEndAdd($event,card)"
              />
            </div>
        </template>
        <template v-else-if="(currentCards && pulledCards && mobileInternalLevel === 5)">
          <div class="card-gallery" key="5"  @touchstart="dragStart">
              <Card
                v-for="card in pulledCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="false"
                :addInDeck="true"
                :cardData="cardsReference.find((c) => c.index === card)"
                class="card-element q-mx-sm"
                @touchend="cardTouchEndAdd($event,card)"
                @click="clickCardTouchEndAdd($event,card)"
              />
            </div>
        </template>
        <template v-else-if="(currentCards && pulledCards && mobileInternalLevel === 6)">
          <div class="card-gallery your-deck" key="6">
              <Card
                v-for="card in currentCards"
                :key="`card-${card}`"
                :uid="card"
                :lockeable="false"
                :cardData="cardsReference.find((c) => c.index === card)"
                class="card-element q-mx-sm"
              />
            </div>
        </template>
        <!-- <template
            v-if="pulledCards && internalLevel < 4 && cardsReference"
          >
            <div class="card-gallery" :key="`pulledCardsLevel-${internalLevel}`">
              <Card
                v-for="card in pulledCards"
                :key="`card-${card}`"
                :uid="card"
                :cardData="cardsReference.find((c) => c.index === card)"
                class="card-element q-mx-sm"
                @clicked="lockMobileCard(card)"
              />
            </div>
          </template> -->
      </Transition>

      <q-btn
        v-if="(mobileInternalLevel === 6)"
        unelevated
        rounded
        color="primary"
        class="q-mt-md"
        @click="emit('completed')"
        >{{$t('profilation.confirm')}}</q-btn
      >

    </div>
  </div>
</template>


<script setup>
import Emitter from '../utils/Emitter'
import { computed, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import CardsSpace from "../components/CardsSpace.vue";
import Card from "../components/Card.vue";
import { gameHints as contents } from "../contents/gameHints.js";

import GameHint from "../components/GameHint.vue";
import _ from "lodash";
import gsap from "gsap";

import { useI18n } from 'vue-i18n'
const { tm } = useI18n();

const dataCards = tm('dataCards')

const store = useStore();

const swapMode = ref(false);
const internalLevel = ref(0);
const lockPulledCards = ref(true);
const lockCurrentCards = ref(false);
const disableCards = ref(false);
const mobileSwitchStep = ref(0);
const mobileRemoveCardIndex = ref(null)
const mobileInternalLevel = ref(0)
const dragging = ref(false)
const dragpos = ref({})
const hasDrag = ref(false)

const isTouchDevice = window.matchMedia('(hover: none)').matches;

const emit = defineEmits(["completed"]);

const cardsReference = computed({
  get() {
    return store.state.cardsReference;
  },
  set(value) {
    store.commit("setCardsDesk", value);
  },
});

const lockMobileCard = (card)=>{
  // console.log(card);
}

const dragStart = (event)=>{
  dragging.value = true
  hasDrag.value = false
  const touch = event.changedTouches[0]
  dragpos.value = {
    x: touch.clientX,
    y: touch.clientY
  }
}

const addCard = (card)=>{
  if(mobileInternalLevel.value == 2){
    const indexToRemove = currentCards.value.indexOf(mobileRemoveCardIndex.value);
    const indexToAdd = pulledCards.value.indexOf(card.index);
    if(indexToRemove >= 0){
      currentCards.value.splice(indexToRemove,1,card.index)
      pulledCards.value.splice(indexToAdd,1,mobileRemoveCardIndex.value)
    }
    internalLevel.value = 2
    mobileInternalLevel.value = 3
  } else if (mobileInternalLevel.value == 4){
    const indexToRemove = currentCards.value.indexOf(mobileRemoveCardIndex.value);
    const indexToAdd = pulledCards.value.indexOf(card.index);
    if(indexToRemove >= 0){
      currentCards.value.splice(indexToRemove,1,card.index)
      pulledCards.value.splice(indexToAdd,1,mobileRemoveCardIndex.value)
    }
    internalLevel.value = 3
    mobileInternalLevel.value = 5
  }else if (mobileInternalLevel.value == 5){
    currentCards.value.push(card.index);
    internalLevel.value = 4
    mobileInternalLevel.value = 6
  }
}

function removeCard(e){
  if (internalLevel.value === 1 && mobileSwitchStep.value ===0){
    mobileRemoveCardIndex.value = e.index
    mobileSwitchStep.value += 1
    mobileInternalLevel.value = 2
  } else if(mobileInternalLevel.value == 3){
    mobileRemoveCardIndex.value = e.index
    mobileInternalLevel.value = 4
  }
}

function clickCardTouchEndAdd(event,index){
  if(!isTouchDevice){
    addCard({
      index
    })
    return
  }
}
function cardTouchEndAdd(event,index){
  dragging.value = false
  const touch = event.changedTouches[0]
  if(Math.floor(dragpos.value.x) !== Math.floor(touch.clientX) || Math.floor(dragpos.value.y) !== Math.floor(touch.clientY)){
    hasDrag.value = true
  }else{
    hasDrag.value = false
    addCard({
      index
    })
  }
}
function clickCardTouchEndRemove(event,index){
  if(!isTouchDevice){
    removeCard({
      index
    })
    return;
  }
}

function cardTouchEndRemove(event,index){
  dragging.value = false
  const touch = event.changedTouches[0]
  if(Math.floor(dragpos.value.x) !== Math.floor(touch.clientX) || Math.floor(dragpos.value.y) !== Math.floor(touch.clientY)){
    hasDrag.value = true
  }else{
    hasDrag.value = false
    removeCard({
      index
    })
  }
}

function clickCardTouchEnd(event,card){
  if(!isTouchDevice){
    clickBehavior({
      properties: card
    })
    return
  }
}
function cardTouchEnd(event, card){
  dragging.value = false
  const touch = event.changedTouches[0]
  if(Math.floor(dragpos.value.x) !== Math.floor(touch.clientX) || Math.floor(dragpos.value.y) !== Math.floor(touch.clientY)){
    hasDrag.value = true
  }else{
    hasDrag.value = false
    clickBehavior({
      properties: card
    })
  }
}

function clickBehavior(e) {
  if (internalLevel.value === 0) {
    e.properties.locked = !e.properties.locked;

    internalLevel.value = 1;
    mobileInternalLevel.value = 1

    setTimeout(() => {
      store.commit("setPulledCards", cardsDeck.value.splice(0, 3));
      store.commit("addToGameLog", {
        level: 1,
        currentCards: currentCards.value,
        pulledCards: pulledCards.value,
        lockedCard: e.index,
      });
      swapMode.value = true;
    }, 1000);
  }
}

function handleCardEvent() {
  if (internalLevel.value === 1) {
    disableCards.value = true;
    setTimeout(() => (internalLevel.value = 2), 1000);
  }
  if (internalLevel.value === 2) {
    disableCards.value = true;
    setTimeout(() => (internalLevel.value = 3), 1000);
  }
  if (internalLevel.value === 3) {
    disableCards.value = true;
    setTimeout(() => (internalLevel.value = 4), 1000);
  }
}

watch(internalLevel, (newVal, oldVal) => {
  Emitter.emit('internalLevel',newVal)
  if (newVal === 1) {
    disableCards.value = false;
    swapMode.value = true;
    lockPulledCards.value = true;
    lockCurrentCards.value = false;
  }
  if (newVal === 2) {
    disableCards.value = false;
    swapMode.value = true;
    lockPulledCards.value = true;
    lockCurrentCards.value = false;
    store.commit("setPulledCards", cardsDeck.value.splice(0, 3));
    store.commit("addToGameLog", {
      level: 2,
      currentCards: currentCards.value,
      pulledCards: pulledCards.value,
    });
  }
  if (newVal === 3) {
    disableCards.value = false;
    swapMode.value = false;
    lockPulledCards.value = false;
    lockCurrentCards.value = true;
    store.commit("setPulledCards", cardsDeck.value.splice(0, 3));

    store.commit("addToGameLog", {
      level: 3,
      currentCards: currentCards.value,
      pulledCards: pulledCards.value,
    });
  }
  if (newVal === 4) {
    store.commit("addToGameLog", {
      level: 4,
      currentCards: currentCards.value,
      pulledCards: pulledCards.value,
    });
  }
});

const cardsDeck = computed({
  get() {
    return store.state.cardsDeck;
  },
  set(value) {
    store.commit("setCardsDesk", value);
  },
});
const pulledCards = computed({
  get() {
    return store.state.pulledCards;
  },
  set(value) {
    store.commit("setPulledCards", value);
  },
});
const currentCards = computed({
  get() {
    return store.state.currentCards;
  },
  set(value) {
    store.commit("setCurrentCards", value);
  },
});
watch (currentCards, (newVal,oldVal)=>{
  const otherCards = document.querySelectorAll('.a1 .card-element')
  const yourCards = document.querySelectorAll('.a2 .card-element')
  yourCards.forEach((card, i) => {
    card.classList.add('your-deck')
  });
  otherCards.forEach((card, i) => {
    card.classList.remove('your-deck')
  });
})
dataCards.forEach((c) => (c.properties = { flipped: false, locked: false }));

store.commit("setCardsReference", _.shuffle(dataCards));
store.commit(
  "setCardsDeck",
  _.shuffle(dataCards).map((c) => c.index)
);
store.commit("setCurrentCards", cardsDeck.value.splice(0, 3));
store.commit("addToGameLog", { level: 0, currentCards: currentCards.value });

// store.commit("setPulledCards", cardsDeck.value.splice(0, 3));
</script>


<style lang="scss">
@import "../style/shared.scss";

.container {
  position: relative;
  padding: 0;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  position: absolute;
}
.games-hint{
  @include max-responsive(sm){
    .text-h4{
      font-size: 22px;
      line-height: 1.2;
    }
    .text-h6{
      font-size: 14px;
      line-height: 1.2;
      margin-top: 10px;
    }
    .q-icon{
      font-size: 2rem !important;
    }
  };
}
.game-hint {
  width: 100%;
}

.games-hint-wrap{
  position: relative;
}

.xs-container{
  @include responsive(sm){
    max-width: 100%;
  }
  .card-element{
    &[data-locked]{
      display: none;
      @include responsive(sm){
        display: block;
        pointer-events: none;
      }
    }
  }
  .card-gallery{
    margin-left: calc(var(--page-gutter) * -1);
    margin-right: calc(var(--page-gutter) * -1);
    width: calc(100% + (var(--page-gutter) * 2));
    padding-left: var(--page-gutter);
    padding-right: var(--page-gutter);
    display: flex;
    overflow: scroll;
    scroll-snap-type: x mandatory;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @include responsive(sm){
      width: auto;
      justify-content: center;
      margin: 0 auto;
      overflow: visible;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .card-structure{
      box-shadow:none !important;
    }
    .card-element{
      scroll-snap-align: center;
      flex-shrink: 0;
    }
  }
}

.slot-level {
  .xs-container{
    display: block !important;
  }
}
</style>
