<template>
<div class="row flex-center">


  <FormElement :title="$t('profilation.title')" :hint="$t('profilation.hint')">
    <FormProfilation @completed="handleConfirmation" :language="store.state.language" />
  </FormElement>
  </div>
</template>


<script setup>
import FormElement from "../components/FormElement.vue";
import FormProfilation from "../components/FormProfilation.vue";
import {profilation as contents} from "../contents/profilation"

import { useStore } from "vuex";
import { ref, watch } from "vue";
const store = useStore();
const emit = defineEmits(["completed"]);

function handleConfirmation(e) {
  store.commit("setUserProfile", e);
  emit("completed");
}
</script>
