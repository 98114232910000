
<template>

        <router-view :class="`locale-${locale}`">

        </router-view>

</template>

<script setup>

import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'

const { locale } = useI18n();

watch(locale,(newLocale, oldLocale)=>{
  const bodyElement = document.querySelector('body')
  bodyElement.classList.remove(`locale-${oldLocale}`);
  bodyElement.classList.add(`locale-${newLocale}`);
})
</script>


<style lang="scss">
#app {
  font-family: Asap, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
