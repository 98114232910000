import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" ,  "name": "Dati sul profilo personale", "name_short": "Profilo personale", "description": "Sesso, etnia, credenze religiose, orientamento sessuale, opinioni politiche, musica, preferenze su TV/film o streaming, abitudini di utilizzo dei dispositivi mobili, orari abituali." },
      { "index": 2, "id":"2_health", "name": "Dati sulla salute", "name_short": "Salute", "description": "Condizione fisica e mentale, informazioni genetiche o biometriche, peso, BMI, stress, accesso alle infrastrutture sanitarie." },
      { "index": 3, "id":"3_dietary", "name": "Dati sulle abitudini alimentari", "name_short": "Abitudini alimentari", "description": "Scelte di cibi o bevande, allergie e intolleranze, abitudine al fumo, consumo di droghe, informazioni nutrizionali, preferenze in termini di bar, ristoranti, altri servizi." },
      { "index": 4, "id":"4_transactions", "name": "Dati sulle transazioni elettroniche", "name_short": "Trans elettroniche", "description": "Utilizzo della carta di credito, internet banking, acquisti offline e online, informazioni sul consumo dei prodotti." },
      { "index": 5, "id":"5_socialnetworks", "name": "Dati sulle reti sociali", "name_short": "Reti sociali", "description": "Uso personale di social network, comunicazione di lavoro, e-mail, applicazioni, incontri di persona, app di incontri, piattaforme per videochiamate." },
      { "index": 6, "id":"6_human", "name": "Dati sulla mobilità umana", "name_short": "Mobilità umana", "description": "Mobilità individuale, utilizzo di mezzi pubblici o privati, con informazioni su geolocalizzazione, orario, conteggio delle persone." },
      { "index": 7, "id":"7_animal", "name": "Dati sulla mobilità di animali", "name_short": "Mobilità animale", "description": "Mobilità di animali domestici o bestiame, con informazioni su geolocalizzazione, orario, conteggio animali." },
      { "index": 8, "id":"8_vehicle", "name": "Dati sulla mobilità di veicoli", "name_short": "Mobilità di veicoli", "description": "Mobilità dei veicoli (auto, bici, scooter, autobus, aerei), con informazioni su geolocalizzazione, orario, conteggio e caratteristiche dei veicoli." },
      { "index": 9, "id":"9_utility", "name": "Dati sul consumo energetico", "name_short": "Consumo energetico", "description": "Consumo di acqua, luce e gas, abitudini su riscaldamento e raffrescamento, raccolta rifiuti, telefono fisso, wifi." },
      { "index": 10, "id":"10_environmental", "name": "Dati ambientali", "name_short": "Ambientali", "description": "Qualità dell'aria interna ed esterna, temperatura, prodotti chimici, fuoco, luce, inquinamento acustico." },
      { "index": 11, "id":"11_infrastructure", "name": "Dati sulle Infrastrutture", "name_short": "Infrastrutture", "description": "Ponti, viabilità, arredo urbano, semafori, manutenzione spazi pubblici." },
      { "index": 12, "id":"12_greenery","name": "Dati sulla vegetazione", "name": "Vegetazione", "description": "Alberi, parchi, giardini, aree agricole, chioma verde e diversità vegetale." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Ambiente domestico", "description": "Aspetti della vita domestica delle persone che contribuiscono alle loro condizioni di vita. Sono inclusi spazi esterni e interni, case private ma anche istituzioni comunitarie residenziali, come case di cura, orfanotrofi, case di riposo, carceri." },
      { "index": 2, "color":"#295966", "name": "Ambiente lavorativo", "description": "Elementi che comprendono l'ambiente e gli aspetti che riguardano la sfera di vita lavorativa con un impatto sui lavoratori, sia sociale che fisico. Questi aspetti possono influire sui sentimenti di benessere e salute, sulle relazioni lavorative, sulla collaborazione, sulla mobilità , sullla produttività dei dipendenti. Sia come ufficio a casa, aziendale o una fabbrica." },
      { "index": 3, "color":"#8862A6", "name": "Spazio pubblico", "description": "Un luogo generalmente aperto e accessibile alle persone come strade, piazze, parchi, spiagge, piste ciclabili, garage, edifici governativi, biblioteche pubbliche, servizi come ascensori, negozi, ristoranti, centri sociali, impianti sportivi, sale per feste, teatro o sale multimediali." }
  ],
  discover:{
    blocks:[
      {
        title:'Contesto',
        text:"I dati vengono raccolti ovunque. Le soluzioni basate sui dati stanno cambiando il modo in cui utilizziamo gli spazi pubblici, i luoghi in cui viviamo e lavoriamo."
      },
      {
        title:"Missione",
        text:"Data Slots è un gioco con una missione unica: proporre soluzioni basate sui dati, bilanciando le preoccupazioni sulla privacy e i benefici generati."
      },
      {
        title:"Il gioco",
        subtitle:"Carte Scenario",
        id:"scenario",
        text:"Scegliete casualmente una carta scenario sulla quale delineare la vostra idea."
      },
      {
        subtitle:"Carte Dati",
        id:"data-cards",
        text:"Riceverete poi tre carte dati casuali. Bloccate una carta, che terrete per tutta la partita. Successivamente, scambiate le altre carte dati per costruire le vostre risorse dati."
      },
      {
        title:"Ideazione",
        id:"ideation",
        text:"Utilizzate le 4 carte dati per proporre una soluzione per lo scenario ottenuto."
      },
      {
        title:"Valutazione",
        id:"assessments",
        text:"Una volta generata la propria soluzione, Data Slots vi mostrerà altre tre idee create da altri giocatori.<br>Valutate le preoccupazioni privacy e i benefici di ogni idea"
      },
      {
        title:"Investimento",
        id:"investment",
        text:"Infine, investite le vostre fiches in queste idee."
      },
      {
        title:"",
        id:"Classifica",
        text:"Non perdetevi la classifica! Create il vostro nickname e tenete traccia di come la vostra idea e' collocata nel tabellone."
      }
    ],
    contact:"<strong>Contatti:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Liberatoria:</strong> Data Slots non richiede alcun dato personale. I dati generati da Data Slots sono anonimi e saranno utilizzati esclusivamente per la ricerca accademica.",
    credits:"<strong>Team:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Sito Internet:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Ringraziamenti:</strong> Yuki Machida, Oluwatobi Oyinlola, e i membri di SCL, nonché i partecipanti delle città di tutto il mondo che hanno giocato di persona a Data Slots e ci hanno aiutato a migliorare il gioco. Ringraziamo il consorzio SCL."
  },
  gameHints:{
    yourCards:"Le tue carte",
    cardsDeck:"Il tavolo da gioco",
    introText:'Un gioco di 10 minuti sull\'etica e il valore delle soluzioni basate sui dati',
    introPlay:'Gioca',
    introDiscover:'Scopri',
    introScoreboard:'Classifica',
    title: 'La slot machine',
    hint: '',
    cardSelect: { message: 'Seleziona lo scenario', hint: "La vostra missione è progettare un'idea basata sui dati per creare esperienze per uno di questi scenari: spazio pubblico, ambiente&nbsp;domestico e lavorativo.', icon: 'highlight_alt" },
    slotMobile:{
      level0: { message: 'Queste sono le tue tre carte. Bloccane una per usarla nella tua idea', hint: 'Scegli toccando la carta', icon: '' },
      level1: { message: 'Ora scegli la carta che vuoi rimuovere dal tavolo', hint: 'Tocca la carta', icon: '' },
      level2: { message: 'Ora scegli la carta che vuoi includere nel tuo tavolo', hint: 'Tocca la carta', icon: '' },
      level3: { message: "Ora scegli un'altra carta che vuoi rimuovere dal tavolo", hint: 'Tocca la carta', icon: '' },
      level4: { message: 'Ora scegli la carta che vuoi includere nel tuo tavolo', hint: 'Tocca la carta' , icon: '' },
      level5: { message: 'Aggiungi la tua ultima carta', hint: 'Tocca la carta', icon: '' },
      level6: { message: 'Queste sono le tue carte finali', hint: 'Seleziona Procedi per proporre la tua idea' }
    },
    slot: {
        level0: { message: "Queste sono le tue tre carte. Bloccane una per usarla nella tua idea", hint: 'Scegli cliccando', icon: 'highlight_alt' },
        level1: { message: 'Ora scambia una delle tue carte', hint: 'Trascina una carta dalla tua area al tavolo', icon: 'swipe_up' },
        level2: { message: 'Ora scambia ancora una carta', hint: 'Trascina una carta dalla tua area al tavolo', icon: 'swipe_up' },
        level3: { message: 'Scegli la tua ultima carta', hint: 'Trascina una carta dalla tua area al tavolo', icon: 'swipe_down' },
        level4: { message: 'Queste sono le tue carte finali', hint: 'Premi "procedi" per generare la tua idea', }
    },
    sideButtons: {
        scanario: { label: 'scenario' },
        cards: { label: 'le mie carte' }
    },
    finalMessage: {
      playAgain:'Gioca ancora',
      scoreboard:'Classifica',
      name: {
        hint: 'Il tuo nome',
        error: 'Scrivi qui',
        confirm:'Aggiorna'
      },
      message: 'Grazie per aver giocato a Data Slots',
      hint:"Questo e' il tuo nickname per entrare a far parte della classifica di Data Slots. <br> Torna presto e usa il tuo nickname per controllare il tuo posizionamento."
    }
  },
  profilation:{
      title: 'Raccontaci di te',
      hint: '',

      name: {
          hint: 'Il tuo nome',
          error: 'Fai la tua scelta'
      },
      role: {
          hint: 'La tua posizione',
          options: [
              'Accademico',
              'Cittadino',
              'Funzionario cittadino'
          ],
          error: 'Fai la tua scelta'

      },
      region: { hint: 'Dove stai giocando?', options: regions,error: 'Fai la tua scelta' },
      age: {
          hint: 'La tua età',
          options: [
              'meno di 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'sopra i 65'
          ],
          error: 'Fai la tua scelta'

      },
      gender: {
          hint: 'Pronomi preferiti',
          options: [
              'Lei',
              'Lui',
              'Loro',
              'Scrivi qui',
              'Preferisco non dirlo'
          ],
          error: 'Fai la tua scelta'
      },
      customGender: {
          hint: 'Pronomi preferiti',
          error: 'Fai la tua scelta'

      },
      confirm: 'Procedi'
  },
  ideation:{
      title: 'Ideazione',
      hint: 'Quale idea/esperienza suggeriresti per il tuo scenario utilizzando almeno 3 delle tue carte? <br>Hai 5 minuti.',
      projectTitle: {
          hint: 'Titolo',
          error: 'Fai la tua scelta'
      },
      name: {
          hint: 'Il tuo nome',
          error: 'Fai la tua scelta'
      },
      projectCaption: {
          hint: 'Claim',
          error: 'Fai la tua scelta'
      },
      projectDescription: {
          hint: 'Breve descrizione',
          error: 'Fai la tua scelta'
      },
      projectRatings:{
        translation:"* Alcune idee sono tradotte automaticamente dalla lingua originale",
        ratings:{
          hint:'Valuta le idee',
          subtitle:"Invasività &#8594; preoccupazioni per la privacy sull'uso dei dati <br> Benefici &#8594; valore generato dall'idea"
        },
        investments:{
          hint:'Ora diventi un investitore',
          subtitle:'Hai dieci fiches. Trascina le tue fisches nelle idee ricevute',
          icon:'swipe_up'
        }
      },
      read_more:'Per saperne di più',
      confirm: 'Invia',
      invasiveness:'Invasività',
      benefit:'Benefici',
      high:'Alto',
      low:'Basso'
  },
  scoreboard:{
    search:'Cerca',
    name:'Nickname',
    position:'Posizione',
    title:'Titolo',
    country: 'Paese',
    score: 'Punteggio',
    ratings:'Visualizzazioni'
  }
}