<template>
<div class="row flex-center project-evaluation" :class="{'isRating': internalLevel ==='ratings'}">
  <div class="row col-12 flex-center">
    <div class="">
      <TransitionGroup name="fade" mode="out-in" tag="div">
        <GameHint
          class="game-hint"
          :class="internalLevel !== 'ratings' && 'q-mb-xl'"
          :key="internalLevel"
          :message="$t(`ideation.projectRatings.${internalLevel}.hint`)"
          :hint="$t(`ideation.projectRatings.${internalLevel}.subtitle`)"
          :icon="$t(`ideation.projectRatings.${internalLevel}.icon`) !== `ideation.projectRatings.${internalLevel}.icon` ? $t(`ideation.projectRatings.${internalLevel}.icon`) : ''"
        />
      </TransitionGroup>
      <p v-if="internalLevel === 'ratings'" class="q-mb-md q-mt-xl notice-text">{{ $t('ideation.projectRatings.translation') }}</p>
    </div>
  </div>
  <div class="row flex-center projects-list">
    <Transition name="fade" tag="div">
      <CardProject
        v-if="internalLevel === 'ratings' && project"
        :cardData="project"
        :index="0"
        @completed="handleCompleted"
        :isRating="internalLevel ==='ratings'"
        class="user-project"
      />
    </Transition>

    <CardProject
      v-for="(project,index) in usersProjects"
      :key="project.id"
      :index="index + 1"
      :cardData="project"
      :isRating="internalLevel ==='ratings'"
      @completed="handleCompleted"
    />
  </div>

<TransitionGroup name="fade" mode="out-in" tag="div" class="row col-12 justify-center">
  <div v-if="internalLevel ==='investments'" class="investments-pool">
      <div class="investments-container" data-investments-id="pool">
        <div class="coin" v-for="index in 10" :key="index"></div>
      </div>
  </div>
</TransitionGroup>

    <div class="col-12 row flex-center q-mt-xl confirm">
      <q-btn @click="submit" :disabled="buttonEnabled" :label="$t('ideation.confirm')" type="submit" color="primary" unelevated rounded />
    </div>
</div>
</template>


<script setup>
import axios from 'axios';

import Emitter from '../utils/Emitter'
import FormElement from "../components/FormElement.vue";
import FormRatings from "../components/FormRatings.vue";
import CardProject from "../components/CardProject.vue";
import GameHint from "../components/GameHint.vue"
import Card from "../components/Card.vue";
import {ideation as contents} from "../contents/profilation"
import Sortable, { MultiDrag } from "sortablejs";

import { useStore } from "vuex";
import {  ref, reactive,watch, computed,nextTick, inject,onMounted } from "vue";
import { useI18n } from 'vue-i18n'

const { locale } = useI18n();
const store = useStore();
const emit = defineEmits(["completed"]);

const internalLevel = ref('ratings')

const project = computed(() => {
  return store.state.projectDescription ? Object.assign(store.state.projectDescription,{id:store.state.sessionId,scenario:store.state.scenario.index, finalCards: store.state.finalCards}) : null
})
const buttonEnabled = computed(()=>{
  return internalLevel.value === 'ratings' ? ratingStates.value.some((state)=>!state) : !investmentReady.value;
})
const usersProjects = ref([])
const ratingStates = ref([null])
const investmentsLevels = ref([null])
const investmentReady = ref(false)
const sortables = ref([]);

watch(
  () => internalLevel.value,
  () => {
    window.scrollTo(0,0)
  }
)

const $firebase = inject('firebase')

const libreTranslateApiKey = "a532fab0-b8fd-478d-aaa5-67ed4c9d125b" 

$firebase.getUsersProjects(store.state.scenario.index).then(async (projects)=>{
  usersProjects.value = projects.map((p)=>Object.assign(p.data(),{id:p.id})).filter((p)=>p.id !== store.state.sessionId).filter((p)=> p.description.length > 10 && p.title.length > 3).slice(0,3)
  for (var i = 0; i < usersProjects.value.length; i++) {
    const p = usersProjects.value[i];
    if((p.languageDetect && p.languageDetect !== locale.value) || (locale.value !== 'en' && !p.languageDetect)){
      let descriptionTranslated = null;
      let captionTranslated = null;
      let titleTranslated = null;
      try{
        if(p.description){
        const {data} = await axios.post('https://libretranslate.com/translate',{
          q:p.description || '',
          source:p.languageDetect || 'en',
          target:locale.value,
          format:'text',
          api_key: libreTranslateApiKey
        })
        descriptionTranslated = data;
      }
      if(p.caption){
        const {data} = await axios.post('https://libretranslate.com/translate',{
          q:p.caption || '',
          source:p.languageDetect || 'en',
          target:locale.value,
          format:'text',
          api_key: libreTranslateApiKey
        })
        captionTranslated = data;
      }
      if(p.title){
        const {data} = await axios.post('https://libretranslate.com/translate',{
          q:p.title || '',
          source:p.languageDetect || 'en',
          target:locale.value,
          format:'text',
          api_key: libreTranslateApiKey
        })
        titleTranslated = data
      }
      p.title = titleTranslated && titleTranslated.translatedText ? titleTranslated.translatedText : p.title
      p.description = descriptionTranslated && descriptionTranslated.translatedText ? descriptionTranslated.translatedText : p.description
      p.caption = captionTranslated && captionTranslated.translatedText ? captionTranslated.translatedText : p.caption
      p.originalDescription = p.description;
      p.originalCaption = p.caption;
      p.originalTitle = p.title;
      }
      catch(err){
        console.log(err);
        $firebase.addError({message:`Error with translation of projects: ${err.message}`})
      }
    }
    usersProjects.value.slice(i,1,p);
    ratingStates.value.push(null)
    investmentsLevels.value.push(null)
  }
  // usersProjects.value.forEach((p, i) => {
  //   if(p.languageDetect && p.languageDetect !== 'en'){
  //     p.originalDescription = p.description;
  //     p.originalCaption = p.caption;
  //   }
  //   ratingStates.value.push(null)
  //   investmentsLevels.value.push(null)
  // });
})

const submit = ()=>{
  if(internalLevel.value ==='ratings'){
    Emitter.emit('lastStep',1)
    store.commit("setProjectRatings", ratingStates.value);
    internalLevel.value = 'investments'
    nextTick(()=>{
      initSortable()
    })
  } else{
    store.commit("setProjectInvestments", investmentsLevels.value);
    emit("completed");
  }
}

function handleCompleted(payload) {
  ratingStates.value[payload.index] = payload;
}

const checkInvestmentLevels = ()=>{
  const investmentsContainers = document.querySelectorAll('.investments-container');
  investmentsContainers.forEach((container, i) => {
    const idInvestment = container.dataset.investmentsId;
    const coins = container.querySelectorAll('.coin');
    if(idInvestment !=='pool'){
      const index = parseInt(idInvestment);
      investmentsLevels.value[index-1] = {project: usersProjects.value[index-1],investment:coins.length};
    }
    if(idInvestment ==='pool' && coins.length === 0){
      investmentReady.value = true;
    }
  });

}

const initSortable = ()=>{
  const options = {
    group: "cards",
    animation: 250,
    easing: "cubic-bezier(1, 0, 0, 1)",
    ghostClass: "coin-element-ghosted", // Class name for the drop placeholder
    chosenClass: "coin-element-dragged", // Class name for the chosen item
    dragClass: "coin-element-dragged", // Class name for the dragging item
    fallbackClass: "coin-element-dragged", // Class name for the cloned DOM Element when using forceFallback
    swapClass: "coin-element-selected", // The class applied to the hovered swap item

    onAdd: function (evt) {
      checkInvestmentLevels()
    }
  };

  const investmentsContainers = document.querySelectorAll('.investments-container');

  investmentsContainers.forEach((container, i) => {
    sortables.value.push(Sortable.create(container, options))
  });
}

try {
  Sortable.mount(new MultiDrag());
} catch (error) {}

// onMounted(()=>{
//   initSortable()
// })

</script>

<style lang="scss">
@import '../style/shared.scss';

.project-evaluation{
  @include max-responsive(sm){
    padding-bottom: 100px;

    &.isRating{
      align-items: flex-start !important;
    }

    .confirm{
      margin-top: 20px !important;
      justify-content: center;
    }
    .notice-text{
      font-size: 13px;
      margin-bottom: 20px !important;
    } 

    .investments-pool{
      flex-grow: 1;
      .investments-container{
      width: 100%;
      height: auto;
      min-height: 60px;
      .coin{

      }
    }
    }
  }
  .fade-move,
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-leave-active {
    position: absolute;
  }
}

.investments-container{
  margin-top: 30px;
  height: 65px;
  width: 470px;
  max-width: 500px;
  border: solid 1px rgb(176, 176, 176);
  border-radius: 8px;
  display: flex;
  padding: 10px;
  gap:5px;
  align-items: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .coin{
    cursor: grab;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #293B43;
    border: none;
    &.coin-element-ghosted{
      opacity: .4;
    }
    &.coin-element-dragged{
      cursor: grabbing;
    }
  }
}

.isRating{
  .projects-list{
    .card-project-wrapper{
        &:first-of-type{
          @include responsive(sm){
            margin-right: 60px;
          }

          .q-card{
            background: rgba(#2e434d, 0.15);
          }
          @include responsive(sm){
            &:after{
            content: "";
            display: inline-block;
            position: absolute;
            top: 20px;
            right: -55px;
            height: calc(100% - 40px);
            width: 1px;
            background: rgba(0, 0, 0, 0.1);
          }
          }
      }
    }
  }
}
  .projects-list{
    gap:50px;
    @media screen and (max-width:1400px){
      margin-left: calc(var(--page-gutter) * -1);
      margin-right: calc(var(--page-gutter) * -1);
      width: calc(100% + (var(--page-gutter) * 2));
      padding-left: calc(50% - 150px);
      padding-right: calc(var(--page-gutter) * 8);
      display: flex !important;
      flex-wrap: nowrap !important;
      justify-content: flex-start !important;
      overflow: scroll;
      scroll-snap-type: x mandatory;
      gap:30px !important;
      padding-bottom: 20px;     
      
      &::-webkit-scrollbar {
        width: 1em;
        height: 6px;
        cursor: pointer;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
      }
    }
    @media screen and (max-width:700px){
      padding-left: calc(var(--page-gutter) * 2);
      padding-right: calc(var(--page-gutter) * 2);
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .card-project-wrapper{
      position: relative;

      @include max-responsive(sm){
        scroll-snap-align: center;
        flex-shrink: 0;
      }

      .q-field__control{
        min-height: 50px;
      }
      .q-field__control-container{
        padding-top: 17px;
      }
    }
  }
</style>
