import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "개인 정보 데이터", "description": "G성별, 인종, 종교적 신념, 성적 취향, 정치적 견해, 음악, TV/영화 또는 스트리밍 선호도, 휴대기기 사용 습관, 일정." },
      { "index": 2, "id":"2_health", "name": "보건 데이터", "description": " 신체 및 정신 상태, 유전 또는 생체 정보, 체중, 체질량 지수 (BMI), 스트레스, 보건 시설 접근성." },
      { "index": 3, "id":"3_dietary", "name": "식습관 데이터", "description": "음식 또는 음료 선택, 알레르기 및 과민증, 흡연 습관, 약물 사용, 영양 정보, 바·레스토랑·편의 시설에 대한 선호도." },
      { "index": 4, "id":"4_transactions", "name": "전자거래 데이터", "description": "신용카드 이용, 인터넷 뱅킹, 온·오프라인 구매, 상품 소비 정보." },
      { "index": 5, "id":"5_socialnetworks", "name": "S소셜 네트워킹 데이터", "description": "소셜 네트워크의 개인적인 사용, 업무 커뮤니케이션, 이메일, 애플리케이션, 대면 회의, 데이트 앱, 화상 통화 플랫폼.      " },
      { "index": 6, "id":"6_human", "name": "인간의 이동성 데이터", "description": "지리적 위치, 타임스탬프 및 인원 수를 포함한 개인의 이동성 정보 (자가용 또는 대중교통 이용)." },
      { "index": 7, "id":"7_animal", "name": "동물의 이동성 데이터", "description": "지리적 위치, 타임스탬프 및 개체 수를 포함한 애완동물 또는 가축의 이동성 정보." },
      { "index": 8, "id":"8_vehicle", "name": "차량 이동성 데이터", "description": "V지리적 위치, 타임스탬프, 차량 대수 및 기능을 포함한 차량의 이동성 정보 (자동차, 자전거, 스쿠터, 버스, 비행기 등)." },
      { "index": 9, "id":"9_utility", "name": "유틸리티 데이터", "description": "물, 전기, 가스 소비량, 난방 및 냉방 습관, 쓰레기 및 재활용 습관, 유선 전화, Wi-Fi." },
      { "index": 10, "id":"10_environmental", "name": "환경 데이터", "description": "실내외 공기질, 온도, 화학물질, 화재, 빛, 소음공해." },
      { "index": 11, "id":"11_infrastructure", "name": "공공 인프라 데이터", "description": "교량, 도로 상태, 공공 가구, 신호등, 공공 장소 유지 보수." },
      { "index": 12, "id":"12_greenery","name": "녹지 데이터", "description": "나무, 공원, 정원, 농업 지역, 녹색 캐노피 및 식물 다양성." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "가정 환경", "description": "사람들의 생활 환경에 기여하는 가정 생활의 전 요소. 여기서 가정 환경이란, 실내외 공간, 개인 주택 뿐만 아니라 양로원·고아원·장애인 치료시설·아동 보호시설과 같은 공동생활가정 등 다양한 형태의 주거 커뮤니티 기관을 포함." },
      { "index": 2, "color":"#295966", "name": "근로 환경", "description": "사회적 특징 및 물리적 조건과 같이 작업 환경을 구성하고 근로자들에게 영향을 미치는 요소. 이러한 요소들은 근로자들의 웰빙, 직장 내 관계, 협업, 효율성 및 건강에 영향을 줄 수 있습니다. 여기서 근로 환경이란, 재택 근무부터 오피스빌딩, 공장 (실내 또는 실외) 등 다양한 형태를 포함." },
      { "index": 3, "color":"#8862A6", "name": "공공 장소", "description": "도로, 광장, 공원, 해변, 자전거 도로, 차고, 공공건물 (관공서, 도서관 등), 공공시설 (엘리베이터 등), 상점, 식당, 커뮤니티센터, 체육시설, 연회장, 극장, 미디어 룸 등 일반적으로 개방되어있고 사람들이 접근 가능한 다양한 장소를 포함." }
  ],
  discover:{
    blocks:[
      {
        title:'배경',
        text:"데이터는 모든 곳에서 수집되고 있습니다. 데이터 기반 솔루션은 우리가 공공 공간, 생활 공간, 업무 공간을 사용하는 방식을 바꾸고 있습니다."
      },
      {
        title:"미션",
        text:"Data Slots 는 다음의 고유한 미션을 바탕으로 만들어진 게임입니다: 개인정보 보호 문제와 이점 사이에서 균형을 이루는 데이터 기반의 솔루션 제안."
      },
      {
        title:"게임 설명",
        subtitle:"시나리오 카드",
        id:"scenario",
        text:"아이디어를 제안할 시나리오 카드를 무작위로 선택하세요."
      },
      {
        subtitle:"데이터 카드",
        id:"data-cards",
        text:"데이터 카드 3장을 무작위로 받습니다. 한 장의 카드는 잠그고 게임 내내 보관합니다. 남은 데이터 카드는 게임을 진행하는동안 자유롭게 교환하여 본인의 자산을 구축하세요."
      },
      {
        title:"아이디어",
        id:"ideation",
        text:"본인이 가진 4개의 데이터 카드를 사용하여 시나리오에 적합한 솔루션을 제안하세요."
      },
      {
        title:"평가",
        id:"assessments",
        text:"솔루션을 제출하면 다른 플레이어들이 만든 세 가지의 다른 아이디어가 표시됩니다. <br>각 아이디어를 개인 정보 보호 문제 및 이익 관점에서 평가하세요."
      },
      {
        title:"투자",
        id:"investment",
        text:"마지막으로, 칩을 이용하여 이 아이디어들에 투자하십시오."
      },
      {
        title:"",
        id:"scoreboard",
        text:"스코어보드를 까먹지 마세요 마세요! 닉네임을 만들고 스코어보드에서 본인의 순위를 확인해보세요."
      }
    ],
    contact:"<strong>연락처:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>유의 사항:</strong> Data Slots 는 어떠한 개인 데이터를 요구하지 않습니다. Data Slots 에서 생성된 데이터는 익명이며 학술 연구 목적으로만 사용될 예정입니다.",
    credits:"<strong>팀:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>웹사이트:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong> 감사의 말:</strong> Yuki Machida, Oluwatobi Oyinlola, SCL 멤버들, 그리고 Data Slots 의 대면 버전을 플레이하고 게임의 개선에 도움을 준 전 세계 도시의 참가자들. SCL 컨소시엄에 감사드립니다."
  },
  gameHints:{
    yourCards:"Your cards",
    cardsDeck:"Cards deck",
    introText:'데이터 기반 솔루션의 윤리와 가치에 대한 게임 (약 10분 소요)',
    introPlay:'게임 시작',
    introDiscover:'더 알아보기',
    introScoreboard:'스코어보드',
    title: '슬롯 머신',
    hint: '',
    cardSelect: { message: '시나리오를 선택해주세요', hint: '당신의 임무는 다음 중 하나의 시나리오에 대한 경험을 생성하기 위해 데이터 기반 아이디어를 설계하는 것입니다: 공공 공간, 생활 환경, 작업 환경', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: '당신은 3장의 카드를 받았습니다. 게임 전체동안 유지할 카드 하나를 잠그세요.', hint: 'Just pick it by tapping the card', icon: '' },
      level1: { message: '이제 당신의 덱에서 제거할 카드 한 장을 선택하세요.', hint: '탭하여 선택', icon: '' },
      level2: { message: '이제 당신의 덱에 포함시킬 카드 한 장을 선택하세요.', hint: '탭하여 선택', icon: '' },
      level3: { message: '이제 당신의 덱에서 제거할 또 다른 카드 한 장을 선택하세요.', hint: '탭하여 선택', icon: '' },
      level4: { message: '이제 당신의 덱에 포함시킬 카드 한 장을 선택하세요.', hint: '탭하여 선택' , icon: '' },
      level5: { message: '이제 당신의 마지막 카드를 선택하세요.', hint: '탭하여 선택', icon: '' },
      level6: { message: '이것이 당신의 최종 카드들입니다.', hint: '다음 을 클릭하여 아이디어 제안' }
    },
    slot: {
        level0: { message: '당신은 3장의 카드를 받았습니다. 게임 전체동안 유지할 카드 하나를 잠그세요.', hint: '클릭하여 선택', icon: 'highlight_alt' },
        level1: { message: '이제 카드 한 장을 교환하세요.', hint: '카드 1장을 위 카드 덱에 드래그하기', icon: 'swipe_up' },
        level2: { message: '이제 카드 한 장을 더 교환하세요.', hint: '카드 1장을 위 카드 덱에 드래그하기', icon: 'swipe_up' },
        level3: { message: '이제 마지막 카드를 선택하세요.', hint: '위 카드 덱에서 카드 1장을 드래그하기', icon: 'swipe_down' },
        level4: { message: '이것이 당신의 최종 카드들입니다.', hint: '다음 을 클릭하여 아이디어 제안', }
    },
    sideButtons: {
        scanario: { label: '시나리오' },
        cards: { label: '내 카드' }
    },
    finalMessage: {
      playAgain:'Play Again',
      scoreboard:'Scoreboard',
      name: {
        hint: '닉네임',
        error: '답변을 입력하세요',
        confirm:'업데이트'
      },
      message: 'Data Slots 을 즐겨주셔서 감사합니다.',
      hint:"스코어보드에 등록하기 위한 닉네임입니다. <br> 닉네임을 이용하여 당신이 제안한 아이디어 순위를 확인하세요."
    }
  },
  profilation:{
      title: '당신에 대해 알려주세요',
      hint: '',

      name: {
          hint: '당신의 이름',
          error: '아무거나 입력하세요'
      },
      role: {
          hint: '당신의 역할',
          options: [
              '학생',
              '시민',
              '시 공무원'
          ],
          error: '무언가를 선택하십시오'

      },
      region: { hint: '국가/지역', options: regions,error: '답변을 선택하세요' },
      age: {
          hint: '나이',
          options: [
              '16세 미만',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              '65세 이상'
          ],
          error: '답변을 선택하세요'

      },
      gender: {
          hint: '대명사',
          options: [
              '그녀',
              '그',
              '그들',
              '직접 입력',
              '답변하지 않기를 원함'
          ],
          error: '답변을 선택하세요'
      },
      customGender: {
          hint: '대명사',
          error: '답변을 입력하세요'

      },
      confirm: '다음'
  },
  ideation:{
      title: '아이디어, # 시나리오에 대한 경험을 생성하기 위해 데이터 기반 아이디어를 설계',
      hint: '당신의 최종 카드들 중, 최소 3장을 결합하여 주어진 시나리오에 대해 아이디어/경험을 제안하세요. <br>제한시간: 5분',
      projectTitle: {
          hint: '프로젝트 제목',
          error: '답변을 입력하세요'
      },
      name: {
          hint: '당신의 이름',
          error: '답변을 입력하세요'
      },
      projectCaption: {
          hint: '프로젝트 한 줄 요약',
          error: '답변을 입력하세요'
      },
      projectDescription: {
          hint: '프로젝트 간략한 설명',
          error: '답변을 입력하세요'
      },
      projectRatings:{
        translation:"* 몇몇 프로젝트들은 최초 작성된 언어에서 자동 번역되어 표시됩니다.",
        ratings:{
          hint:'침입성 및 이익 관점에서 다양한 아이디어를 평가하세요, 각 아이디어를 개인 정보 보호 문제 및 이점 관점에서 평가하세요.',
          subtitle:'침입성 &#8594; 각 아이디어를 위한 데이터 사용에 따른 개인 정보 침해 문제 <br> 이익 &#8594; 각 아이디어가 가져다주는 혜택'
        },
        investments:{
          hint:'이제 당신은 투자자입니다',
          subtitle:'당신은 10개의 동전을 가지고 있습니다. 코인을 드래그하여 각 아이디어에 투자하세요.',
          icon:'swipe_up'
        }
      },
      read_more:'더 읽기',
      confirm: '제출',
      invasiveness:'침입성',
      benefit:'이익',
      high:'높은',
      low:'낮은'
  },
  scoreboard:{
    search:'검색',
    name:'닉네임',
    position:'순위',
    title:'제목',
    country: '국가',
    score: '점수',
    ratings:'등급'
  }
}
