import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "Données personnelles", "name_short": "Personnelles", "description": "Sexe, origine, croyances, orientation sexuelle, opinions politiques, préférences en matière de musique, de télévision, de films ou de streaming, usage du téléphone, emploi du temps." },
      { "index": 2, "id":"2_health", "name": "Données de santé", "name_short": "Santé", "description": "Condition physique et mentale, informations génétiques ou biométriques, poids, IMC, niveau de stress, accès aux infrastructures de santé." },
      { "index": 3, "id":"3_dietary", "name": "Données sur les habitudes de consommation", "name_short": "Consommation", "description": "Alimentation (nourriture ou boissons), allergies et intolérances, tabagisme, addiction, santé nutritionnelle, préférences en matière de bars, de restaurants et d'équipements." },
      { "index": 4, "id":"4_transactions", "name": "Données sur les transactions bancaires", "name_short": "Trans bancaires.", "description": "Utilisation d'une carte de paiement, opérations bancaires, achats en ligne, informations sur la consommation." },
      { "index": 5, "id":"5_socialnetworks", "name": "Données des réseaux sociaux", "name_short": "Réseaux sociaux", "description": "sage des réseaux sociaux personnel et professionnel, courrier électronique, applications, rendez-vous, applications de rencontres, plateformes d'appels vidéo." },
      { "index": 6, "id":"6_human", "name": "Données de mobilité", "name_short": "Mobilité", "description": "Déplacements individuels, utilisation de véhicules individuels ou des transports en commun, informations sur la géolocalisation, la date et l'heure du déplacement, le nombre de personnes en déplacement." },
      { "index": 7, "id":"7_animal", "name": " Données sur le déplacement des animaux", "name_short": "Animaux", "description": "ouvements des animaux domestiques ou du bétail, avec informations sur la géolocalisation, la date et l'heure du déplacement, le nombre d’animaux." },
      { "index": 8, "id":"8_vehicle", "name": "Données sur les véhicules", "name_short": "Véhicules", "description": "La géolocalisation des véhicules (voitures, vélos, scooters, bus, avions), la date et l'heure de circulation, le nombre et les caractéristiques des véhicules." },
      { "index": 9, "id":"9_utility", "name": "Données sur la consommation domestique", "name_short": "consommation", "description": "Consommation d'eau, d'électricité et de gaz, habitudes de chauffage, habitudes de tri des déchets et de recyclage, abonnements de téléphone et internet." },
      { "index": 10, "id":"10_environmental", "name": "Données environnementales", "name_short": "Environnementales", "description": "Qualité de l'air intérieur et extérieur, température, produits chimiques, incendies, lumière, pollution sonore." },
      { "index": 11, "id":"11_infrastructure", "name": "Données sur les infrastructures publiques", "name": "Infrastructures", "description": "Ponts, état des routes, mobilier urbain, feux de signalisation, entretien des espaces publics." },
      { "index": 12, "id":"12_greenery", "name": "Données de végétalisation", "name": "Végétalisation", "description": "Arbres, parcs, jardins, zones agricoles, canopée et diversité végétale." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Habitat", "description": "Conditions d’habitat qui contribuent à la qualité de vie. Caractéristiques du logement (espaces intérieurs et extérieurs), type de résidences, vie en institutions tels que les établissements médico-sociaux et les foyers (orphelinats, maisons de retraite, prisons, centres de rééducation et foyers d'accueil)." },
      { "index": 2, "color":"#295966", "name": "Environnement de travail", "description": "Éléments qui constituent le cadre de travail et qui impactent les employés, tels que les conditions salariales et les conditions de travail. Ces éléments peuvent avoir un impact sur le bien-être, les relations au travail, la collaboration, l'efficacité et la santé des employés. Il peut s'agir d'un bureau à domicile, d'un immeuble de bureaux ou d'une usine (extérieure ou intérieure)." },
      { "index": 3, "color":"#8862A6", "name": "Lieux et espaces publics", "description": "Un lieu généralement ouvert et accessible aux personnes, tels que les places publiques, les parcs, les plages, les pistes cyclables, les routes, les garages, les bâtiments administratifs,  les bibliothèques, les ascenseurs, les magasins, les restaurants, les maisons de quartier, les installations sportives, les salles de fête, les salles de théâtre ou de médias." }
  ],
  discover:{
    blocks:[
      {
        title:'Contexte',
        text:"Les données sont collectées partout. Les solutions basées sur les données changent la façon dont nous utilisons les espaces publics, les lieux de vie et de travail."
      },
      {
        title:"Mission",
        text:"Data Slots est un jeu dont la mission est unique : proposer des solutions basées sur les données en équilibrant les préoccupations et les avantages en matière de protection de la vie privée."
      },
      {
        title:"La partie",
        subtitle:"Cartes de scénario",
        id:"scenario",
        text:"Choisissez au hasard une carte de scénario pour proposer vos idées."
      },
      {
        subtitle:"Cartes de données",
        id:"data-cards",
        text:"Vous recevez trois cartes de données aléatoires. Verrouillez une carte, que vous gardez pendant toute la partie. Ensuite, échangez d'autres cartes de données pour construire vos atouts."
      },
      {
        title:"Ideation",
        id:"ideation",
        text:"Utilisez vos 4 cartes de données pour proposer des solutions pour votre scénario."
      },
      {
        title:"Évaluation",
        id:"assessments",
        text:"Une fois que vous aurez soumis votre solution, Data Slots affichera trois autres idées créées par d'autres joueurs.<br> Évaluez les problèmes de confidentialité et les avantages de chaque idée."
      },
      {
        title:"Investissement",
        id:"investment",
        text:"Enfin, investissez vos jetons dans ces idées."
      },
      {
        title:"",
        id:"tableau d'affichage",
        text:"Ne manquez pas le tableau d'affichage! Créez un surnom et suivez votre classement grâce au tableau d'affichage."
      }
    ],
    contact:"<strong>Contact:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Avis de non-responsabilité:</strong> Data Slots ne demande aucune donnée personnelle. Les données générées dans Data Slots sont anonymes et ne seront utilisées que pour la recherche académique.",
    credits:"<strong>Équipe:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Website:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Acknowledgment:</strong> Yuki Machida, Oluwatobi Oyinlola, et les membres du SCL, ainsi que les participants des villes du monde entier qui ont joué à la version en personne de Data Slots et nous ont aidés à améliorer le jeu. Nous remercions le consortium SCL."
  },
  gameHints:{
    yourCards:"Vos cartes",
    cardsDeck:"Jeu de cartes",
    introText:"Un jeu de 10 minutes sur l'éthique et la valeur des solutions basées sur les données",
    introPlay:"Jouez le jeu",
    introDiscover:'Découvrez',
    introScoreboard:"Tableau d'affichage",
    title: 'La machine à sous',
    hint: '',
    cardSelect: { message: 'Veuillez sélectionner le scénario', hint: "Votre mission consiste à concevoir une idée basée sur des données pour créer des expériences pour l'un de ces scénarios : espace public, environnement de vie et de travail.", icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: 'Vous avez trois cartes. Verrouillez-en une pour la garder tout au long du jeu', hint: 'Choisissez-le simplement en appuyant sur la carte', icon: '' },
      level1: { message: 'Choisissez maintenant la carte que vous souhaitez retirer de votre deck', hint: 'Appuyez sur la carte', icon: '' },
      level2: { message: 'Choisissez maintenant la carte que vous voulez dans votre deck', hint: 'Appuyez sur la carte', icon: '' },
      level3: { message: 'Choisissez maintenant une autre carte que vous souhaitez supprimer de votre deck', hint: 'Appuyez sur la carte', icon: '' },
      level4: { message: 'Choisissez maintenant la carte que vous voulez dans votre deck', hint: 'Appuyez sur la carte' , icon: '' },
      level5: { message: 'Maintenant, choisissez votre dernière carte', hint: 'Appuyez sur la carte', icon: '' },
      level6: { message: 'Ce sont vos cartes finales', hint: 'Cliquez sur "Continuer" pour proposer votre idée' }
    },
    slot: {
        level0: { message: 'Vous avez trois cartes. Verrouillez-en une pour la garder tout au long du jeu', hint: 'Il suffit de le choisir en cliquant sur', icon: 'highlight_alt' },
        level1: { message: 'Maintenant, échangez une de vos cartes', hint: 'Faites glisser une carte vers le paquet supérieur', icon: 'swipe_up' },
        level2: { message: 'Maintenant, échangeons une autre carte', hint: 'Faites glisser une carte vers le paquet supérieur', icon: 'swipe_up' },
        level3: { message: 'Maintenant, choisissez votre dernière carte', hint: 'Faites glisser une carte vers le paquet supérieur', icon: 'swipe_down' },
        level4: { message: 'Ce sont vos cartes finales', hint: 'Cliquez sur "Continuer" pour proposer votre idée', }
    },
    sideButtons: {
        scanario: { label: 'scénario' },
        cards: { label: 'mes cartes' }
    },
    finalMessage: {
      playAgain:'Rejouer',
      scoreboard:"Tableau d'affichage",
      name: {
        hint: 'Votre nom',
        error: 'Veuillez taper quelque chose',
        confirm:'Mise à jour'
      },
      message: 'Merci de jouer à Data Slots',
      hint:"C'est votre surnom pour rejoindre le tableau d'affichage <br> Revenez bientôt et utilisez votre pseudo pour vérifier le classement de votre idée."
    }
  },
  profilation:{
      title: 'Parlez-nous de vous',
      hint: '',

      name: {
          hint: 'Votre nom',
          error: 'Veuillez taper quelque chose'
      },
      role: {
          hint: 'Votre rôle',
          options: [
              'Académique',
              'Citoyen',
              'Fonctionnaire de la ville'
          ],
          error: 'Veuillez taper quelque chose'

      },
      region: { hint: "où jouez-vous", options: regions,error: 'Veuillez taper quelque chose' },
      age: {
          hint: 'Votre âge',
          options: [
              'moins de 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'plus de 65'
          ],
          error: 'Veuillez taper quelque chose'

      },
      gender: {
          hint: 'Vos pronoms',
          options: [
              'Elle/le',
              'Il/elle',
              'Ils/elles',
              'Laissez-moi taper',
              'Je préfère ne pas dire'
          ],
          error: 'Veuillez taper quelque chose'
      },
      customGender: {
          hint: 'Vos pronoms',
          error: 'Veuillez taper quelque chose'

      },
      confirm: 'Continuer'
  },
  ideation:{
      title: "L'idéation",
      hint: "Quelle idée/expérience suggéreriez-vous pour votre scénario combinant au moins 3 de vos cartes? <br> Vous avez 5 minutes.",
      projectTitle: {
          hint: 'Titre du projet',
          error: 'Veuillez taper quelque chose'
      },
      name: {
          hint: 'Votre nom',
          error: 'Veuillez taper quelque chose'
      },
      projectCaption: {
          hint: 'Légende du projet',
          error: 'Veuillez taper quelque chose'
      },
      projectDescription: {
          hint: 'Brève description du projet',
          error: 'Veuillez taper quelque chose'
      },
      projectRatings:{
        translation:"* Certains projets sont automatiquement traduits de leur langue d'origine",
        ratings:{
          hint:"Évaluez les idées.",
          subtitle:"Invasivité &#8594; les préoccupations relatives à la vie privée concernant l'utilisation des données dans chaque idée <br> Avantages &#8594; les avantages créés par chaque idée"
        },
        investments:{
          hint:"Maintenant, vous êtes l'investisseur",
          subtitle:'Vous avez dix pièces. Faites glisser les pièces pour investir dans ces idées',
          icon:'swipe_up'
        }
      },
      read_more:'Lire la suite',
      confirm: 'Soumettre',
      invasiveness:'Invasivité',
      benefit:'Avantage',
      high:'Haut',
      low:'Faible'
  },
  scoreboard:{
    search:'Recherche',
    name:'Surnom',
    position:'Position',
    title:'Titre',
    country: 'Pays',
    score: 'Score',
    ratings:'Notations'
  }
}