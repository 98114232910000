// FILE: main.js

import { createApp } from 'vue'
import { Quasar } from 'quasar'
import store from "./store"
import router from "./router"

// Import icon libraries
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'

// A few examples for animations from Animate.css:
// import @quasar/extras/animate/fadeIn.css
// import @quasar/extras/animate/fadeOut.css

// Import Quasar css
import 'quasar/src/css/index.sass'
import './style/global.scss'
import './style/arabic.scss'

//Import Firebase initialization script
import FirebaseClient from "./firebaseApp.js";

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from './App.vue'

//Import internationalisation settings
import { i18n } from "./plugins/i18n.js";


const myApp = createApp(App)

myApp.use(store);
myApp.use(router);
myApp.use(i18n);
myApp.use(FirebaseClient);

myApp.use(Quasar, {
    plugins: {}, // import Quasar plugins and add here
})

// Assumes you have a <div id="app"></div> in your index.html
myApp.mount('#app')
