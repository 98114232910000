import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "Личные данные", "description": "Пол, национальность, этническая принадлежность, сексуальная ориентация, политические взгляды, предпочтения в музыке, кино или стриминговых сервисах, привычки в использование мобильных устройств, расписание дня." },
      { "index": 2, "id":"2_health", "name": "Данные о здоровье", "description": "Физическое и психическое состояние, генетическая или биометрическая информация, вес, индекс массы тела, стресс, доступ к услугам здравооохранения." },
      { "index": 3, "id":"3_dietary", "name": "Данные о пищевых привычках", "description": "Выбор еды и напитков, аллергии и непереносимости, курение, употребление наркотиков, информация о нутриентах, предпочтения относительно баров, ресторанов, и других заведений." },
      { "index": 4, "id":"4_transactions", "name": "Данные о транзацкиях", "description": "Использование кредитной карты, интернет банкинг, оффлайн и онлайн покупки." },
      { "index": 5, "id":"5_socialnetworks", "name": "Данные в социальных сетях", "description": "Использование социальных сетей, рабочая коммуникация, электронная почта, приложения, личные встречи, приложения для знакомств, приложения для видеозвонков." },
      { "index": 6, "id":"6_human", "name": "Данные о передвижении людей", "description": "Личные передвижения, использование частного и публичного транспорта, с информацией о геолокации, времени, и количестве человек." },
      { "index": 7, "id":"7_animal", "name": "Данные о передвижении животных", "description": "Передвижения домашних питомцев и сельскохозяйственных животных, с информацией о геолокации, времени, и количестве животных." },
      { "index": 8, "id":"8_vehicle", "name": "Данные о передвижении транспортных средств", "description": "Передвижения транпорта (автомобилей, велосипедов, скутеров, автобусов, самолетов), с информацией о геолокации, времени, и характеристиках транспортного средства." },
      { "index": 9, "id":"9_utility", "name": "Данные о жилищно-коммунальных услугах", "description": "Расход воды, электричества, и газа, использование обогрева и кондиционера, привычки относительно утилизации мусора и использование вторичной переработки (ресайклинга)." },
      { "index": 10, "id":"10_environmental", "name": "Данные об окружающей среде", "description": "Качество воздуха в помещении и на улице, температура, химический состав воздуха, пожары, световое и шумовое загрязнение." },
      { "index": 11, "id":"11_infrastructure", "name": "Данные о публичной инфраструктуре", "description": "Состояние мостов, дорог, уличная мебель, светофоры, содержание публичных пространств." },
      { "index": 12, "id":"12_greenery","name": "Данные об озелененни", "description": "Деревья, парки, сады, сельскохозяйственные зоны, уровень озеленения и биологического разнообразия." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Дом", "description": "Различные аспекты домашнего быта которые характеризуют жизненные условия. Включая внутренние и уличные пространства, частные дома, а так же государственные или частные организации предоставляющие жилье, такие как дома для пожилых людей, детские дома, тюрьмы, медицинские учреждения, и приюты." },
      { "index": 2, "color":"#295966", "name": "Работа", "description": "Различные элементы описывающие рабочую среду и взаимодействие с коллегами, включая материальные и социальные условия на рабочем месте. Эти аспекты могут влиять на общее ощущение благополучия, отношения на работе, кооперацию, эффективность и здоровье человека.  Включая все категории от домашнего офиса до офисного комплекса или завода, внутренние и уличные пространства." },
      { "index": 3, "color":"#8862A6", "name": "Публичные пространства", "description": "Пространства которые открыты для публичного посещения: улицы, площади, парки, пляжи, дорожки для велосипедов, государственные здания (например библиотеки), магазины, рестораны, общественные центры, спортивные заведения и площадки, клубы, театры или медиа-центры." }
  ],
  discover:{
    blocks:[
      {
        title:'Краткая справка',
        text:"Данные собираются повсеместно. Приложения на основе данных влияют на то как мы используем публичные пространства, жилье и рабочие места."
      },
      {
        title:"Миссия",
        text:"Data Slots (Инфо-автомат: от англ. slot machine – слот-машина, игровой автомат) это игра с особой миссией: выработать стратегии обращения с данными таким образом, чтобы сбалансировать выгоды и риски связанные с использованием персональных данных."
      },
      {
        title:"Игра",
        subtitle:"Карты сценариев",
        id:"scenario",
        text:"Выберите случайную карточку сценария чтобы предложить вашу идею."
      },
      {
        subtitle:"Карты с данными",
        id:"data-cards",
        text:"Вы получаете три случайных карты с данными. Выберите одну карту чтобы оставить ее до конца игры. Далее, обменяйте другие карты чтобы пополнить ресурсы."
      },
      {
        title:"Формулировка идеи",
        id:"ideation",
        text:"Используйте не менее 3-х карт с данными чтобы предложить стратегию для вашего сценария."
      },
      {
        title:"Оценка",
        id:"assessments",
        text:"Когда вы предложите вашу стратегию, Data Slots покажет три стратегии предложенные другими игроками. Оцените выгоды и риски связанные с использованием персональных данных для каждоый стратегии."
      },
      {
        title:"Вложения",
        id:"investment",
        text:"В конце, выберите сколько ресурсов вы вложите в каждую из стратегий."
      },
      {
        title:"",
        id:"scoreboard",
        text:"Не забывайте про таблицу очков! Выберите себе игровое имя и следите за своим рейтингом в таблице."
      }
    ],
    contact:"<strong>Контактная информация:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Ответственность за использование данных:</strong> Data Slots не требует ваших личных данных. Данные сгенерированные в ходе игры являются анонимными и могут быть использованы только для академических целей.",
    credits:"<strong>Команда:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Веб-сайт:</strong> Команда + Paul Bouisset, Pietro Leoni<br><strong>Благодарность:</strong> Yuki Machida, Oluwatobi Oyinlola, и всему составу SCL, а так же участникм из разных городов мира, которые согласились играть в Data Slots лично и помогли нам улучшить игру. Мы также благодарим консорциум SCL."
  },
  gameHints:{
    yourCards:"Ваши карты",
    cardsDeck:"Колода карт",
    introText:'Десятиминутная игра об этике и практиках обращения с данными',
    introPlay:'Начать игру',
    introDiscover:'Искать',
    introScoreboard:'Таблица очков',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'Выберите сценарий', hint: 'Ваша миссия – придумать стратегию обращения с данными с целью создания пользовательского опыта в следующих сценариях: публичное пространство, дом или рабочее место.', icon: 'highlight_alt' },
    slotMobile:{
        level0: { message: 'Вы получили три карты. Выберите одну чтобы оставить ее до конца игры', hint: 'Просто выберите его, нажав на карту', icon: '' },
        level1: { message: 'Теперь выберите карту, которую хотите удалить из своей колоды.', hint: 'Коснитесь карты', icon: '' },
        level2: { message: 'Теперь выберите карту, которую хотите добавить в свою колоду.', hint: 'Коснитесь карты', icon: '' },
        level3: { message: 'Теперь выберите другую карту, которую хотите удалить из своей колоды.', hint: 'Коснитесь карты', icon: '' },
        level4: { message: 'Теперь выберите карту, которую хотите добавить в свою колоду', hint: 'Коснитесь карты' , icon: '' },
        level5: { message: 'Теперь выберите свою последнюю карту', hint: 'Коснитесь карты', icon: '' },
        level6: { message: 'Это ваши финальные карты', hint: 'Нажмите «Продолжить», чтобы предложить свою идею.' }
      },
    slot: {
        level0: { message: 'Вы получили три карты. Выберите одну чтобы оставить ее до конца игры', hint: 'просто кликните на нее', icon: 'highlight_alt' },
        level1: { message: 'Теперь обменяйте одну из ваших карт', hint: 'Переложите одну карту на верх колоды', icon: 'swipe_up' },
        level2: { message: 'Теперь обменяйте еще одну из ваших карт', hint: 'Переложите одну карту на верх колоды', icon: 'swipe_up' },
        level3: { message: 'Теперь выберите последнюю карту', hint: 'Возьмите одну карту сверху колоды', icon: 'swipe_down' },
        level4: { message: 'Теперь выберите последнюю карту', hint: 'Возьмите одну карту сверху колоды', }
    },
    sideButtons: {
        scanario: { label: 'Сценарий' },
        cards: { label: 'Мои карты' }
    },
    finalMessage: {
        playAgain:'Играть снова',
      scoreboard:'Таблица очков',
      name: {
        hint: 'Ваше имя',
        error: 'Наберите любой текст',
        confirm:'Обновить'
      },
      message: 'Спасибо вам за игру в Data Slots',
      hint:"Это ваше игровое имя для помещения в таблицу очков"
    }
  },
  profilation:{
      title: 'Расскажите нам о себе',
      hint: '',

      name: {
          hint: 'Ваше имя',
          error: 'Наберите любой текст'
      },
      role: {
          hint: 'Кем вы работаете',
          options: [
              'Ученый',
              'Гражданин',
              'Городской работник'
          ],
          error: 'Выберите любое'

      },
      region: { hint: 'Где вы находитесь', options: regions,error: 'Выберите любое' },
      age: {
          hint: 'Ваш возраст',
          options: [
              'До 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'Старше 65'
          ],
          error: 'Выберите любое'

      },
      gender: {
          hint: 'Как к вам обращаться',
          options: [
              'Она/ей',
              'Он/ему',
              'Они/им',
              'Другое (наберите текст)',
              'Предпочитаю не говорить'
          ],
          error: 'Выберите любое'
      },
      customGender: {
          hint: 'Как к вам обращаться',
          error: 'Наберите любой текст'

      },
      confirm: 'Дальше'
  },
  ideation:{
      title: 'Формулировка идеи',
      hint: 'Какую идею вы бы предложили для вашего сценария используя не менее трех карт.У вас есть 5 минут.',
      projectTitle: {
          hint: 'Название проекта',
          error: 'Наберите любой текст'
      },
      name: {
          hint: 'Your name',
          error: 'Наберите любой текст'
      },
      projectCaption: {
          hint: 'Основная цель проекта',
          error: 'Наберите любой текст'
      },
      projectDescription: {
          hint: 'Короткое описание проекта',
          error: 'Наберите любой текст'
      },
      projectRatings:{
        translation:"* Некоторые идеи автоматически переводятся с языка оригинала.",
        ratings:{
          hint:'Оцените идеи',
          subtitle:'Информационные риски &#8594; Риски связанные с использованием и распространением личных данных для каждого проектаa <br> Выгоды &#8594; Выгоды от осуществления каждого проекта'
        },
        investments:{
          hint:'Теперь вы выступаете в роли инвестора',
          subtitle:'У вас есть десять монет. Переложите монеты чтоб инвестировать в выбранные проекты',
          icon:'swipe_up'
        }
      },
      read_more:'Узнать больше',
      confirm: 'Готово',
      invasiveness:'Информационные риски',
      benefit:'Выгоды',
      high:'Высокие',
      low:'Низкие'
  },
  scoreboard:{
    search:'Поиск',
    name:'Имя пользователя',
    position:'Рейтинг пользователей',
    title:'Название',
    country: 'Страна',
    score: 'Счет',
    ratings:'Рейтинги'
  }
}