<template>
  <div class="socials">
    <a :href="link.link" target="_blank" v-for="(link,index) in socials" :key="index">

      <template v-if="link.name ==='TW'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
      </template>
      <template v-else-if="link.name ==='FB'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
      </template>
      <template v-else-if="link.name ==='IN'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/></svg>
      </template>

    </a>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const url = 'https://data-slots.web.app';
const title = 'Data Slots';
const socials = ref([ {
    name: 'FB',
    link: `http://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`
  }, {
    name: 'TW',
    link: `http://www.twitter.com/intent/tweet?url=${url}&text=${title}`
  }, {
    name: 'IN',
    link: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
  } ]);
</script>

<style lang="scss">
.socials{
  position: fixed;
  right: 20px;
  bottom: 70px;
  svg{
    width: 35px;
  }
}
</style>
