<template>
<div class="row flex-center form-ideation">
  <FormElement :title="$t('ideation.title')" :hint="$t('ideation.hint')">
    <p class="timer q-mt-xs" ref="timer"></p>
    <FormIdeation @completed="handleConfirmation" :language="store.state.language" />
  </FormElement>
  </div>
</template>


<script setup>
import FormElement from "../components/FormElement.vue";
import FormIdeation from "../components/FormIdeation.vue";
import {ideation as contents} from "../contents/profilation"

import { useStore } from "vuex";
import {  ref, watch, onMounted,onUnmounted } from "vue";
const store = useStore();
const emit = defineEmits(["completed"]);
const timer = ref()
const interval = ref()

function handleConfirmation(e) {
  console.log("game:set-project-description")
  // console.log("handled", e);
  store.commit("setProjectDescription", e);
  emit("completed");
}

function startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    interval.value = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            timer = 0;
        }
    }, 1000);
}

onMounted(()=>{
  setTimeout(()=>{
    startTimer(60*5, timer.value);
  },3000)
})

onUnmounted(()=>{
  if(interval.value){
    clearInterval(interval.value)
  }
})
</script>

<style lang="scss">
@import '../style/shared.scss';
  .form-ideation{
    @include max-responsive(sm){
      padding-top: 30px !important;
    }
    .games-hint{
      margin-bottom: 0px;
    }
  }
</style>
