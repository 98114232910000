import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal", "name": "Datos de perfil personal", "name_short": "Perfil personal", "description": "Género, etnicidad, creencias religiosas, orientación sexual, opiniones políticas, preferencias de música, preferencias de tv/películas o streaming, hábitos de uso del móvil, horario." },
      { "index": 2, "id":"2_health", "name": "Datos de salud", "name_short": "Salud", "description": "Estado físico y mental, información genética o biométrica, peso, IMC, estrés, acceso a hospitales u otras infraestructuras sanitarias." },
      { "index": 3, "id":"3_dietary", "name": "Datos de hábitos alimenticios", "name_short": "Hábitos alimenticios", "description": "Opciones de alimentos o bebidas, alergias e intolerancias, hábitos de fumar, uso de drogas, información nutricional, preferencias en cuanto a bares, restaurantes, y otros servicios." },
      { "index": 4, "id":"4_transactions", "name": "Datos de transacciones electrónicas", "name_short": "Trans electrónicas", "description": "CUso de tarjetas de crédito, banca por Internet, compras fuera de línea y en línea, información de consumo de productos." },
      { "index": 5, "id":"5_socialnetworks", "name": "Datos de redes sociales", "name_short": "Redes sociales", "description": "Uso personal de redes sociales, comunicación laboral, correo electrónico, aplicaciones, reuniones presenciales, apps de citas, plataformas de videollamadas." },
      { "index": 6, "id":"6_human", "name": "Datos de movilidad humana", "name_short": " Movilidad humana", "description": "Movilidad individual, uso de transporte público o privado, que incluye información de geolocalización, sello de tiempo, conteo de personas." },
      { "index": 7, "id":"7_animal", "name": "Datos de movilidad animal", "name_short": "Movilidad animal", "description": "Movilidad de mascotas o ganado, incluyendo  información de geolocalización, sello de tiempo, conteo de animales." },
      { "index": 8, "id":"8_vehicle", "name": "Datos de movilidad del vehículo", "name_short": "Movilidad del vehículo", "description": "Movilidad de vehículos (automóviles, bicicletas, scooters, buses, aviones), incluyendo información sobre geolocalización, sello de tiempo, conteo y características de los vehículos." },
      { "index": 9, "id":"9_utility", "name": "Datos de utilidad", "name_short": "Utilidad", "description": "Consumo de agua, electricidad y gas, hábitos de calefacción y refrigeración, hábitos de basura y reciclaje, teléfono fijo, wifi." },
      { "index": 10, "id":"10_environmental", "name": "Datos ambientales", "name_short": "Ambientales", "description": "Calidad del aire interior y exterior, temperatura, productos químicos, fuego, luz, contaminación acústica." },
      { "index": 11, "id":"11_infrastructure", "name": "Datos de infraestructura pública", "name_short": "Infraestructura", "description": "Puente, estado vial, mobiliario público, semáforos, mantenimiento de espacios públicos." },
      { "index": 12, "id":"12_greenery", "name": "Datos de vegetación", "name_short": "Vegetación", "description": "Árboles, parques, jardines, áreas agrícolas, cobertura y diversidad vegetal." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Ambientes de hogar", "description": "Aspectos de la vida cotidiana de las personas que contribuyen a su diario vivir. Por ejemplo, incluye espacios interiores y al aire libre, instituciones comunitarias, como hogares de ancianos y hogares grupales (es decir, orfanatos, hogares de ancianos, prisiones, centros de tratamiento y hogares de crianza)." },
      { "index": 2, "color":"#295966", "name": "Ambientes de trabajo", "description": "Aspectos relacionados a el entorno en el que trabajan los empleados o que impactan a los trabajadores, incluyendo características sociales y condiciones físicas. Estos aspectos pueden afectar el bienestar de los trabajadores, las relaciones laborales, la colaboración, la eficiencia y la salud de los empleados. Abarca desde una oficina en casa hasta un edificio de oficinas o una fábrica (al aire libre o bajo techo)." },
      { "index": 3, "color":"#8862A6", "name": "Espacios públicos", "description": "Lugares que generalmente están abiertos y accesibles para las personas, como por ejemplo: carreteras, plazas públicas, parques, playas, ciclovías, garajes, edificios gubernamentales, como bibliotecas públicas, instalaciones como ascensores, tiendas, restaurantes, centros comunitarios, instalaciones deportivas, salas de fiestas, teatros o salas de prensa." }
  ],
  discover:{
    blocks:[
      {
        title:'Los conocimientos',
        text:"Los datos se recopilan en todas partes. Las soluciones basadas en datos están cambiando la forma en que usamos los espacios públicos, los lugares de vida y de trabajo."
      },
      {
        title:"Misión",
        text:"Data Slots es un juego con una misión única: proponer soluciones basadas en datos que equilibren las preocupaciones y los beneficios de la privacidad."
      },
      {
        title:"El juego",
        subtitle:"Tarjeta de escenario",
        id:"scenario",
        text:"Elige al azar una tarjeta de escenario para proponer tus ideas."
      },
      {
        subtitle:"Tarjeta de datos",
        id:"data-cards",
        text:"Recibes tres tarjetas de datos aleatorias. Bloquea una carta, que conservas durante todo el juego. Luego, intercambie otras tarjetas de datos para construir sus activos."
      },
      {
        title:"Ideation",
        id:"ideation",
        text:"Utilice al menos 3 tarjetas de datos para proponer soluciones para su escenario."
      },
      {
        title:"Evaluación",
        id:"assessments",
        text:"Una vez que envíe su solución, Data Slots mostrará otras tres ideas creadas por otros jugadores. Evalúe las preocupaciones sobre la privacidad y los beneficios de cada idea."
      },
      {
        title:"Inversión",
        id:"investment",
        text:"Por último, invierta sus fichas en estas ideas."
      },
      {
        title:"",
        id:"scoreboard",
        text:"¡No te pierdas el marcador! Cree un apodo y realice un seguimiento de su clasificación a través del marcador."
      }
    ],
    contact:"<strong>Contacto:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Descargo de responsabilidad:</strong> las ranuras de datos no solicitan ningún dato personal. Los datos generados en Data Slots son anónimos y se utilizarán únicamente para investigación académica.",
    credits:"<strong>Equipo:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Sitio web:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Reconocimiento:</strong> Yuki Machida, Oluwatobi Oyinlola, y miembros de SCL, así como participantes en ciudades de todo el mundo que jugaron la versión en persona de Data Slots y nos ayudaron a mejorar el juego. Damos las gracias al consorcio SCL."
  },
  gameHints:{
    yourCards:"Tus cartas",
    cardsDeck:"Baraja de cartas",
    introText:'Un juego de 10 minutos sobre la ética y el valor de las soluciones basadas en datos.',
    introPlay:'Jugar el juego',
    introDiscover:'Descubrir',
    introScoreboard:'Marcador',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'Por favor seleccione el escenario', hint: 'Tu misión es diseñar una idea basada en datos para crear experiencias para uno de estos escenarios: espacio público, entorno de vida y de trabajo.', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: 'Tienes tres cartas. Bloquea uno para mantenerlo durante todo el juego', hint: 'Elíjalo tocando la tarjeta', icon: '' },
      level1: { message: 'Ahora elige la carta que quieres eliminar de tu mazo', hint: 'Toca la tarjeta', icon: '' },
      level2: { message: 'Ahora elige la carta que quieras en tu mazo', hint: 'Toca la tarjeta', icon: '' },
      level3: { message: 'Ahora elige otra carta que quieras eliminar de tu mazo', hint: 'Toca la tarjeta', icon: '' },
      level4: { message: 'Ahora elige la carta que quieras en tu mazo', hint: 'Toca la tarjeta' , icon: '' },
      level5: { message: 'Ahora elige tu última carta', hint: 'Toca la tarjeta', icon: '' },
      level6: { message: 'Estas son tus cartas finale', hint: 'Tap proceed to propose your idea' }
    },
    slot: {
        level0: { message: 'Tienes tres cartas. Bloquea uno para mantenerlo durante todo el juego.', hint: 'Sólo tiene que elegirlo haciendo clic en', icon: 'highlight_alt' },
        level1: { message: 'Ahora cambia una de tus cartas', hint: 'Arrastra una carta al mazo superior', icon: 'swipe_up' },
        level2: { message: 'Ahora cambia una carta más', hint: 'Arrastra una carta al mazo superior', icon: 'swipe_up' },
        level3: { message: 'Ahora elige tu última carta', hint: 'Arrastra una carta al mazo superior', icon: 'swipe_down' },
        level4: { message: 'Estas son tus cartas finale', hint: 'Toca "proceder" para proponer tu idea', }
    },
    sideButtons: {
        scanario: { label: 'scanario' },
        cards: { label: 'mis tarjetas' }
    },
    finalMessage: {
      playAgain:'Play Again',
      scoreboard:'Scoreboard',
      name: {
        hint: 'Tu nombre',
        error: 'Por favor escriba aquí',
        confirm:'Actualizar'
      },
      message: 'Gracias por jugar Data Slots',
      hint:"Este es tu apodo para unirte al marcador <br> Vuelva pronto y use su apodo para verificar la clasificación de tu idea"
    }
  },
  profilation:{
      title: 'Cuéntanos acerca de ti',
      hint: '',

      name: {
          hint: 'tu nombre',
          error: 'Escriba algo'
      },
      role: {
          hint: 'Tu rol',
          options: [
              'Académico',
              'Ciudadano',
              'Funcionaria de la ciudad'
          ],
          error: 'Escriba algo'

      },
      region: { hint: 'Dónde estás jugando', options: regions,error: 'Escriba algo' },
      age: {
          hint: 'Su edad',
          options: [
              'menores de 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'mayores de 65'
          ],
          error: 'Escriba algo'

      },
      gender: {
          hint: 'Tus pronombres',
          options: [
              'Ella',
              'él',
              'Ellos',
              'déjame escribir',
              'prefiero no decir'
          ],
          error: 'Escriba algo'
      },
      customGender: {
          hint: 'Tus pronombres',
          error: 'Escriba algo'

      },
      confirm: 'Proceder'
  },
  ideation:{
      title: 'La ideación',
      hint: '¿Qué idea/experiencia sugerirías para tu escenario combinando al menos 3 de tus cartas? <br>Tienes 5 minutos.',
      projectTitle: {
          hint: 'Título del Proyecto',
          error: 'Escriba algo'
      },
      name: {
          hint: 'Su nombre',
          error: 'Escriba algo'
      },
      projectCaption: {
          hint: 'Reclamo de proyecto',
          error: 'Escriba algo'
      },
      projectDescription: {
          hint: 'Breve descripción del proyecto',
          error: 'Escriba algo'
      },
      projectRatings:{
        translation:"* Algunos de los proyectos se traducen automáticamente desde su idioma original.",
        ratings:{
          hint:'Califique las ideas',
          subtitle:'invasividad &#8594; preocupaciones de privacidad sobre el uso de datos en cada idea <br> Beneficios &#8594; beneficios creados por cada idea'
        },
        investments:{
          hint:'Ahora eres el inversionista',
          subtitle:'Tienes diez monedas. Arrastra las monedas para invertir en estas ideas',
          icon:'swipe_up'
        }
      },
      read_more:'Lee mas',
      confirm: 'Enviar',
      invasiveness:'invasividad',
      benefit:'Beneficio',
      high:'Alto',
      low:'Bajo'
  },
  scoreboard:{
    search:'Buscar',
    name:'Nickname',
    position:'Clasificación',
    title:'Título',
    country: 'País',
    score: 'Puntaje',
    ratings:'Calificaciones'
  }
}
