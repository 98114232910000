<template>
  <div class="scoreboard-page">

    <Socials/>

    <div class="scoreboard-back">
      <a @click.prevent="$router.replace('/')">
        <svg width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
         <path d="m420 140h-223.77l53.668-53.434h-0.003906c5.9219-5.918 8.2305-14.547 6.0664-22.629-2.168-8.0859-8.4844-14.402-16.566-16.566-8.0859-2.168-16.715 0.14453-22.633 6.0625l-93.332 93.332c-2.125 2.2188-3.7891 4.8359-4.8984 7.6992-2.3359 5.6836-2.3359 12.055 0 17.734 1.1094 2.8633 2.7734 5.4805 4.8984 7.6992l93.332 93.332v0.003906c4.3828 4.418 10.348 6.9023 16.566 6.9023 6.2227 0 12.188-2.4844 16.566-6.9023 4.418-4.3828 6.9023-10.348 6.9023-16.566 0-6.2227-2.4844-12.188-6.9023-16.566l-53.664-53.434h223.77c41.68 0 80.195 22.234 101.04 58.332s20.84 80.57 0 116.67c-20.84 36.094-59.355 58.332-101.04 58.332h-280c-8.3359 0-16.039 4.4492-20.207 11.668s-4.168 16.113 0 23.332 11.871 11.668 20.207 11.668h280c58.352 0 112.27-31.133 141.45-81.668 29.18-50.535 29.18-112.8 0-163.33-29.176-50.535-83.098-81.668-141.45-81.668z"/>
        </svg>
      </a>
    </div>
    <div class="scoreboard-content">

      <h1 class="text-h1 text-bold text-primary q-mb-0">
        {{$t('gameHints.introScoreboard')}}
      </h1>

      <div class="scoreboard__search">
        <q-input
          square
          outlined
          v-model="query"
          :label="$t('scoreboard.search')"
        />
      </div>


      <table class="scoreboard__list">
        <tr class="scoreboard__header">
          <th>{{$t('scoreboard.position')}}</th>
          <th>{{$t('scoreboard.name')}}</th>
          <th>{{$t('scoreboard.title')}}</th>
          <th>{{$t('scoreboard.country')}}</th>
          <th>{{$t('scoreboard.score')}}</th>
          <th>{{$t('scoreboard.ratings')}}</th>
        </tr>
        <tr class="scoreboard__item" v-for="(project,index) in usersProjects" :key="project.id" :class="{'match':project.match}">
          <td>{{index + 1}}</td>
          <td>{{project.nickname || '-'}}</td>
          <td>{{project.title}}</td>
          <td>{{project.region}}</td>
          <td>{{String(parseFloat(project.investmentsAvg).toFixed(1)).replace('.0','')}}</td>
          <td>{{project.investments ? project.investments.length: '-' }}</td>
        </tr>

        <Transition name="fade" tag="div">
          <tr v-if="matchingProject" class="spacer">
            <svg width="20px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
              <path d="m350 435.55c34.363 0 62.223 27.859 62.223 62.223 0 34.363-27.859 62.223-62.223 62.223s-62.223-27.859-62.223-62.223c0-34.363 27.859-62.223 62.223-62.223zm0-217.78c34.363 0 62.223 27.859 62.223 62.223s-27.859 62.223-62.223 62.223-62.223-27.859-62.223-62.223 27.859-62.223 62.223-62.223zm0-217.78c34.363 0 62.223 27.859 62.223 62.223 0 34.363-27.859 62.223-62.223 62.223s-62.223-27.859-62.223-62.223c0-34.363 27.859-62.223 62.223-62.223z" fill-rule="evenodd"/>
            </svg>
          </tr>
        </Transition>

        <Transition name="fade" tag="div">
          <tr v-if="matchingProject" class="scoreboard__item result">
            <td>{{matchingProject.position}}</td>
            <td>{{matchingProject.nickname || '-'}}</td>
            <td>{{matchingProject.title}}</td>
            <td>{{matchingProject.region}}</td>
            <td>{{String(matchingProject.investmentsAvg ? parseFloat(matchingProject.investmentsAvg).toFixed(1) : 0).replace('.0','')}}</td>
            <td>{{matchingProject.investments ? matchingProject.investments.length: '-' }}</td>
          </tr>
        </Transition>

      </table>


    </div>
  </div>
</template>

<script setup>
import Socials from "../components/Socials.vue";
import LanguageSelector from "../components/LanguageSelector.vue";
import {translations as contents} from "../contents/scoreboard"
import { useStore } from "vuex";
import { ref, inject, watch } from 'vue';
const store = useStore()

const $firebase = inject('firebase')

const query =ref('')
const usersProjects = ref([])
const matchingProject = ref(null)
let timeout = null

watch(query, (val, prevVal) => {

  if(timeout){
    clearTimeout(timeout)
  }

  if(!query.value){
    matchingProject.value = null
    usersProjects.value.forEach((p, i) => {
      p.match = false;
    });
    return
  }

  timeout = setTimeout(()=>{
    $firebase.searchProject('nickname_lower',query.value.toLowerCase()).then((projects)=>{
      if(projects && projects[0]){
        const project = projects[0]
        const projectData = project.data()
        let matchCurrent = false;
        matchingProject.value = null

        usersProjects.value.forEach((p, i) => {
          const match = query.value && (p.title ? p.title.toLowerCase().includes(query.value.toLowerCase()) : false) || (p.nickname ? p.nickname.toLowerCase().includes(query.value.toLowerCase()) : false);
          p.match = match;
        });

        //&& usersProjects.value.every((p)=>p.id !== project.id)
        if(projectData && usersProjects.value.every((p)=>p.id !== project.id) && projectData.nickname_lower.includes(query.value.toLowerCase())){
          matchingProject.value = projectData;

          $firebase.getRank(projectData.investmentsAvg || 0,project.id).then((projectsBefore)=>{
            matchingProject.value.position = projectsBefore + 1
          })
        }
      }
      else{
        matchingProject.value = null
      }
    })
  },1000)

})

$firebase.getScoreboardProjects().then((projects)=>{
  usersProjects.value = projects.map((p,i)=>Object.assign(p.data(),{id:p.id,index:i})).sort((a,b)=>{
    if( a.investmentsAvg < b.investmentsAvg){
      return 1
    }
    if( a.investmentsAvg > b.investmentsAvg){
      return -1
    }
    return 0
  })
})


</script>

<style lang="scss">
@import '../style/shared.scss';

.scoreboard-page{
  position: relative;
  overflow: hidden;

  .socials{
      bottom:20px;
  }

  @include max-responsive(sm){
    padding-bottom: 60px;
    .global_language_button{
      bottom: 20px;
    }
    .text-h1{
      font-size: 2rem;
    }
    td,th{
      font-size: .8rem;
    }
    th{
      font-size: .6rem;
      &:nth-of-type(1){
        opacity: 0;
      }
    }
    td,th{
      &:nth-of-type(1){
        width:20px !important; 
      }
      &:nth-of-type(2){
        width:65px !important; 
      }
      &:nth-of-type(4),&:nth-of-type(5),&:nth-of-type(6){
        font-size: .65rem;
      }
      &:last-of-type{
        width: 20px !important;
      }
    }
  }

  .spacer{
    padding-top: 20px;
    justify-content: center !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-leave-active {
    position: relative;
  }

  .scoreboard__search{
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .scoreboard-content{
    max-width: 800px;
    margin: 0 auto;
    padding: 60px 20px;

    .text-h1{
      margin-bottom: 10px
    }
  }

  .scoreboard__list{
    // display: flex;
    // flex-direction: column;
    // gap:10px;
    table-layout: fixed ;
    width: 100%;

    tr{
      display: flex;
      justify-content: space-between;
    }
    td,th{
      width: 10%;

      &:first-of-type{
        width: 60px;
      }
      &:nth-of-type(2){
        width: 150px;
      }
      &:nth-of-type(3){
        width: 200px;
      }
      &:nth-of-type(5){
        text-align: right;
      }
      &:last-of-type{
        text-align: right;
      }
    }
    .scoreboard__header{
      width: 100%;
    }
    .scoreboard__item{
      width: 100%;
      padding: 15px 5px;
      border-bottom: solid 1px rgb(205, 205, 205);
      transition: all .4s;

      &.result{
        border-top: solid 1px rgb(205, 205, 205);
        margin-top: 20px;
      }

      &.match{
        background:rgba(0, 0, 0, 0.04);
      }
    }
    .scoreboard__item,.scoreboard__header{
      text-align: left;
    }
  }

  .scoreboard-back{
    position: fixed;
    left: 30px;
    top: 30px;
    border: solid 1px $primary;
    background: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    svg{
      width: 30px;
      height: 30px;
      transform: translate(2px,5px);
      path{
        fill:$primary;
      }
    }
  }

}
</style>
