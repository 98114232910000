<template >
  <div class="game-progress" v-if="gameLevel > 1">
    <div class="step" v-for="index in 8" :key="index" :class="{'done':level >= index}">
    </div>
  </div>
</template>

<script setup>
import Emitter from '../utils/Emitter'
import {onMounted,ref, onUnmounted, computed} from 'vue'
const props = defineProps(["gameLevel"]);
const internalLevel = ref()
const lastStep = ref(0)

const switchInternalLevel = (val)=>{
  internalLevel.value = val
}
const switchLastStep = (val)=>{
  lastStep.value = val
}

const level = computed(()=>{
  return props.gameLevel - 1  + (internalLevel.value ? internalLevel.value < 4 ? internalLevel.value : 3 :  0) + lastStep.value
})

onMounted(()=>{
  Emitter.on('internalLevel', switchInternalLevel)
  Emitter.on('lastStep', switchLastStep)
})
onUnmounted(()=>{
  Emitter.off('internalLevel', switchInternalLevel)
  Emitter.off('lastStep', switchLastStep)
})
</script>


<style lang="scss">
@import '../style/shared.scss';
  .game-progress{
    position: fixed;
    bottom: 30px;
    left: 50%;
    display: flex;
    gap:15px;
    align-items: center;
    justify-content: center;
    @include responsive(sm){
      bottom: inherit;
      top: 30px;
      right: 30px;
      left: inherit;
    }
    @include max-responsive(sm){
      transform: translate(-50%,0);
      position:absolute;
    }

    // &:before{
    //   content: "";
    //   display: inline-block;
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   transform: translate(0,-50%);
    //   width: 100%;
    //   height: 1px;
    //   background: #DFE2E3;
    //   z-index: 1;
    // }

    .step{
      position: relative;
      z-index: 2;
      width: 13px;
      height: 13px;
      border-radius: 100%;
      background: #DFE2E3;

      &.done{
        background: #7F898E;
      }
    }
  }
</style>
