import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

const profilation = {
    en: {
        title: 'Tell us about you',
        hint: '',

        name: {
            hint: 'Your name',
            error: 'Please type something'
        },
        role: {
            hint: 'Your role',
            options: [
                'Academic',
                'Citizen',
                'City official'
            ],
            error: 'Please select something'

        },
        region: { hint: 'where are you playing', options: regions },
        age: {
            hint: 'Your age',
            options: [
                'under 16',
                '16-25',
                '26-35',
                '36-45',
                '46-55',
                '56-65',
                'over 65'
            ],
            error: 'Please select something'

        },
        gender: {
            hint: 'Your pronouns',
            options: [
                'She/her',
                'He/him',
                'They/them',
                'Let me type',
                'I prefer not to say'
            ],
            error: 'Please select something'
        },
        customGender: {
            hint: 'Your pronouns',
            error: 'Please type something'

        },
        confirm: 'Proceed'
    }

}

const ideation = {
    en: {
        title: 'The ideation',
        hint: 'Which idea/experience would you suggest for your scenario combining at least 3 of your cards? <br>You have 5 minutes.',
        projectTitle: {
            hint: 'Project title',
            error: 'Please type something'
        },
        name: {
            hint: 'Your name',
            error: 'Please type something'
        },
        projectCaption: {
            hint: 'Project caption',
            error: 'Please type something'
        },
        projectDescription: {
            hint: 'Project short description',
            error: 'Please type something'
        },
        projectRatings:{
          ratings:{
            hint:'Rate the different ideas in terms of invasiveness and benefits',
            subtitle:'Invasiveness &#8594; privacy concerns about the use of data in each idea <br> Benefits &#8594; benefits created by each idea'
          },
          investments:{
            hint:'Now you are the investor',
            subtitle:'You have ten coins. Drag the coins to invest in these ideas',
            icon:'swipe_up'
          }
        },
        read_more:'Read more',
        confirm: 'Submit',
        invasiveness:'Invasiveness',
        benefit:'Benefit',
        high:'High',
        low:'Low'
    }
}

export { profilation, ideation };
