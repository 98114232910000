import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "بيانات الملف الشخصي", "description": " الجنس ، والعرق ، والمعتقدات الدينية ، والتوجه الجنسي ، والآراء السياسية ، والموسيقى ، والتلفزيون / الأفلام أو تفضيلات البث ، وعادات استخدام الهاتف المحمول ، والجدول الزمني." },
      { "index": 2, "id":"2_health", "name": "بيانات الصحة", "description": "خيارات الأطعمة أو المشروبات ، الحساسية وعدم تحمل ، عادات التدخين ، تعاطي المخدرات ، معلومات التغذية ، التفضيلات من حيث الحانات والمطاعم والمرافق." },
      { "index": 3, "id":"3_dietary", "name": "بيانات العادات الغذائية", "description": " خيارات الأطعمة أو المشروبات ، الحساسية وعدم تحمل ، عادات التدخين ، تعاطي المخدرات ، معلومات التغذية ، التفضيلات من حيث الحانات والمطاعم والمرافق." },
      { "index": 4, "id":"4_transactions", "name": "بيانات المعاملات الإلكترونية", "description": "استخدام بطاقة الائتمان ، والخدمات المصرفية عبر الإنترنت ، وعمليات الشراء خارج الإنترنت وعبر الإنترنت ، ومعلومات استهلاك المنتج." },
      { "index": 5, "id":"5_socialnetworks", "name": "بيانات الشبكات الاجتماعية", "description": " الاستخدام الشخصي للشبكات الاجتماعية ، واتصالات العمل ، والبريد الإلكتروني ، والتطبيقات ، والاجتماعات الشخصية ، وتطبيقات المواعدة ، ومنصات مكالمات الفيديو." },
      { "index": 6, "id":"6_human", "name": "بيانات التنقل البشري", "description": "التنقل الفردي ، واستخدام وسائل النقل الخاصة أو العامة ، مع معلومات حول تحديد الموقع الجغرافي ، والطابع الزمني ، وعدد الأشخاص." },
      { "index": 7, "id":"7_animal", "name": "بيانات تنقل الحيوانات", "description": " تنقل الحيوانات الأليفة أو الماشية ، مع معلومات حول تحديد الموقع الجغرافي والطابع الزمني وعدد الحيوانات." },
      { "index": 8, "id":"8_vehicle", "name": "بيانات تنقل السيارة", "description": "تنقل المركبات (السيارات والدراجات والدراجات البخارية والحافلات والطائرات) ، مع معلومات حول تحديد الموقع الجغرافي والطابع الزمني والعدد وميزات المركبات." },
      { "index": 9, "id":"9_utility", "name": "بيانات المرافق", "description": "استهلاك المياه والكهرباء والغاز ، وعادات التدفئة والتبريد ، وعادات القمامة وإعادة التدوير ، والهاتف الأرضي ، والواي فاي." },
      { "index": 10, "id":"10_environmental", "name": "بيانات البيئة", "description": "جودة الهواء الداخلي والخارجي ، درجة الحرارة ، الكيماويات ، الحريق ، الضوء ، التلوث الضوضائي." },
      { "index": 11, "id":"11_infrastructure", "name": "بيانات البنية التحتية العامة", "description": "الجسر ، حالة الطرق ، الأثاث العام ، إشارات المرور ، صيانة الأماكن العامة." },
      { "index": 12, "id":"12_greenery","name": "بيانات المساحات الخضراء", "description": "الأشجار والمتنزهات والحدائق والمناطق الزراعية والمظلة الخضراء والتنوع النباتي." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "بيئة المنزل", "description": "جوانب الحياة المنزلية التي تساهم في الظروف المعيشية للناس. المساحات الخارجية والداخلية ، والمؤسسات المجتمعية السكنية التي يمكن للناس العيش فيها ، مثل دور رعاية المسنين ، والمنازل الجماعية (دور الأيتام للأطفال ، ودور المسنين لكبار السن ، والسجون ، ومرافق العلاج ، ودور الحضانة." },
      { "index": 2, "color":"#295966", "name": "بيئة العمل", "description": "العناصر التي تشتمل على البيئة التي يعمل فيها الموظفون وتؤثر على العمال ، مثل السمات الاجتماعية والظروف المادية ، ويمكن أن تؤثر هذه الجوانب على مشاعر الرفاهية والعلاقات في مكان العمل والتعاون والكفاءة وصحة الموظف. وهي تتراوح من مكتب منزلي إلى مبنى مكتبي أو مصنع (خارجي أو داخلي)." },
      { "index": 3, "color":"#8862A6", "name": "مكان عام", "description": " مكان مفتوح بشكل عام ويمكن الوصول إليه من قبل الناس مثل الطرق والساحات العامة والمتنزهات والشواطئ ومسارات الدراجات والجراجات والمباني الحكومية مثل المكتبات العامة والمرافق مثل المصاعد والمتاجر والمطاعم والمراكز المجتمعية والمرافق الرياضية وغرف الحفلات أو غرف المسرح أو الوسائط." }
  ],
  discover:{
    blocks:[
      {
        title:'خلفية',
        text:"يتم جمع البيانات في كل مكان. الحلول المدعومة بالبيانات تغير كيف نستخدم الأماكن العامة والمنازل وأماكن العمل."
      },
      {
        title:"مهمة",
        text:"ي لعبة بمهمة فريدة: تقديم حلول مدعومة بالبيانات التي توازن الخصوصية ومزاياها."
      },
      {
        title:" اللعبة",
        subtitle:"بطاقة السيناريو.",
        id:"scenario",
        text:"اختر بطاقة سيناريو عشوائيًا لاقتراح أفكارك."
      },
      {
        subtitle:"بطاقات البيانات",
        id:"data-cards",
        text:"تتلقى ثلاث بطاقات بيانات عشوائية. قم بقفل بطاقة واحدة ، واحتفظ بها طوال اللعبة. بعد ذلك ، قم بتبادل بطاقات البيانات لبناء الأصول الخاصة بك."
      },
      {
        title:"التفكير ",
        id:"ideation",
        text:"استخدم 3 بطاقات بيانات على الأقل لاقتراح حلول للسيناريو الخاص بك."
      },
      {
        title:"تقدير",
        id:"assessments",
        text:"بمجرد إرسال الحل الخاص بك ، Data Slots ستعرض ثلاثة أفكار أخرى تم إنشاؤها بواسطة لاعبين آخرين. قيم خصوصية وفوائد كل فكرة."
      },
      {
        title:"استثمار",
        id:"investment",
        text:"خيرًا ، استثمر عملاتك في هذه الأفكار."
      },
      {
        title:"",
        id:"scoreboard",
        text:"لا تفوت لوحة النتائج! قم بإنشاء اسم مستعار وتتبع ترتيبك من خلال لوحة النتائج."
      }
    ],
    contact:"<strong>اتصال:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"Data Slots  لا تطلب أي بيانات شخصية. البيانات التي تم إنشاؤها في Data Slots مجهولة الهوية وسيتم استخدامها للبحث العلمي فقط. <strong>كن حذرا:</strong>",
    credits:"<strong>فريق:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>موقع إلكتروني:</strong> فريق+ Paul Bouisset, Pietro Leoni<br><strong>Acknowledgment:</strong> Yuki Machida, Oluwatobi Oyinlola, وأعضاء SCL ، بالإضافة إلى المشاركين في المدن حول العالم الذين لعبوا  النسخة من Data Slots شخصيا وساعدونا في تحسين اللعبة. نشكر اتحاد SCL."
  },
  gameHints:{
    yourCards:"بطاقاتك",
    cardsDeck:"أوراق اللعب",
    introText:'لعبة مدتها 10 دقائق حول أخلاقيات وقيمة الحلول المبنية على البيانات.',
    introPlay:'العب اللعبة',
    introDiscover:'اكتشف',
    introScoreboard:'لوحة الدرجات',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'الرجاء تحديد السيناريو', messageMobile:'انقر لتحديد سيناريو عشوائي',hint: 'تتمثل مهمتك في تصميم فكرة تعتمد على البيانات لإنشاء تجارب لأحد هذه السيناريوهات: لأماكن العامة وأماكن المعيشة و العمل.', icon: 'highlight_alt' },
    slotMobile:{
        level0: { message: 'لديك ثلاث بطاقات. قفل واحدة لإبقائها على طول اللعبة بأكملها', hint: ' فقط اخترها بنقر البطاقة', icon: '' },
        level1: { message: 'اختر الآن البطاقة التي تريد إزالتها من مجموعتك', hint: 'انقر البطاقة', icon: '' },
        level2: { message: 'اختر الآن البطاقة التي تريدها في مجموعتك', hint: 'انقر البطاقة', icon: '' },
        level3: { message: 'اختر الآن بطاقة أخرى تريد إزالتها من مجموعتك', hint: 'انقر البطاقة', icon: '' },
        level4: { message: 'اختر الآن البطاقة التي تريدها في مجموعتك', hint: 'انقر البطاقة' , icon: '' },
        level5: { message: 'الآن اختر بطاقتك الأخيرة', hint: 'انقر البطاقة', icon: '' },
        level6: { message: 'هذه هي بطاقتك النهائية', hint: 'انقر "تقدم" لاقتراح فكرتك' }
      },
    slot: {
        level0: { message: 'لديك ثلاث بطاقات. قفل واحدة لإبقائها على طول اللعبة بأكملها', hint: 'فقط اختره بالنقر', icon: 'highlight_alt' },
        level1: { message: 'الآن قم بتبديل إحدى بطاقاتك', hint: 'اسحب بطاقة واحدة إلى السطح العلوي', icon: 'swipe_up' },
        level2: { message: 'الآن قم بتبديل بطاقة أخرى', hint: 'اسحب بطاقة واحدة إلى السطح العلوي', icon: 'swipe_up' },
        level3: { message: 'الآن اختر بطاقتك الأخيرة', hint: 'اسحب بطاقة واحدة من المجموعة العلوية', icon: 'swipe_down' },
        level4: { message: 'هذه هي أوراقك النهائية', hint: 'انقر فوق "متابعة" لاقتراح فكرتك', }
    },
    sideButtons: {
        scanario: { label: 'scanario' },
        cards: { label: 'بطاقاتي' }
    },
    finalMessage: {
        playAgain:'العب مرة أخرى',
      scoreboard:'لوحة الدرجات',
      name: {
        hint: 'اسمك',
        error: 'الرجاء كتابة شيء ما',
        confirm:'تحديث'
      },
      message: 'شكرًا لك على لعب Data Slots',
      hint:"هذا هو لقبك الخاص بك للانضمام إلى لوحة النتائج<br>عد قريبًا واستخدم لقبك للتحقق من ترتيب فكرتك"
    }
  },
  profilation:{
      title: 'أخبرنا عن نفسك',
      hint: '',

      name: {
          hint: 'اسمك',
          error: 'الرجاء كتابة شيء ما'
      },
      role: {
          hint: 'دورك',
          options: [
              'أكاديمي',
              'مواطن',
              'مسؤول المدينة'
          ],
          error: 'الرجاء تحديد شيء ما'

      },
      region: { hint: 'أين تلعب', options: regions,error: 'الرجاء تحديد شيء ما' },
      age: {
          hint: 'عمرك',
          options: [
              'تحت سن 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'فوق 65'
          ],
          error: 'الرجاء تحديد شيء ما'

      },
      gender: {
          hint: 'ضمائركم',
          options: [
              'هي',
              'هو',
              'هم',
              'اسمحوا لي أن أكتب',
              'أنا أفضل ألا أقول'
          ],
          error: 'الرجاء تحديد شيء ما'
      },
      customGender: {
          hint: 'ضمائركم',
          error: 'الرجاء كتابة شيء ما'

      },
      confirm: 'تقدم'
  },
  ideation:{
      title: 'التفكير',
      hint: 'ما هي الفكرة / التجربة التي تقترحها على السيناريو الخاص بك الذي يجمع بين 3 بطاقات على الأقل؟<br>لديك 5 دقائق.',
      projectTitle: {
          hint: 'عنوان المشروع',
          error: 'الرجاء كتابة شيء ما'
      },
      name: {
          hint: 'اسمك',
          error: 'الرجاء كتابة شيء ما'
      },
      projectCaption: {
          hint: 'مطالبة المشروع',
          error: 'الرجاء كتابة شيء ما'
      },
      projectDescription: {
          hint: 'وصف موجز للمشروع',
          error: 'الرجاء كتابة شيء ما'
      },
      projectRatings:{
        translation:"* تتم ترجمة بعض المشاريع تلقائيًا من لغتها الأصلية",
        ratings:{
          hint:'قيم الأفكار',
          subtitle:'الغازية &#8594; اهتمامات الخصوصية بشأن استخدام البيانات في كل فكرة <br> فوائد &#8594; فوائد تم إنشاؤها بواسطة كل فكرة'
        },
        investments:{
          hint:'أنت الآن المستثمر',
          subtitle:'لديك عشر عملات. اسحب القطع النقدية للاستثمار في هذه الأفكار',
          icon:'swipe_up'
        }
      },
      read_more:'اقرء المزيد',
      confirm: 'قدم',
      invasiveness:'الغازية',
      benefit:'فوائد',
      high:'عالٍ',
      low:'قليل'
  },
  scoreboard:{
    search:'ابحث',
    name:'لقب ',
    position:'تصنيف',
    title:'عنوان',
    country: 'دولة',
    score: 'نتيجة',
    ratings:'التقييمات'
  }
}