<template>
  <div class="language-selector" :class="{'visible':visible}">
    <div class="q-mb-md">
      <q-btn rounded unelevated color="primary" size="large" v-for="locale in locales.filter((l)=>l.code =='en')" :key="locale.code" @click="setLanguage(locale)">
        {{locale.name}}
      </q-btn>
    </div>
    <p class="q-mt-md q-mb-xs">* beta versions</p>
    <div class="language-selector__list">
      <q-btn rounded unelevated outline color="primary" size="large" v-for="locale in locales.filter((l)=>l.code !=='en')" :key="locale.code" @click="setLanguage(locale)">
        {{locale.name}}
      </q-btn>
    </div>
  </div>
  <button class="global_language_button" type="button" name="button" @click="visible = true"><svg xmlns="http://www.w3.org/2000/svg" width="16.672" height="16.672" viewBox="0 0 16.672 16.672">
    <path id="Tracé_3603" data-name="Tracé 3603" d="M8.336-17A8.345,8.345,0,0,0,0-8.664,8.345,8.345,0,0,0,8.336-.328a8.345,8.345,0,0,0,8.336-8.336A8.345,8.345,0,0,0,8.336-17ZM14-12.453H11.727A10.712,10.712,0,0,0,10.855-15,6.86,6.86,0,0,1,14-12.453ZM8.336-15.484c.6,0,1.377,1.115,1.849,3.031h-3.7C6.959-14.37,7.736-15.484,8.336-15.484ZM1.906-6.391a6.792,6.792,0,0,1-.39-2.273,6.792,6.792,0,0,1,.39-2.273h2.78a18.605,18.605,0,0,0-.139,2.273,18.605,18.605,0,0,0,.139,2.273Zm.762,1.516H4.944a10.713,10.713,0,0,0,.873,2.549A6.861,6.861,0,0,1,2.668-4.875Zm2.277-7.578H2.668A6.86,6.86,0,0,1,5.817-15,10.713,10.713,0,0,0,4.945-12.453ZM8.336-1.844c-.6,0-1.377-1.115-1.849-3.031h3.7C9.713-2.958,8.936-1.844,8.336-1.844Zm2.13-4.547H6.206a17.561,17.561,0,0,1-.143-2.273,17.561,17.561,0,0,1,.143-2.273h4.261a17.526,17.526,0,0,1,.143,2.273A17.56,17.56,0,0,1,10.466-6.391Zm.388,4.065a10.712,10.712,0,0,0,.873-2.549H14A6.859,6.859,0,0,1,10.855-2.326Zm4.3-6.338a6.792,6.792,0,0,1-.39,2.273h-2.78a18.606,18.606,0,0,0,.139-2.273,18.606,18.606,0,0,0-.139-2.273h2.78A6.792,6.792,0,0,1,15.156-8.664Z" transform="translate(0 17)" fill="#000000"/>
  </svg>
  Languages
  </button>
</template>

<script setup>
import {localeList} from '../plugins/i18n.js'
import { ref } from "vue";
import Cookies from 'js-cookie'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n();
const locales = ref(localeList)

const visible = ref(true);

defineExpose({visible})


const languagePreference = Cookies.get('i18n_locale')

if(languagePreference){
  locale.value = languagePreference;
  visible.value = false;
}


function setLanguage(l){
  Cookies.set('i18n_locale',l.code)
  locale.value = l.code;
  visible.value = false
}
// import { global } from 'vue-i18n'
// const switchLocale = (locale) => {
//   if(global){
//     global.locale.value = 'en'
//   }
// }

</script>

<style lang="scss">
@import '../style/shared.scss';

.language-selector{
  position: absolute;
    z-index: 3;
    left: 50%;
    top: 0;
    background: white;
    width: 600px;
    height: 100%;
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%, 0);
    button{
        font-size: .9rem !important;
      }
    @include max-responsive(sm){
      width: 100%;
      button{
        font-size: .9em !important;
      }
    }

  .language-selector__list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:10px;
    @include max-responsive(sm){
      font-size: .9em;
    }

  }

  &.visible{
    opacity: 1;
    pointer-events: auto;
  }

}
.global_language_button{
  all:unset;
  background: white;
  border: solid 1px rgba(0,0,0,0.2);
  border-radius: 4px;
  padding: 5px  10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include max-responsive(sm){
    bottom: 60px;
    right: inherit;
    left: 50%;
    transform: translate(-50%, 0);
  };
  svg{
    margin-right: 10px;
  }
}
</style>
