import { createI18n } from 'vue-i18n'

import en from '../lang/en.js'
import fr from '../lang/fr.js'
import es from '../lang/es.js'
import it from '../lang/it.js'
import pt from '../lang/pt.js'
import ko from '../lang/ko.js'
import zh from '../lang/zh.js'
import hi from '../lang/hi.js'
import ja from '../lang/ja.js'
import de from '../lang/de.js'
import ru from '../lang/ru.js'
import ar from '../lang/ar.js'

export const localeList = [
  {
    code:'en',
    name:'English'
  },{
    code:'es',
    name:'Spanish'
  },{
    code:'it',
    name:'Italian'
  },{
    code:'fr',
    name:'French'
  },{
    code:'pt',
    name:'Portuguese'
  },{
    code:'ko',
    name:'Korean'
  },{
    code:'zh',
    name:'Chinese'
  },{
    code:'hi',
    name:'Hindi'
  },{
    code:'ja',
    name:'Japanese'
  },{
    code:'de',
    name:'German'
  },{
    code:'ru',
    name:'Russian'
  },{
    code:'ar',
    name:'Arabic'
  }
]
export const i18n = createI18n({
  locale:'en',
  legacy: false,
  fallbackLocale:'en',
  messages:{
    en,
    es,
    it,
    fr,
    pt,
    ko,
    zh,
    hi,
    ja,
    de,
    ru,
    ar
  },
  specialCharacters: {
    linked: '~@',
  }
})
