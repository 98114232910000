import { countries } from "countries-list";

const regions = Object.values(countries).map((c) => c.name).sort();

export default {
  dataCards:[
      { "index": 1, "id":"1_personal" , "name": "Personal profile data","name_short": "Personal profile", "description": "Gender, ethnicity, religious beliefs, sexual orientation, political opinions, music, tv/movies or streaming preferences, mobile usage habits, schedule." },
      { "index": 2, "id":"2_health", "name": "Health data","name_short": "Health", "description": "Physical and mental condition, genetic or biometric information, weight, BMI, stress, access to health infrastructures." },
      { "index": 3, "id":"3_dietary", "name": "Dietary habits data", "name_short": "Dietary habits","description": "Food or beverage choices, allergies and intolerances, smoking habits, drugs usage, nutrition information, preferences in terms of bars, restaurants, amenities." },
      { "index": 4, "id":"4_transactions", "name": "Electronic transaction data", "name_short": "Elect transaction", "description": "Credit card usage, internet banking, offline and online purchases, product consumption information." },
      { "index": 5, "id":"5_socialnetworks", "name": "Social networking data", "name_short": "Social networking", "description": "Personal use of social networks, work communication, email, applications, in-person meetings, dating apps, video call platforms." },
      { "index": 6, "id":"6_human", "name": "Human mobility data","name_short": "Human mobility", "description": "Individual mobility, usage of private or public transportation, with information about geolocalization, timestamp, count of people." },
      { "index": 7, "id":"7_animal", "name": "Animal mobility data", "name_short": "Animal mobility", "description": "Pet or livestock mobility, with information about geolocalization, timestamp, count of animals." },
      { "index": 8, "id":"8_vehicle", "name": "Vehicle mobility data", "name_short": "Vehicle mobility", "description": "Vehicles’ mobility (cars, bikes, scooters, buses, airplanes), with information about geolocalization, timestamp, count and vehicles’ features." },
      { "index": 9, "id":"9_utility", "name": "Utility data", "name_short": "Utilities", "description": "Consumption of water, electricity, and gas, heating and cooling habits, trash and recycling habits, landline phone, wifi." },
      { "index": 10, "id":"10_environmental", "name": "Environmental data", "name_short": "Environment", "description": "Indoor and outdoor air quality, temperature, chemicals, fire, light, noise pollution." },
      { "index": 11, "id":"11_infrastructure", "name": "Infrastructure data", "name_short": "Infrastructure", "description": "Bridge, road condition, public furniture, traffic lights, maintenance of public spaces." },
      { "index": 12, "id":"12_greenery","name": "Greenery data", "name_short": "Greenery", "description": "Trees, parks, gardens, agricultural areas, green canopy and plant diversity." }
  ],
  scenarioCards :[
      { "index": 1, "color":"#BA562C", "name": "Home environment", "description": "Aspects of people's domestic lives that contribute to their living conditions. Are included outdoor and indoor spaces, private houses but also residential community institutions, such as nursing homes, group homes such as orphanages, retirement homes, prisons, treatment facilities, and foster homes." },
      { "index": 2, "color":"#295966", "name": "Work environment", "description": "Elements that comprise the setting in which employees work and impact workers or students conduct their routines, as social features and physical conditions.These aspects can impact feelings of wellbeing, workplace relationships, collaboration, efficiency and employee health. It ranges from a home office to an office building, an university campus or factory (outdoor or indoor)." },
      { "index": 3, "color":"#8862A6", "name": "Public space", "description": "A place that is generally open and accessible to people as roads, public squares, parks, beaches, bike paths, garages, government buildings, such as public libraries, facilities such as elevators, shops, restaurants, community centers, sport facilities, party rooms, theater or media rooms." }
  ],
  discover:{
    blocks:[
      {
        title:"Mission",
        text:"Your mission is the following: propose a data-driven solutions trying to balance privacy concerns and benefits."
      },
      {
        title:"Step of the Game",
        subtitle:"1. Scenario cards",
        id:"scenario",
        text:"To help you come up with a great idea, you’ll first be asked to pick a random scenario card."
      },
      {
        subtitle:"2. Data cards",
        id:"data-cards",
        text:"You receive three random data cards. Lock one card, which you keep for the entire game. Next, exchange other data cards to build your assets."
      },
      {
        subtitle:"3. Ideation",
        id:"ideation",
        text:"Use those data cards as inspiration to ideate a solution (such as an app, service or device) for the given scenario, in less than 5 minutes."
      },
      {
        subtitle:"4. Assessment",
        id:"assessments",
        text:"Once you submit your solution, rate ideas from other Data Slots players around the world. Assess privacy concerns and benefits of each idea."
      },
      {
        subtitle:"5. Investment",
        id:"investment",
        text:"Decide which ones you’d invest your coins on."
      },
      {
        subtitle:"",
        id:"scoreboard",
        text:"Don’t miss the scoreboard! Create a nickname and keep track of your ranking through the Scoreboard. Follow this journey and your idea might climb to the top of the scoreboard!"
      }
    ],
    contact:"<strong>Contact:</strong> <a href='mailto:{account}{'@'}{domain}'>{account}{'@'}{domain}</a>",
    disclaimer:"<strong>Disclaimer:</strong> Data Slots do not request any personal data. The data generated in Data Slots is anonymous and will be used for academic research only.",
    credits:"<strong>Team:</strong> Martina Mazzarello, Fábio Duarte, Simone Mora, Carlo Ratti <br><strong>Website:</strong> Team + Paul Bouisset, Pietro Leoni<br><strong>Acknowledgment:</strong> Arianna Salazar Miranda, Timur Abbiasov, Niklas Hagemann, Surbhi Agrawal, Mani Makhija, Latifa Alkahayat, Ous Abou Raf, Marc-Edouard Schultheiss, Kee Moon Jang, Yanhua Yao, Ichiro Watanabe, Louis Charron, and SCL members, as well as participants in cities around the world who played the in person version of Data Slots and helped us to improve the game. We thank the SCL consortium."
  },
  gameHints:{
    yourCards:"Your cards",
    cardsDeck:"Cards deck",
    introText:'A 10-min game about the ethics and value of data-driven solutions',
    introPlay:'Play the game',
    introDiscover:'How to play',
    introScoreboard:'Scoreboard',
    title: 'The slot machine',
    hint: '',
    cardSelect: { message: 'Pick a random scenario', messageMobile:'Tap to pick a random scenario', hint: 'Your mission is to design a data-driven idea to create experiences for one of these scenarios: public space, living and work&nbsp;environment.', icon: 'highlight_alt' },
    slotMobile:{
      level0: { message: 'You got three cards. Lock one to keep it along the entire game', hint: 'Just pick it by tapping the card', icon: '' },
      level1: { message: 'Now choose the card you want to remove from your deck', hint: 'Tap the card', icon: '' },
      level2: { message: 'Now choose the card you want in your deck', hint: 'Tap the card', icon: '' },
      level3: { message: 'Now choose another card you want to remove from your deck', hint: 'Tap the card', icon: '' },
      level4: { message: 'Now choose the card you want in your deck', hint: 'Tap the card' , icon: '' },
      level5: { message: 'Now pick your last card', hint: 'Tap the card', icon: '' },
      level6: { message: 'These are your final cards', hint: 'Tap proceed to propose your idea' }
    },
    slot: {
        level0: { message: 'You got three cards. Lock one to keep it along the entire game', hint: 'Just pick it by clicking', icon: 'highlight_alt' },
        level1: { message: 'Now swap one of your cards', hint: 'Drag one card to the top deck', icon: 'swipe_up' },
        level2: { message: 'Now swap one more card', hint: 'Drag one card to the top deck', icon: 'swipe_up' },
        level3: { message: 'Now pick your last card', hint: 'Drag one card from the top deck', icon: 'swipe_down' },
        level4: { message: 'These are your final cards', hint: 'Click "proceed" to propose your idea', }
    },
    sideButtons: {
        scanario: { label: 'scanario' },
        cards: { label: 'my cards' }
    },
    finalMessage: {
      playAgain:'Play Again',
      scoreboard:'Scoreboard',
      name: {
        hint: 'Your name',
        error: 'Please type something',
        confirm:'Update'
      },
      message: 'Thank you for playing Data Slots',
      hint:"This is your nickname to join the scoreboard <br> Come back soon and use your nickname to check your idea's ranking"
    }
  },
  profilation:{
      title: 'Tell us about you',
      hint: '',

      name: {
          hint: 'Your name',
          error: 'Please type something'
      },
      role: {
          hint: 'Your role',
          options: [
              'Academic',
              'Citizen',
              'City official'
          ],
          error: 'Please select something'

      },
      region: { hint: 'where are you playing', options: regions,error: 'Please select something' },
      age: {
          hint: 'Your age',
          options: [
              'under 16',
              '16-25',
              '26-35',
              '36-45',
              '46-55',
              '56-65',
              'over 65'
          ],
          error: 'Please select something'

      },
      gender: {
          hint: 'Your pronouns',
          options: [
              'She/her',
              'He/him',
              'They/them',
              'Let me type',
              'I prefer not to say'
          ],
          error: 'Please select something'
      },
      customGender: {
          hint: 'Your pronouns',
          error: 'Please type something'

      },
      confirm: 'Proceed'
  },
  ideation:{
      title: 'The ideation',
      hint: 'Use your data cards to ideate a solution (such as an app, service, device) for your scenario <br>You have 5 minutes.',
      projectTitle: {
          hint: 'Project title',
          error: 'Please type something'
      },
      name: {
          hint: 'Your name',
          error: 'Please type something'
      },
      projectCaption: {
          hint: 'Project caption',
          error: 'Please type something'
      },
      projectDescription: {
          hint: 'Project short description',
          error: 'Please type something'
      },
      projectRatings:{
        translation:"* Some of the projects are automatically translated from their original language",
        ratings:{
          hint:'Rate the ideas',
          subtitle:'Invasiveness &#8594; privacy concerns about the use of data in each idea <br> Benefit &#8594; created by each idea'
        },
        investments:{
          hint:'Now you are the investor',
          subtitle:'You have ten coins. Drag the coins to invest in these ideas',
          icon:'swipe_up'
        }
      },
      read_more:'Read more',
      confirm: 'Submit',
      invasiveness:'Invasiveness',
      benefit:'Benefit',
      high:'High',
      low:'Low'
  },
  scoreboard:{
    search:'Search',
    name:'Nickname',
    position:'Position',
    title:'Title',
    country: 'Country',
    score: 'Score',
    ratings:'Ratings'
  }
}