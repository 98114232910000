<template>
  <div>
    <TransitionGroup name="scale" mode="out-in" tag="div" class="selected-archive">
      <q-btn
        key="btn-1"
        v-if="scenarioCard"
        @click="() => setDialogScenario(true)"
        rounded
        unelevated
        style="background:#e6293d; color:white;"
        icon-right="search"
        :label="scenarioCard.name"
        class="reference-button q-mb-sm"
      />
      <br key="br"/>
      <q-btn
        key="btn-2"
        v-if="finalCards"
        @click="() => setDialogCards(true)"
        rounded
        unelevated
        color="primary"
        class="reference-button q-mb-sm"
        icon-right="search"
        :label="$t('gameHints.sideButtons.cards.label')"
      />
      <div class="q-mt-xs md-container" @click="() => setDialogCards(true)" v-if="finalCards && finalCards.length > 0">
        <p class="text-body1 q-my-none" v-for="(card,index) in finalCardsInfo" :key="index">
          {{card.name}}
        </p>
      </div>
    </TransitionGroup>
    <q-dialog v-model="openDialogScenario" class="dialog-box_" maximized>
      <div class="row dialog-content">
        <div class="dialog-content__background" @click="() => setDialogScenario(false)">

        </div>
        <q-btn
          color="primary"
          round
          unelevated
          @click="() => setDialogScenario(false)"
          icon="close"
          class="close-button"
        />
        <Card
          :key="`card-scenario`"
          :cardData="scenarioCard"
          class="q-ma-md"
          :flipped="false"
          :disabled="true"
          :color="colors.getPaletteColor('red-6')"
        />
      </div>
    </q-dialog>

    <q-dialog v-model="openDialogCards" class="dialog-box" maximized>
      <div class="dialog-content">
        <div class="dialog-content__background" @click="() => setDialogCards(false)">

        </div>
        <q-btn
          color="primary"
          round
          unelevated
          @click="() => setDialogCards(false)"
          icon="close"
          class="close-button"
        />
        <div
          :class="{
            'final-cards-mobile': $q.screen.xs || $q.screen.sm,
            'final-cards-desktop': $q.screen.md || $q.screen.lg || $q.screen.xl,
          }"
        >
          <Card
            v-for="card in finalCards"
            :key="`card-final-${card - index}`"
            :cardData="cardsReference.find((c) => c.index === card)"
            class="q-ma-md"
            :flipped="false"
            :disabled="true"
            :color="colors.getPaletteColor('primary')"
          />
        </div>
      </div>
    </q-dialog>
  </div>
</template>


<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Card from "../components/Card.vue";
import { colors } from "quasar";
import { useQuasar } from "quasar";
import { gameHints as contents } from "../contents/gameHints.js";

const store = useStore();
const $q = useQuasar();
// console.log($q.screen);
const scenarioCard = computed(() => store.state.scenario);
const finalCards = computed(() => store.state.finalCards);
const cardsReference = computed(() => store.state.cardsReference);

const openDialogScenario = ref(false);
const openDialogCards = ref(false);

const finalCardsInfo = computed(()=>{
  return cardsReference.value && finalCards.value ? finalCards.value.map((e,i) => cardsReference.value.find((c,j)=>c.index == e)) : [];
})

function setDialogScenario(input) {
  openDialogScenario.value = input;
}
function setDialogCards(input) {
  openDialogCards.value = input;
}
</script>
<style lang="scss">
@import "../style/shared.scss";
.reference-button {
  // width: 15rem !important;
}

  .dialog-box_{
    
      .card-flipped{
        transform : perspective(600px) rotateY(0deg) !important;
      }
    
  }

.selected-archive{
  .text-body1{
    font-size: .95em;
  }
  @include max-responsive(sm){
    width:100%;
    display: flex;
    flex-wrap: wrap;
    gap:5px;
    .dialog-box_{

    }
    .q-btn{
      font-size: .7rem;
      line-height: 1;
    }
  }
}
.close-button {
  position: fixed !important;
  top: 20px;
  right: 20px;
  z-index: 1000 !important;
}
.dialog-content {
  width: 100vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;

  .card-front{
    opacity: 1 !important;
  }
  .dialog-content__background{
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background: red;
  }
}
.dialog-box {
}
.final-cards-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.final-cards-mobile {
  position: fixed;
  left: 0px;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  scrollbar-width: 1px;
}
.final-cards-mobile::-webkit-scrollbar {
  display: 1px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
</style>
