<template>
  <q-form
    @submit="onSubmit"
    class="row align-center q-col-gutter-y-sm q-col-gutter-x-sm"
  >
    <!-- <q-input
      square
      :filled="filled"
      v-model="nickname"
      :label="contents[store.state.language].name.hint"
      :class="elementClasses"
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || contents[store.state.language].name.error]"
    /> -->
    <q-input
      square
      :filled="filled"
      v-model="title"
      :label="$t('ideation.projectTitle.hint')"
      :class="elementClasses"
      lazy-rules
      :rules="[
        (val) =>
          (val && val.length > 0) || $t('ideation.projectTitle.error'),
      ]"
    />
    <q-input
      square
      :filled="filled"
      v-model="caption"
      :label="$t('ideation.projectCaption.hint')"
      :class="elementClasses"
      lazy-rules
      :rules="[
        (val) =>
          (val && val.length > 0) || $t('ideation.projectCaption.error'),
      ]"
    />

    <div style="width: 100%">
      <q-input

        :label="$t('ideation.projectDescription.hint')"
        v-model="description"
        filled
        type="textarea"


      />
    </div>

    <!-- <q-select standout v-model="model" :options="options" label="Standout" /> -->
    <div class="col-12 row">
      <q-btn
        :label="$t('ideation.confirm')"
        type="submit"
        color="primary"
        unelevated
        rounded
      />
    </div>
  </q-form>
</template>

<script setup>
import { countries } from "countries-list";
import { ideation as contents } from "../contents/profilation";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";

import { useStore } from "vuex";

import {  ref, watch } from "vue";

const store = useStore();

const emit = defineEmits(["completed"]);

const title = ref("");
const caption = ref("");
const description = ref("");
// const nickname = ref(
//   uniqueNamesGenerator({
//     dictionaries: [adjectives, animals],
//     style: "capital",
//     separator: " ",
//   })
// );

const elementClasses = "col-xs-12 col-md-12";
const filled = ref(true);

const region = ref();
const regions = Object.values(countries).map((c) => c.name);

function onSubmit() {
  const output = {
    title: title.value,
    caption: caption.value,
    description: description.value,
  };

  emit("completed", output);
}
</script>
